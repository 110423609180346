import React, { useState, useEffect } from "react";
import { Typography, IconButton, Divider, Switch, FormControl} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../styles/changePart.css";
import { ReactComponent as Alert } from "../../assets/images/Alert.svg";
import axios from "axios";
import {automationHttpRequest} from "../../api/httpRequests";
import PartModificationSucess from "./PartModificationSuccess";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";

const PlantSpecificModification = (props) => {
  const {
    uniqueId,
    materialNumber,
    selectAction,
    handleBackClick,
    handleClose,
  } = props;
  const [modify, setModify] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState({
    maximumStock: "",
    maximumStockLevel: "",
  });
  const [futureValues, setFutureValues] = useState({
    maximumStock: "",
    maximumStockLevel: "",
  });
  const [modifyLoader, setModifyLoader] = useState(false);

  const [minStockCurrentValue, setMinStockCurrentValue] = useState("");
  const [maxStockCurrentValue, setMaxStockCurrentValue] = useState("");
  const [minFutCurrentValue, setMinFutCurrentValue] = useState("");
  const [minFutCurrValueChanged, setMinFutCurrValueChanged] = useState(false);
  const [maxFutCurrValueChanged, setMaxFutCurrValueChanged] = useState(false);
  const [abcFutCurrValueChanged, setAbcFutCurrValueChanged] = useState(false);
  const [maxFutCurrentValue, setMaxFutCurrentValue] = useState("");
  const [minJustValue, setMinJustValue] = useState("");
  const [maxJustValue, setMaxJustValue] = useState("");
  const [requestID, setRequestID] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currAbcIndicator, setCurrAbcIndicator] = useState("");
  const [futAbcIndicator, setFutAbcIndicator] = useState("");
  const [justiAbcIndicator, setJustiAbcIndicator] = useState("");
  const [critcialIndicator, setCritcialIndicator] = useState("");
  const [storageLocation, setStorageLocation] = useState("");
  const [storageBin, setStorageBin] = useState("");
  const [partModificationDialog, setPartModificationDialog] = useState(false);
  const [dfFlag, setDfFlag] = useState();
  const [dfStorage, setDfStorage] = useState();
  const [plantName, setPlantName] = useState();
  const [noDataAvailable, setNoDataAvailable] = useState(false);


  const headingStyleNoData = {
    fontSize: 20,
    fontFamily: "Verdana",
    fontWeight: "600",
    margin: "10px 0px 10px 40px",
    color:"#C6003D",
    marginLeft: "400px"
  };

  const headingStyleHeading = {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: "600",
    marginLeft:"10px"
    // margin: "30px 0px 10px 40px",
  };
  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  };


  useEffect(() => {
    fetchPlantModificationData();
  }, []);

  const handleToggleChange = () => {
    setModify(!modify);
    if (modify) {
      setDfFlag("");
      setDfStorage("");
    } else {
      setDfFlag("X");
      setDfStorage("X");
    }

    // Add any other logic you want to execute when the toggle button is changed
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    // Perform any validation or processing if needed
    // Update the corresponding state based on the key
    if (key === "minFutCurrentValue") {
      setMinFutCurrentValue(value);
      setMinFutCurrValueChanged(true);
    } else if (key === "maxFutCurrentValue") {
      setMaxFutCurrentValue(value);
      setMaxFutCurrValueChanged(true);
    } else if (key === "minJustValue") {
      setMinJustValue(value);
    } else if (key === "maxJustValue") {
      setMaxJustValue(value);
    } else if (key === "minStockCurrentValue") {
      setMinStockCurrentValue(value);
    } else if (key === "maxStockCurrentValue") {
      setMaxStockCurrentValue(value);
    }
  };

  const handlePlantInputChange = (e, key) => {
    const value = e.target.value;
    // Perform any validation or processing if needed
    // Update the corresponding state based on the key
    if (key === "currAbcIndicator") {
      setCurrAbcIndicator(value);
    } else if (key === "futAbcIndicator") {
      setFutAbcIndicator(value);
      setAbcFutCurrValueChanged(true);
    } else if (key === "justiAbcIndicator") {
      setJustiAbcIndicator(value);
    } else if (key === "critcialIndicator") {
      setCritcialIndicator(value);
    } else if (key === "storageLocation") {
      setStorageLocation(value);
    } else if (key === "storageBin") {
      setStorageBin(value);
    } else if (key === "currAbcIndicator") {
      setCurrAbcIndicator(value);
    }
  };

  const handleModify = () => {
    setModifyLoader(true);
    updatePlantSpecificModification();
  };
  const handleReset = () => {
    setCurrAbcIndicator("");
    setFutAbcIndicator("");
    setJustiAbcIndicator("");
    setCritcialIndicator("");
    setStorageLocation("");
    setStorageBin("");
    setMinStockCurrentValue("");
    setMaxStockCurrentValue("");
    setMinFutCurrentValue("");
    setMinFutCurrValueChanged(false);
    setMaxFutCurrValueChanged(false);
    setMaxFutCurrentValue("");
    setMinJustValue("");
    setMaxJustValue("");
    setLoading(true);
    fetchPlantModificationData();
  };

  const updatePlantSpecificModification = async () => {
    const userEmailId = localStorage.getItem("MRO_user_email")
    try {
      const response = await automationHttpRequest.post(
        "/modification_plant_specific_insert/",
        {
          material_number: materialNumber,
          minimum_stock_level: minStockCurrentValue,
          maximum_stock_level: maxStockCurrentValue,
          future_maximum_stock_level: maxFutCurrentValue,
          future_minimum_stock_level: minFutCurrentValue,
          justification_for_change_in_maximum_stock: maxJustValue,
          justification_for_change_in_minimum_stock: minJustValue,
          df_plant: dfFlag,
          df_storage_location: dfStorage,
          ABC_indicator: currAbcIndicator,
          critical_part: critcialIndicator,
          storage_location: storageLocation,
          storage_bin: storageBin,
          plant_name: plantName,
          submitted_by: userEmailId,
          future_abc_indicator: futAbcIndicator,
          justification_for_change_in_abc_indicator: justiAbcIndicator,
          type: "plant_specific",
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setRequestID(response?.data?.data.request_id);
        setModifyLoader(false);
        setPartModificationDialog(true);
      }
    } catch (error) {
      // setModifyLoader(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };

  const fetchPlantModificationData = async () => {
    try {
       const response = await automationHttpRequest.post(
        "/get_plant_specific_modification/",
        {
          Unique_ID: uniqueId,
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        if (response?.data?.data.length > 0) {
            setMinStockCurrentValue(response?.data?.data[0].minimum_stock_level);
            setMaxStockCurrentValue(response?.data?.data[0].maximum_stock_level);
            setMinFutCurrentValue(
              response?.data?.data[0].future_minimum_stock_level
            );
            setMaxFutCurrentValue(
              response?.data?.data[0].future_maximum_stock_level
            );
            setCurrAbcIndicator(response?.data?.data[0].ABC_indicator);
            setPlantName(response?.data?.data[0].plant_name);
            setFutAbcIndicator(response?.data?.data[0].future_abc_indicator);
            setCritcialIndicator(response?.data?.data[0].critical_part);
            setStorageLocation(response?.data?.data[0].storage_location);
            setStorageBin(response?.data?.data[0].storage_bin);
            setJustiAbcIndicator("");
            if (response?.data?.data[0].df_plant.trim() === "active") {
              setModify(!true);
            }
            if (response?.data?.data[0].df_storage_location.trim() === "active") {
              setDfStorage("X");
            }
            setLoading(false);
        }else{
            setLoading(false);
            setNoDataAvailable(true);
        }
    
      }
    } catch (error) {}
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            top: "20px",
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleModify();
          }}
          disabled={modifyLoader}
        >
          {modifyLoader ? (
            // Render loader or loading state here
            <div>Loading...</div>
          ) : (
            // Render button text
            "Modify"
          )}
        </button>
        <button
          type="submit"
          className="submitButton"
          style={{
            top: "20px",
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleReset();
          }}
        >
          Reset
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            top: "20px",
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleBackClick();
          }}
        >
          Cancel
        </button>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Typography
          sx={{
            // height: "70px",
            fontSize: "18px",
            padding: "20px",
            background: "#fff",
            fontFamily: "Verdana",
          }}
        >
          Loading...
        </Typography>
      ) : (
        <>
          {noDataAvailable ? (
            <Typography style={headingStyleNoData}>
              No Data Available
            </Typography>
          ) : (
            <>
              <div>
                <div style={{ margin: "40px" }}>
                <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      alignItems: "center",
                    }}
                  >
                    <Plant />
                    <div style={inputStyle}>
                      <Typography style={headingStyleHeading} >
                        Modification Required
                      </Typography>
                      <FormControl sx={{ m: 1, width: "200px" }}>
                        <div className="select-container">
                          <input
                            // placeholder="Part Extension"
                            name="activePart"
                            style={{ background: "#EFEFEF" }}
                            type="text"
                            value={plantName}
                            className="partInputField"
                            disabled
                          />
                        </div>
                      </FormControl>
                    </div>
                  </div>
                  <div className="modificationStyle">
                    <Typography
                      sx={{
                        fontFamily: "Verdana",
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#C6003D",
                      }}
                    >
                      Replenishment Level
                    </Typography>
                  </div>
                  <Divider sx={{ marginTop: "10px", marginBottom: "15px" }} />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyItems: "center",
                    }}
                  >
                    <div className="inputStyle">
                      <Typography
                        className="headingStyle"
                        sx={{
                          marginTop: "40px",
                          marginRight: "30px",
                          fontFamily: "Verdana",
                          fontWeight: 600,
                        }}
                      >
                        Minimum Stock
                      </Typography>
                    </div>
                    <div className="inputStyle">
                      <Typography
                        className="headingStyle"
                        sx={{ fontFamily: "Verdana", fontSize: "13px",fontWeight: 600, }}
                      >
                        Current Value
                      </Typography>
                      <input
                        name="abcIndicator"
                        // onChange={handleChange}
                        type="number"
                        value={minStockCurrentValue}
                        onChange={(e) =>
                          handleInputChange(e, "minStockCurrentValue")
                        }
                        className="partInputField"
                        // readOnly
                        // style={{
                        //   backgroundColor: "#f0f0f0",
                        //   cursor: "not-allowed",
                        // }}
                      />
                    </div>
                    <div className="inputStyle">
                      <Typography
                        className="headingStyle"
                        sx={{ fontFamily: "Verdana", fontSize: "13px", fontWeight: 600, }}
                      >
                        Future Value
                      </Typography>
                      <input
                        name="criticalPart"
                        onChange={(e) =>
                          handleInputChange(e, "minFutCurrentValue")
                        }
                        type="number"
                        value={minFutCurrentValue}
                        className="partInputField"
                      />
                    </div>

                    {minFutCurrValueChanged && (
                      <div className="inputStyle">
                        <Typography
                          className="headingStyle"
                          sx={{ fontFamily: "Verdana", fontSize: "13px", fontWeight: 600, }}
                        >
                          Justification for Requested Changes
                        </Typography>
                        <input
                          name="bin"
                          onChange={(e) => handleInputChange(e, "minJustValue")}
                          type="text"
                          // value={values.maximumStock}
                          // disabled={!minFutCurrValueChanged}
                          className="partInputField"
                        />
                      </div>
                    )}
                  </div>
                  <Divider style={{ marginTop: "20px" }} />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="inputStyle">
                      <Typography
                        className="headingStyle"
                        sx={{
                          marginTop: "40px",
                          marginRight: "30px",
                          fontFamily: "Verdana",
                          fontWeight: 600,
                        }}
                      >
                        Maximum Stock
                      </Typography>
                    </div>
                    <div className="inputStyle">
                      <input
                        placeholder=" - "
                        name="abcIndicator"
                        // onChange={handleChange}
                        type="number"
                        value={maxStockCurrentValue}
                        className="partInputField"
                        // style={{ marginTop: "35px", readOnly
                        onChange={(e) =>
                          handleInputChange(e, "maxStockCurrentValue")
                        }
                        style={{
                          marginTop: "35px",
                        }}
                        // readOnly
                      />
                    </div>
                    <div className="inputStyle">
                      <input
                        placeholder=" - "
                        name="criticalPart"
                        onChange={(e) =>
                          handleInputChange(e, "maxFutCurrentValue")
                        }
                        type="number"
                        value={maxFutCurrentValue}
                        className="partInputField"
                        style={{ marginTop: "35px" }}
                      />
                    </div>
                    {maxFutCurrValueChanged && (
                      <div className="inputStyle">
                        <Typography
                          className="headingStyle"
                          sx={{ fontFamily: "Verdana", fontSize: "13px" }}
                        >
                          Justification for Requested Changes
                        </Typography>
                        <input
                          placeholder="- "
                          name="bin"
                          onChange={(e) => handleInputChange(e, "maxJustValue")}
                          type="text"
                          className="partInputField"
                          disabled={!maxFutCurrValueChanged}
                          style={{ marginTop: "15px" }}
                        />
                      </div>
                    )}
                  </div>
                  <Divider style={{ marginTop: "20px" }} />
                  {/* <ButtonFamily btnText="Modify" /> */}
                </div>
              </div>
              <div style={{ margin: "40px" }}>
                <div className="modificationStyle">
                  <Typography
                    sx={{
                      fontFamily: "Verdana",
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#C6003D",
                    }}
                  >
                    Flag for Deletion
                  </Typography>
                </div>
                <Divider sx={{ marginTop: "10px", marginBottom: "15px" }} />
                <div>
                  <Typography
                    className="headingStyle"
                    sx={{
                      marginBottom: "20px",
                      marginRight: "30px",
                      fontFamily: "Verdana",
                      fontWeight: 600,
                    }}
                  >
                    De-activate this Part
                  </Typography>
                </div>
                <div>
                  <Switch
                    checked={modify}
                    onChange={handleToggleChange}
                    sx={{
                      borderRadius: "50px",
                      //   marginLeft: "25px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "50px",
                      "& .Mui-checked": {
                        color: "#C6003D", // Change the color to red when the switch is checked
                      },
                    }}
                    inputProps={{ "aria-label": "toggle" }}
                    color="default"
                  />
                </div>
              </div>
              <div>
                <div style={{ margin: "40px" }}>
                  <div className="modificationStyle">
                    <Typography
                      sx={{
                        fontFamily: "Verdana",
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#C6003D",
                      }}
                    >
                      Plant Changes
                    </Typography>
                  </div>
                  <Divider sx={{ marginTop: "10px", marginBottom: "15px" }} />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="column" style={{ flex: "1" }}>
                      <Typography
                        className="headingStyle"
                        sx={{
                          marginTop: "32px",
                          marginRight: "30px",
                          fontFamily: "Verdana",
                          fontWeight: 600,
                        }}
                      >
                        ABC Indicator
                      </Typography>
                    </div>
                    <div className="column" style={{ flex: "1" }}>
                      <Typography
                        className="headingStyle"
                        sx={{
                          fontFamily: "Verdana",
                          fontSize: "13px",
                          marginLeft: "13px",
                          fontWeight: 600,
                        }}
                      >
                        Current Value
                      </Typography>

                      <input
                        name="abcIndicator"
                        className="partInputField"
                        value={currAbcIndicator}
                        onChange={(e) =>
                          handlePlantInputChange(e, "currAbcIndicator")
                        }

                        // style={{
                        //   backgroundColor: "#f0f0f0",
                        //   cursor: "not-allowed",
                        // }}
                        // readOnly
                      />
                    </div>
                    <div className="column" style={{ flex: "1" }}>
                      <Typography
                        className="headingStyle"
                        sx={{ fontFamily: "Verdana", fontSize: "13px",fontWeight: 600, }}
                      >
                        Future Value
                      </Typography>
                      <input
                        name="futAbcIndicator"
                        onChange={(e) =>
                          handlePlantInputChange(e, "futAbcIndicator")
                        }
                        type="text"
                        value={futAbcIndicator}
                        className="partInputField"
                      />
                    </div>
                    {!abcFutCurrValueChanged && (
                      <div className="column" style={{ flex: "1.5" }}></div>
                    )}
                    {abcFutCurrValueChanged && (
                      <div className="column" style={{ flex: "1.5" }}>
                        <Typography
                          className="headingStyle"
                          sx={{ fontFamily: "Verdana", fontSize: "13px", fontWeight: 600, }}
                        >
                          Justification for Requested Changes
                        </Typography>
                        <input
                          name="justiAbcIndicator"
                          onChange={(e) =>
                            handlePlantInputChange(e, "justiAbcIndicator")
                          }
                          type="text"
                          value={justiAbcIndicator}
                          className="partInputField"
                          //   disabled={!minFutCurrValueChanged}
                        />
                      </div>
                    )}
                  </div>
                  <Divider />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="column" style={{ flex: "1" }}>
                      <Typography
                        className="headingStyle"
                        sx={{
                          marginTop: "33px",
                          marginRight: "30px",
                          fontFamily: "Verdana",
                          fontWeight: 600,
                        }}
                      >
                        Criticality Indicator
                      </Typography>
                    </div>
                    <div
                      className="column"
                      style={{ flex: "3.6", marginTop: "20px" }}
                    >
                      <input
                        name="critcialIndicator"
                        onChange={(e) =>
                          handlePlantInputChange(e, "critcialIndicator")
                        }
                        type="text"
                        value={critcialIndicator}
                        className="partInputField"
                      />
                    </div>
                    {/* <div className="column" style={{ flex: "1", marginTop: "20px" }}>
              <input
                  placeholder=" -"
                  name="criticalPart"
                  className="partInputField"
                  value={futureValues.criticalPart}
                  onChange={(e) =>
                    setFutureValues({
                      ...futureValues,
                      criticalPart: e.target.value,
                    })
                  }
                />
              </div>
              <div
                className="column"
                style={{ flex: "1.5", marginTop: "20px" }}
              >
                                <input
                  placeholder=" -"
                  name="criticalPart"
                  className="partInputField"
                  value={justifications.abcIndicator}
                  onChange={(e) =>
                    setJustifications({
                      ...justifications,
                      criticalPart: e.target.value,
                    })
                  }
                />
              </div> */}
                  </div>

                  <Divider />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="column" style={{ flex: "1" }}>
                      <Typography
                        className="headingStyle"
                        sx={{
                          marginTop: "33px",
                          marginRight: "30px",
                          fontFamily: "Verdana",
                          fontWeight: 600,
                        }}
                      >
                        Storage Location
                      </Typography>
                    </div>
                    <div
                      className="column"
                      style={{ flex: "3.6", marginTop: "20px" }}
                    >
                      <input
                        name="storageLocation"
                        onChange={(e) =>
                          handlePlantInputChange(e, "storageLocation")
                        }
                        type="text"
                        value={storageLocation}
                        className="partInputField"
                      />
                    </div>
                    {/* <div className="column" style={{ flex: "1", marginTop: "20px" }}>
              <input
                  placeholder=" -"
                  name="criticalPart"
                  className="partInputField"
                  value={futureValues.sloc}
                  onChange={(e) =>
                    setFutureValues({
                      ...futureValues,
                      sloc: e.target.value,
                    })
                  }
                />
              </div>
              <div
                className="column"
                style={{ flex: "1.5", marginTop: "20px" }}
              >
                                <input
                  placeholder=" -"
                  name="criticalPart"
                  className="partInputField"
                  value={futureValues.abcIndicator}
                  onChange={(e) =>
                    setJustifications({
                      ...justifications,
                      criticalPart: e.target.value,
                    })
                  }
                />
              </div> */}
                  </div>

                  <Divider />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="column" style={{ flex: "1" }}>
                      <Typography
                        className="headingStyle"
                        sx={{
                          marginTop: "35px",
                          marginRight: "30px",
                          fontFamily: "Verdana",
                          fontWeight: 600,
                        }}
                      >
                        Storage Bin
                      </Typography>
                    </div>

                    <div
                      className="column"
                      style={{ flex: "3.6", marginTop: "20px" }}
                    >
                      <input
                        name="storageBin"
                        onChange={(e) =>
                          handlePlantInputChange(e, "storageBin")
                        }
                        type="text"
                        value={storageBin}
                        className="partInputField"
                      />
                    </div>
                    {/* <div className="column" style={{ flex: "1", marginTop: "20px" }}>
              <input
                  placeholder=" -"
                  name="criticalPart"
                  className="partInputField"
                  value={futureValues.bin}
                  onChange={(e) =>
                    setFutureValues({
                      ...futureValues,
                      bin: e.target.value,
                    })
                  }
                />
              </div>
              <div
                className="column"
                style={{ flex: "1.5", marginTop: "20px" }}
              >
               <input
                  placeholder=" -"
                  name="criticalPart"
                  className="partInputField"
                  value={justifications.bin}
                  onChange={(e) =>
                    setJustifications({
                      ...justifications,
                      criticalPart: e.target.value,
                    })
                  }
                />
              </div> */}
                  </div>
                  <Divider sx={{ marginTop: "30px" }} />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ButtonFamily btnText="Modify.." />
                  </div>
                </div>
              </div>
            </>
          )}

          <PartModificationSucess
            partModificationDialog={partModificationDialog}
            setPartModificationDialog={setPartModificationDialog}
            requestID={requestID}
            handleClose={handleClose}
          />
        </>
      )}
    </>
  );
};

export default PlantSpecificModification;
