import React, {useState} from "react";
import { Typography, Paper, Divider } from "@mui/material";
import { ReactComponent as ArrowRight } from '../../assets/images/ArrowRight.svg';
import '../../styles/partCreation.css'
import CheckPartDialog from "./CheckPartDialog";
import axios from "axios";
import {automationHttpRequest} from "../../api/httpRequests";
import { ToastContainer, toast } from "react-toastify";


const CheckPartExtension = ({setCheckPartData, handleContinue, setExtension, setExtensionData}) => {
  const [checkDialog, setCheckDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkTableData, setCheckTableData] = useState([]);
  const [checkPart, setCheckPart] =useState({
    "manufacturer":"",
    "manufacturing_part_number":"",
    "supplier_part_number":""
  })

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  }

  const headingStyle = {
    fontWeight: "700",
  }


  const handleChange = (event) => {
    if (event?.target?.name === "manufacturer") {
      event.target.value = event.target.value.toUpperCase()
      setCheckPart((prevObject) => ({
        ...prevObject,
        manufacturer: event?.target?.value,
      }));
    }
    if (event?.target?.name === "manufacturingPartNumber") {
      setCheckPart((prevObject) => ({
        ...prevObject,
        manufacturing_part_number: event?.target?.value,
      }));
    }
    if (event?.target?.name === "supplierPartNumber") {
      setCheckPart((prevObject) => ({
        ...prevObject,
        supplier_part_number: event?.target?.value,
      }));
    }
  }

  const handleChangeNext = () => {
    setCheckDialog(true)
    fetchPartCheckResult();
    setCheckPartData(checkPart)
  }

  const fetchPartCheckResult = async () => {
    setLoading(true);
    const {supplier_part_number }= checkPart;
    const manufacturing_number = [];
    if(checkPart?.manufacturer.length > 0) {
      manufacturing_number.push(checkPart?.manufacturer)
    } 
    if(checkPart?.manufacturing_part_number.length > 0) {
      manufacturing_number.push(checkPart?.manufacturing_part_number)
    }
    try {
      const response = await automationHttpRequest.post(
        "/existing_part_data/",
        {manufacturing_part_number: manufacturing_number, supplier_part_number},
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setCheckTableData(response?.data?.data)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  return (
    <>
      <Paper sx={{
        width: "100%",
        height: "150px",
        display: "flex",
        alignItems: "center",
        margin: "20px 0px",
        borderRadius: "18px"
      }} elevation={2}>
        {/* <div style={{width: "100%"}}>
          <div style={inputStyle}>
            <Typography style={headingStyle}>Preferred Supplier Identifier</Typography>
            <input
              value={checkPart.supplierIdentifier}
              //placeholder="Preferred Supplier Identifier"
              type="text"
              name="supplierIdentifier"
              onChange={handleChange}
              className="partInputField"
            />
          </div>
          <Divider style={{margin: "20px"}} /> */}
        <div className='checkPart'>
          <div style={inputStyle}>
            <Typography style={headingStyle}>Manufacturer</Typography>
            <input
                value={checkPart.manufacturer}
              //placeholder="Manufacturer"
              type="text"
              name="manufacturer"
                onChange={handleChange}
              className="partInputField"
            />
          </div>
          <div style={inputStyle}>
            <Typography style={headingStyle}>Manufacturer Part Number</Typography>
            <input
                value={checkPart.manufacturingPartNumber}
              //placeholder="manufacturing part number"
              type="text"
              name="manufacturingPartNumber"
                onChange={handleChange}
              className="partInputField"
            />
          </div>
          <div style={inputStyle}>
            <Typography style={headingStyle}>Supplier Part Number</Typography>
            <input
                value={checkPart.supplierPartNumber}
              //placeholder="Supplier Part Number"
              type="text"
              name="supplierPartNumber"
                onChange={handleChange}
              className="partInputField"
            />
          </div>
          {/* <div style={inputStyle}>
            <Typography style={headingStyle}>Description</Typography>
            <input
                value={checkPart.description}
              //placeholder="Description"
              type="text"
              name="description"
                onChange={handleChange}
              className="partInputField"
            />
          </div> */}
          <div style={inputStyle}>
            <button className="checkbutton" onClick={handleChangeNext}>
              <span>Next</span>
              <ArrowRight />
            </button>
          </div>
        </div>
        {/* </div> */}
      </Paper>
      <CheckPartDialog checkDialog={checkDialog} setCheckDialog={setCheckDialog} loading={loading} checkTableData= {checkTableData} handleContinue = {handleContinue} setExtension= {setExtension} setExtensionData={setExtensionData}/>
    </>
  )
}

export default CheckPartExtension;