import React, { useEffect, useState } from "react";
import CheckPartExtension from "./CheckPartExtension";
import DecisionMatrix from "./DecisionMatrix";
import SubstitutionPart from "./SubstitutionPart";
import PartInformation from "./PartInformation";
import { Typography, Paper, Box, Divider } from "@mui/material";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";
import Header from "../dashboard/Header";
import "../../styles/partCreation.css";
import { useNavigate, useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import PartDescription from "./PartDescription";
import PartCreationSuccess from "./PartCreationSuccess";
import PartExtension from "../dashboard/PartExtension";
import { automationHttpRequest } from "../../api/httpRequests";
import { ToastContainer, toast } from "react-toastify";

const PartCreation = () => {
  const navigate = useNavigate();
  const [checkPartData, setCheckPartData] = useState({});
  const [partInformationData, setPartInformationData] = useState({});
  const [decisionData, setDecisionData] = useState({
    impact_score: 0,
    probability_score: "",
    stock_decision: "",
    stock_decision_comment: "",
  });
  const [pirValue, setPirValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDecision, setShowDecision] = useState(false);
  const [showPartInformation, setShowPartInformation] = useState(false);
  const [showSubstitution, setShowSubstitution] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [partDescriptionData, setPartDescriptionData] = useState({
    clean_short_description: "",
    modifier: "",
    noun_indentifier: "",
    attribute_data: "",
  });
  const [substitution_status, setSubstitution_status] = useState("no");
  const [extension, setExtension] = useState(false);
  const [equipment_number_for_bom, setEquipment_number_for_bom] = useState([]);
  const [partCreationDialog, setPartCreationDialog] = useState(false);
  const [requestID, setRequestID] = useState("");
  const [extensionData, setExtensionData] = useState({
    materialNumber: "",
    uid: "",
  });

  useEffect(() => {
    setSearchString(localStorage.getItem("shortDesc"));
  });

  const handlePartCreationData = async () => {
    setLoading(true);
    try {
      const response = await automationHttpRequest.post(
        "/creation_insert/",
        {
          ...checkPartData,
          ...decisionData,
          ...pirValue,
          ...partInformationData,
          ...partDescriptionData,
          equipment_number_for_bom,
          substitution_status,
          plant_name:  localStorage.getItem("MRO_user_location"),
          submitted_by: localStorage.getItem("MRO_user_email")
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setRequestID(response?.data?.data?.request_id);
        setPartCreationDialog(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handlePartCreationData();
          }}
        >
          {loading && (
            <div
              className="loader"
              style={{ borderTop: "2px dotted white" }}
            ></div>
          )}
          {!loading && btnText}
        </button>

        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/dashboard");
          }}
        >
          Cancel
        </button>
      </>
    );
  };

  const handleContinue = () => {
    setShowDecision(true);
  };

  const handleDecisionContinue = () => {
    setShowInformation(true);
  };

  const handleInfoContinue = () => {
    setShowSubstitution(true);
  };

  const handleSubstitutionContinue = () => {
    setShowPartInformation(true);
  };
  const handleExtensionClose = () => {
    setExtension(false);
    setShowDecision(false);
    setShowSubstitution(false);
    setShowInformation(false);
    setShowPartInformation(false);
  };

  return (
    <>
      <Header />
      <div style={{ margin: "30px" }}>
        {extension ? (
          <>
            <PartExtension
              materialNumber={extensionData?.materialNumber}
              handleDialogClose={handleExtensionClose}
              uid={extensionData?.uid}
            />
          </>
        ) : (
          <>
            <Typography
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "18px",
                fontWeight: "500",
                marginTop: "30px",
                marginBottom: "30px",
                width: "60%",
              }}
            >
              New Part Request
            </Typography>
            <Paper
              sx={{
                width: "25%",
                height: "100px",
                display: "flex",
              }}
              elevation={2}
            >
              <div className="plantCard">
                <div>
                  <Plant />
                </div>
                <div>
                  <Typography className="plantHead">
                    Creation Required for Plant
                  </Typography>
                  <Typography className="plantName">
                    {localStorage.getItem("MRO_user_location")}
                  </Typography>
                </div>
              </div>
            </Paper>
            <CheckPartExtension
              setCheckPartData={setCheckPartData}
              handleContinue={handleContinue}
              setExtension={setExtension}
              setExtensionData={setExtensionData}
            />
            {showDecision && (
              <DecisionMatrix
                setDecisionData={setDecisionData}
                handleContinue={handleDecisionContinue}
              />
            )}
            {showInformation && (
              <PartDescription
                handleContinue={handleInfoContinue}
                setSearchString={setSearchString}
                setPartDescriptionData={setPartDescriptionData}
              />
            )}
            {showSubstitution && (
              <SubstitutionPart
                setPirValue={setPirValue}
                handleContinue={handleSubstitutionContinue}
                searchString={searchString}
                setSubstitution_status={setSubstitution_status}
              />
            )}
            {showPartInformation && (
              <>
                <PartInformation
                  setPartInformationData={setPartInformationData}
                  setEquipment_number_for_bom={setEquipment_number_for_bom}
                />
                <ButtonFamily btnText="Create Part Creation Request" />
              </>
            )}
          </>
        )}
      </div>
      <PartCreationSuccess
        partCreationDialog={partCreationDialog}
        setPartCreationDialog={setPartCreationDialog}
        requestID={requestID}
      />
    </>
  );
};

export default PartCreation;
