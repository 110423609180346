import React, { useState } from "react";
import logo from "../../assets/images/Logo.png";
import { Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../assets/images/userIcon.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/homeIcon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/ArrowIcon.svg";
import { ReactComponent as PartHistoryIcon } from "../../assets/images/PartHistory.svg";
import AddIcon from "@mui/icons-material/Add";
import { useMsal } from "@azure/msal-react";

import "../../styles/header.css";

const Header = ({ handleHideInsights }) => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("session_id");
    localStorage.removeItem("MRO_user_name");
    localStorage.removeItem("MRO_user_type");
    localStorage.removeItem("MRO_user_location");
    localStorage.removeItem("userLoginInfo");
    localStorage.removeItem("MRO_user_isSignedIn");
    instance.logout();
    setAnchorEl(null);
    navigate("/");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const handleButtonClick = () => {
    navigate("/partcreation");
  };

  const location = useLocation();
  let currentPage = location.pathname;
  currentPage = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);
  if (localStorage.getItem("session_id") === "" || undefined) {
    handleLogout();
  }

  const getEmailSpecificURL = (userType) => {
    if (userType === "edp-mro-llm-app-data-owner" || userType === "edp-mro-llm-app-admin") {
      return "/partrequesthistory";
    } else if (userType === "edp-mro-llm-app-plant-owner" || userType === "edp-mro-llm-app-maintenance-manager" ) {
      return "/parthistoryrequest";
    } else if (userType === "edp-mro-llm-app-etc") {
      return "/atcpartrequesthistory";
    }

    return "/parthistoryrequest";
  };

  const typoStyle = {
    color: "#000000",
    fontFamily: "Verdana",
    fontSize: "10px",
    fontWeight: "400",
    marginLeft: "20px",
    position: "relative",
    top: "-5px",
  };

  const nameStyle = {
    color: "#000000",
    fontFamily: "Verdana",
    fontSize: "18px",
    fontWeight: "400",
    marginLeft: "20px",
    position: "relative",
    top: "-5px",
  };

  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
      </div>

      <div className="page-title">
        <div className="header_tabs">
          <div
            className={`header-tab ${
              currentPage.startsWith("/dashboard") ? "active" : ""
            }`}
            onClick={() => navigate("/dashboard")}
          >
            <HomeIcon className="icon" />
            <Typography className="icon-text">Dashboard</Typography>
          </div>
          {(localStorage.getItem("MRO_user_type") !== "edp-mro-llm-app-search-limited" && process.env.REACT_APP_ENV_TYPE === "DEV") && (
            <div
            className={`header-tab ${
              currentPage.includes("history") ? "active" : ""
            }`}
            onClick={() =>
              navigate(
                getEmailSpecificURL(localStorage.getItem("MRO_user_type"))
              )
            }
          >
            <PartHistoryIcon className="icon" />
            <Typography className="icon-text">Part Request History</Typography>
          </div>
          )}
        </div>
      </div>
      <div className="icons-container">
        {((localStorage.getItem("MRO_user_type") === "edp-mro-llm-app-plant-owner"|| localStorage.getItem("MRO_user_type") === "edp-mro-llm-app-maintenance-manager" || localStorage.getItem("MRO_user_type") === "edp-mro-llm-app-admin") && process.env.REACT_APP_ENV_TYPE === "DEV") && (
          <button className="custom-button" onClick={handleButtonClick}>
            <AddIcon style={{ color: "#c51c1c", marginRight: "5px" }} />
            <span
              style={{
                color: "#c51c1c",
                position: "relative",
                top: "-4px",
                fontFamily: "Verdana",
              }}
            >
              Create New Part
            </span>
          </button>
        )}
        <div className="userHeading">
          <UserIcon className="user-icon" />
          <div className="userSubHeading">
            <Typography className="icon" component="span" sx={typoStyle}>
              Welcome back,
            </Typography>

            <Typography component="span" className="icon" sx={nameStyle}>
              {localStorage.getItem("MRO_user_name")}
            </Typography>
            {localStorage.getItem("MRO_user_type") ===
            "edp-mro-llm-app-plant-owner" ? (
              <Typography sx={typoStyle}>Plant Owner</Typography>
            ) : 
            localStorage.getItem("MRO_user_type") ===
            "edp-mro-llm-app-maintenance-manager" ? (
              <Typography sx={typoStyle}>Maintenance Manager</Typography>
            )
            :localStorage.getItem("MRO_user_type") ===
            "edp-mro-llm-app-admin" ? (
              <Typography sx={typoStyle}>Admin</Typography>
            ):localStorage.getItem("MRO_user_type") ===
              "edp-mro-llm-app-data-owner" ? (
              <Typography sx={typoStyle}>Data Owner</Typography>
            ) : (
              <Typography sx={typoStyle}>ETC</Typography>
            )}
            <Typography sx={typoStyle}>
              {localStorage.getItem("MRO_user_location")}
            </Typography>
          </div>
          <IconButton
            onClick={handleClick}
            color="inherit"
            aria-controls="user-menu"
            aria-haspopup="true"
            size="small"
            className="arrow-icon"
          >
            <ArrowIcon />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout} sx={{ px: "8px", py: "0px" }}>
              <Typography
                sx={{
                  fontFamily: "Verdana",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
