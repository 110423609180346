import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  OutlinedInput,
  Divider,
  Chip,
  ListSubheader,
  InputAdornment,
  TextField,
  IconButton,
  Autocomplete,
  FormControl,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as DropdownIcon } from "../../assets/images/DropdownIcon.svg";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";
import "../../styles/partCreation.css";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import PartModificationSucess from "./PartModificationSuccess";
import { ToastContainer, toast } from "react-toastify";
import { automationHttpRequest } from "../../api/httpRequests";

const AttributeChange = (props) => {
  const {
    uniqueId,
    materialNumber,
    selectAction,
    handleBackClick,
    handleClose,
  } = props;
  const [noun, setNoun] = useState();
  const [plantName, setPlantName] = useState();
  const [modifier, setModifier] = useState();
  const [nouns, setNouns] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [attributes, setAttributes] = useState();
  const [attributeValues, setAttributeValues] = useState({});
  const [uomValues, setUOMValues] = useState({});
  const [attributeValue, setAttributeValue] = useState({});
  const [chipValues, setChipValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [uomValue, setUomValue] = useState({});
  const [updateAttributeValues, setUpdatedAttributeValues] = useState({});
  const [typeAttributeValue, setTypeAttributeValue] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [modifierSearch, setModifierSearch] = useState("");
  const [optionToShow, setOptionToShow] = useState([]);
  const [topAttribute, setTopAttribute] = useState([]);
  const [registeredAttribute, setRegisteredAttribute] = useState({});
  const [attributeTypeValue, setAttributeTypeValue] = useState({});
  const [requestID, setRequestID] = useState(false);
  const [modifyLoader, setModifyLoader] = useState(false);
  const [partModificationDialog, setPartModificationDialog] = useState(false);
  const [cleanLongDescObj, setCleanLongDescObj] = useState([]);
  const [attributeData, setAttributeData] = useState();
  const [cleanShortDescription, setCleanShortDescription] = useState({});
  const [addAttributeValue, setAddAttributeValue] = useState("");
  const [attributeName, setAttributeName] = useState("");
  const [attributeUomValue, setAttributeUoMValue] = useState("");
  const [showUoMInput, setShowUoMInput] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const uomOptions = [
    "DZ",
    "FT",
    "RL",
    "MIL",
    "M",
    "TH",
    "BOX",
    "DR",
    "BTE",
    "KG",
    "BAG",
    "L",
    "EA",
    "PAK",
    "GLL",
    "PAA",
    "CS",
    "LB",
    "PAQ",
  ];
  useEffect(() => {
    if (uomValue && Object.keys(uomValue).length) {
      const mappedObject = Object.fromEntries(
        Object.entries(attributeValue).map(([key, value]) => {
          if (Object.keys(uomValue).includes(key)) {
            const result = uomValue[key];
            return [key, `${value} ${result}`];
          } else {
            return [key, value];
          }
        })
      );
      setUpdatedAttributeValues(mappedObject);
    } else {
      setUpdatedAttributeValues(attributeValue);
    }
  }, [attributeValue, uomValue]);

  useEffect(() => {
    const result = chipValues.join(" ");
    setCleanShortDescription(chipValues.join(" "));
  }, [chipValues]);

  useEffect(() => {
    const resultString = cleanLongDescObj.reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    setAttributeData(resultString);
  }, [cleanLongDescObj]);

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  };
  const modifierInput = {
    margin: "0px 200px 0px",
  };

  const headingStyle = {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: "600",
    // margin: "30px 0px 10px 40px",
  };
  const headingStyleHeading = {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: "600",
    marginLeft:"10px"
    // margin: "30px 0px 10px 40px",
  };
  const headingStyles = {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: "600",
    // margin: "30px 0px 10px 190px",
  };

  const headingStyleNoData = {
    fontSize: 20,
    fontFamily: "Verdana",
    fontWeight: "600",
    margin: "10px 0px 10px 40px",
    color: "#C6003D",
    marginLeft: "500px",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };

  const uomProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };

  useEffect(() => {
    fetchDefaultAttributeValues();
  }, []);

  const handleModify = () => {
    const convertedObject = attributes.reduce((result, currentObject) => {
      const key = Object.keys(currentObject)[0];
      const value = currentObject[key];
      result[key] = value;
      return result;
    }, {});
    setModifyLoader(true);
    updateReplishmentLevel(convertedObject);
  };

  const handleAddAttribute = () => {
    if (attributeName && addAttributeValue) {
      const attributeObject = {};
      attributeObject[
        attributeName
      ] = `${addAttributeValue} ${attributeUomValue}`;
      setAttributes((prevAttributes) => [...prevAttributes, attributeObject]);
      setAttributeUoMValue("");
      setAddAttributeValue("");
      setAttributeName("");
    }
  };

  const handleAttributeValueChange = (e) => {
    const inputValue = e.target.value;
    setAddAttributeValue(inputValue);
    if (isNaN(inputValue) || inputValue.trim() === "") {
      setShowUoMInput(false);
    } else {
      setShowUoMInput(true);
    }
  };

  const updateReplishmentLevel = async (attributeObject) => {
    try {
      const userEmailId = localStorage.getItem("MRO_user_email")
      const response = await automationHttpRequest.post(
        "/attribute_insert/",
        {
          material_number: materialNumber,
          noun_indentifier: noun,
          modifier: modifier,
          clean_short_description: cleanShortDescription,
          attribute_data: attributeObject,
          plant_name: plantName,
          submitted_by: userEmailId,
          type: "attribute",
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setRequestID(response?.data?.data.request_id);
        setPartModificationDialog(true);
        setTimeout(() => {
          setModifyLoader(false);
        }, 1200);
      }
    } catch (error) {
      setModifyLoader(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };

  const fetchDefaultAttributeValues = async () => {
    setFetching(true);
    try {
      const response = await automationHttpRequest.post(
        "/get_attribute_details/",
        {},
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {
            material_number: materialNumber,
          },
        }
      );
      if (response?.data?.status) {
        if (response?.data?.data.length > 0) {
          setNoun(response?.data?.data[0].noun);
          setChipValues([response?.data?.data[0].noun]);
          setModifier(response?.data?.data[0].modifier);
          setCleanShortDescription(
            response?.data?.data[0].clean_short_description.replace(/,/g, " ")
          );
          setChipValues(
            response?.data?.data[0].clean_short_description.split(",")
          );
          const attributeValues = Object.entries(
            response?.data?.data[0].clean_long_description
          ).map(([key, value]) => ({ [key]: value }));
          setAttributes(attributeValues);
          setFetching(false);
          setPlantName(response?.data?.data[0].plant_name);
        } else {
          setFetching(false);
          setNoDataAvailable(true);
        }
      }
    } catch (error) {
      toast.error("Unable get Noun attributes. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const handleAttributeChange = (attribute, value, type) => {
    const existingObjIndex = cleanLongDescObj.findIndex((obj) =>
      obj.hasOwnProperty(attribute)
    );

    if (existingObjIndex !== -1) {
      // If an object with the specified attribute exists, update the value
      const updatedObj = {
        ...cleanLongDescObj[existingObjIndex],
        [attribute]: value,
      };
      const updatedArray = [...cleanLongDescObj];
      updatedArray[existingObjIndex] = updatedObj;
      setCleanLongDescObj(updatedArray);
    } else {
      // If no object with the specified attribute exists, create a new object
      const newObj = { [attribute]: value };
      setCleanLongDescObj([...cleanLongDescObj, newObj]);
    }

    if (type === "select") {
      setAttributeValue((prevAttributeValue) => ({
        ...prevAttributeValue,
        [attribute]: value,
      }));
    } else {
      setAttributeValue((prevAttributeValue) => ({
        ...prevAttributeValue,
        [attribute]: value,
      }));
      setAttributeTypeValue((prevAttributeValue) => ({
        ...prevAttributeValue,
        [attribute]: value,
      }));
    }
    const index = topAttribute.indexOf(attribute) + 2;

    if (index !== -1) {
      // if (index !== -1 && type === "select") {
      if (
        chipValues.length < 8 ||
        (chipValues.length === 8 &&
          Object.keys(registeredAttribute).includes(attribute))
      ) {
        if (
          chipValues.length - 1 < index &&
          !Object.keys(registeredAttribute).includes(attribute)
        ) {
          setChipValues([...chipValues, value]);
          setRegisteredAttribute((prev) => ({
            ...prev,
            [attribute]: value,
          }));
        } else {
          const keys = Object.keys(registeredAttribute);
          if (keys.includes(attribute)) {
            let val = registeredAttribute?.[attribute];
            const myArray = [...chipValues];
            myArray.splice(myArray.indexOf(val), 1, value);
            setChipValues([...myArray]);
            setRegisteredAttribute((prev) => ({
              ...prev,
              [attribute]: value,
            }));
          } else {
            const array = [...chipValues];
            array.splice(index, 0, value);
            setChipValues([...array]);
          }
        }
      }
    }
  };

  const handleUomChange = (attribute, value) => {
    setUomValue((prevAttributeValue) => ({
      ...prevAttributeValue,
      [attribute]: value,
    }));
    // if (chipValues.length < 6) {
    //   setChipValues([...chipValues, value]);
    // }
  };

  const handleReset = () => {
    setNouns([]);
    setModifiers([]);
    setNoun();
    setPlantName();
    setModifier();
    setAttributes([]);
    setAttributeValues({});
    setChipValues([]);
    setRegisteredAttribute({});
    setCleanLongDescObj([]);
    fetchDefaultAttributeValues();
  };

  const handleNext = () => {
    // handleContinue()
  };

  const handlechipsValues = (values) => {
    const val = values.join(" ");
    localStorage.setItem("shortDesc", val);
    return val;
  };

  const handleChange = (event) => {
    setAttributeUoMValue(event?.target?.value)
  }

  const handleSearchTerm = (event) => {
    event.preventDefault();
    // setSearchTerm(event?.target?.value)
    const value = nouns.filter((option) =>
      option.toLowerCase().includes(event?.target?.value.toLowerCase())
    );
    setOptionToShow(value.slice(0, 100));
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          margin: "20px 0px",
          borderRadius: "18px",
        }}
        elevation={2}
      >
        {fetching ? (
          <>
            <Typography
              sx={{
                // height: "70px",
                fontSize: "18px",
                padding: "20px",
                background: "#fff",
                fontFamily: "Verdana",
              }}
            >
              Loading...
            </Typography>
          </>
        ) : (
          <>
            {noDataAvailable ? (
              <Typography style={headingStyleNoData}>
                No Data Available
              </Typography>
            ) : (
              <>
                <div
                  style={{ margin: "25px 40px 40px 40px", paddingTop: "5px" }}
                >
                  <div className="modificationStyle">
                    <IconButton onClick={() => handleBackClick()}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "Verdana",
                        fontWeight: 600,
                        margin: "20px 0px",
                      }}
                    >
                      {" "}
                      Back
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      alignItems: "center",
                    }}
                  >
                    <Plant />
                    <div style={inputStyle}>
                      <Typography style={headingStyleHeading} >
                        Modification Required
                      </Typography>
                      <FormControl sx={{ m: 1, width: "200px" }}>
                        <div className="select-container">
                          <input
                            // placeholder="Part Extension"
                            name="activePart"
                            style={{ background: "#EFEFEF" }}
                            type="text"
                            value={plantName}
                            className="partInputField"
                            disabled
                          />
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  <div className="modificationStyle">
                    <Typography
                      sx={{
                        fontFamily: "Verdana",
                        fontWeight: 600,
                        margin: "20px 0px",
                      }}
                    >
                      {" "}
                      Attribute Change
                    </Typography>
                  </div>

                  <div className="partDesc" style={{ paddingBottom: "20px" }}>
                    <div style={inputStyle} className="selectComponent">
                      <Typography style={headingStyle}>Noun</Typography>
                      {/* <Select
                    sx={{
                      height: "32px",
                      borderRadius: "8px",
                      width: "250px !important",
                      background: "#FFFFFF",
                      marginRight: "300px",
                    }}
                    value={noun ? noun : "select"}
                    onChange={(event) => handleNounChange(event?.target?.value)}
                    inputProps={{ "aria-label": "Without label" }}
                    input={<OutlinedInput />}
                    IconComponent={() => <DropdownIcon style={{ width: "20px", position: "relative", left: "-10px" }} />}
                    MenuProps={MenuProps}
                  >
                    <ListSubheader sx={{ background: "#EAEFF8", padding: "4px" }}>
                      <TextField
                        size="small"
                        autoFocus
                        placeholder='Search'
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                        onChange={handleSearchTerm}
                        onKeyDown={(e) => {
                          // if (e.key !== "Escape") {
                          e.stopPropagation();
                          // }
                        }}
                      />
                    </ListSubheader>
                    <MenuItem disabled value="select">
                      <span style={{ color: "#333333" }}>Select Noun</span>
                    </MenuItem>
                    {optionToShow.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select> */}
                      <TextField
                        className="formControlSelect"
                        value={noun}
                        // onChange={(e) => handleModifierChange(e.target.value)}
                        variant="outlined"
                        InputProps={{
                          readOnly: true, // Make the input field non-editable
                        }}
                        sx={{ marginLeft: "10px" }}
                      />
                    </div>
                    <div style={modifierInput} className="selectComponent">
                      <Typography style={headingStyles}>Modifier</Typography>

                      <TextField
                        style={inputStyle}
                        placeholder="Enter Modifier"
                        value={modifier}
                        // onChange={(e) => handleModifierChange(e.target.value)}
                        variant="outlined"
                        InputProps={{
                          readOnly: true, // Make the input field non-editable
                        }}
                      />
                    </div>
                  </div>

                  {noun && (
                    <div>
                      <Divider />
                      <div style={inputStyle}>
                        <Typography style={headingStyle}>
                          Description
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Verdana",
                            margin: "10px 0px 10px 0px",
                          }}
                        >
                          Clean Short Description
                        </Typography>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid #BDBDBD",
                            borderRadius: "8px",
                            padding: "10px",
                          }}
                        >
                          <Typography className="shortDesc">
                            {handlechipsValues(chipValues)}
                          </Typography>
                        </div>

                        {modifier && (
                          <>
                            {false && <p>Loading...</p>}
                            {true && (
                              <>
                                <Typography
                                  style={{
                                    fontFamily: "Verdana",
                                    margin: "10px 0px 10px 0px",
                                  }}
                                >
                                  Clean Long Description
                                </Typography>
                                <div
                                  style={{
                                    border: "1px solid #BDBDBD",
                                    borderRadius: "8px",
                                    padding: "20px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "baseline",
                                      gap: "1px",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        marginBottom: "10px",
                                        marginLeft: "10px",
                                        fontFamily: "Verdana",
                                        background: "#EFEFEF",
                                        padding: "10px",
                                        fontSize: "13px",
                                        borderRadius: "8px",
                                        width: "fit-content",
                                      }}
                                    >
                                      {noun} {modifier}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "baseline",
                                      gap: "2px",
                                      marginBottom:"15px"
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: "Verdana",
                                        margin: "10px 0px",
                                        marginLeft: "10px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Add Attribute
                                    </Typography>
                                    <input
                                      placeholder="Name"
                                      value={attributeName}
                                      style={{
                                        // margin: "0px 10px",
                                        marginLeft: "10px",
                                        marginRight: "2px",
                                        fontFamily: "Verdana",
                                        borderRadius: "8px",
                                        padding: "6px 10px",
                                        height: "32px",
                                        outlineStyle: "none",
                                        border: "1px solid #cbc3c3",
                                      }}
                                      onChange={(e) =>
                                        setAttributeName(
                                          e.target.value.toUpperCase()
                                        )
                                      }
                                    />

                                    <input
                                      placeholder="Value"
                                      value={addAttributeValue}
                                      onChange={handleAttributeValueChange}
                                      style={{
                                        borderRadius: "8px",
                                        marginRight: "2px",
                                        fontFamily: "Verdana",
                                        padding: "6px 10px",
                                        height: "32px",
                                        outlineStyle: "none",
                                        border: "1px solid #cbc3c3",
                                        width: "100px",
                                      }}
                                    />
                                        {showUoMInput && (
                                          <>
                                            <FormControl sx={{ m: 1, width: "100px" }}>
                                              <Select
                                                value={attributeUomValue}
                                                name="baseUOM"
                                                onChange={handleChange}
                                                IconComponent={() => (
                                                  <DropdownIcon
                                                    style={{
                                                      width: "30px",
                                                      position: "relative",
                                                      left: "-10px",
                                                    }}
                                                  />
                                                )}
                                                MenuProps={MenuProps}
                                                sx={{ height: "32px", borderRadius: "8px" }}
                                              >
                                                {uomOptions.map((option) => (
                                                  <MenuItem key={option} value={option}>
                                                    {option}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </>
                                        )}
                                    <button
                                      type="submit"
                                      className="submitButton"
                                      style={{
                                        fontSize: 16,
                                        cursor: "pointer",
                                        // position: "relative",
                                        fontFamily: "Verdana",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleAddAttribute();
                                      }}
                                      disabled={false}
                                    >
                                      Add
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                      gap: "1px",
                                    }}
                                  >
                                    {attributes.map((attribute) => (
                                      <div
                                        key={Object.keys(attribute)[0]} // Assuming each attribute has only one key
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "baseline",
                                          width: "48%",
                                          margin: "0px 10px 0px 10px",
                                          paddingRight: "30px",
                                        }}
                                      >
                                        {Object.entries(attribute).map(
                                          ([key, value]) => (
                                            <React.Fragment key={key}>
                                              <Typography
                                                style={headingStyle}
                                                sx={{ width: "250px" }}
                                              >
                                                {key} :
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  width: "250px",
                                                  fontFamily: "Verdana",
                                                }}
                                              >
                                                {value}
                                              </Typography>

                                              {uomValues[key] &&
                                                uomValues[key].length > 0 && (
                                                  <Select
                                                    displayEmpty
                                                    sx={{
                                                      height: "32px",
                                                      borderRadius: "8px",
                                                      width: "80px",
                                                      background: "#FFFFFF",
                                                      margin: "10px 0px",
                                                      right: "25px",
                                                    }}
                                                    value={uomValue[key] || ""}
                                                    onChange={(event) =>
                                                      handleUomChange(
                                                        key,
                                                        event.target.value
                                                      )
                                                    }
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                    IconComponent={() => (
                                                      <DropdownIcon
                                                        style={{
                                                          width: "20px",
                                                          position: "relative",
                                                          left: "-10px",
                                                        }}
                                                      />
                                                    )}
                                                    MenuProps={uomProps}
                                                  >
                                                    <MenuItem disabled value="">
                                                      <span
                                                        style={{
                                                          color: "#333333",
                                                        }}
                                                      >
                                                        Select
                                                      </span>
                                                    </MenuItem>
                                                    {uomValues[key]?.map(
                                                      (value) => (
                                                        <MenuItem
                                                          key={value}
                                                          value={value}
                                                        >
                                                          {value}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </Select>
                                                )}
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div style={{ margin: "25px 0px" }}>
                    <button
                      type="submit"
                      className="submitButton"
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: "Verdana",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleModify();
                      }}
                      disabled={false}
                    >
                      {modifyLoader ? (
                        // Render loader or loading state here
                        <div>Loading...</div>
                      ) : (
                        // Render button text
                        "Modify"
                      )}
                    </button>
                    <button
                      type="submit"
                      className="cancelButton"
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: "Verdana",
                      }}
                      onClick={(e) => {
                        handleReset();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="cancelButton"
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: "Verdana",
                      }}
                      onClick={(e) => {
                        handleReset();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <PartModificationSucess
                  partModificationDialog={partModificationDialog}
                  setPartModificationDialog={setPartModificationDialog}
                  requestID={requestID}
                  handleClose={handleClose}
                />
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default AttributeChange;
