import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Typography,
  Divider,
  Box,
  Tooltip,
  IconButton,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";
import { ReactComponent as Setting } from "../../assets/images/Setting.svg";
import { ReactComponent as Date } from "../../assets/images/Date.svg";
import { ReactComponent as User } from "../../assets/images/User.svg";
import { ReactComponent as ModificationIconSetting } from "../../assets/images/ModificationIconSetting.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import "../../styles/ownerPartRequest.css";
import { ReactComponent as Info } from "../../assets/images/Info.svg";
import { ReactComponent as MatrixCriteria } from "../../assets/images/MatrixCriteria.svg";
import { automationHttpRequest } from "../../api/httpRequests";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const AtcModificationDialog = ({
  partRequestNumber,
  open,
  dialogData,
  closeDialog,
  modificationType,
  dialogLoading,
}) => {
  const [ownerComment, setOwnerComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [approveResult, setApproveResult] = React.useState("");
  React.useEffect(() => {
    setApproveResult("");
  }, [open]);

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    color: "#212F37",
    margin: "0px 10px",
  };

  const handleRejectRequest = () => {};

  const handleApprovePartRequest = async () => {
    // const values = {
    //     "material_number": partRequestNumber.toString(),
    // }
    try {
      setLoading(true);
      const response = await automationHttpRequest.get(
        `/compare_modification_data/?material_number=${dialogData?.material_number}/`,
        {},
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
        }
      );
      if (response?.data) {
        // toast.success(response?.data?.data?.message , {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        setApproveResult(response?.data?.result);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleApprovePartRequest();
          }}
        >
          {loading && (
            <div
              className="loader"
              style={{ borderTop: "2px dotted white" }}
            ></div>
          )}
          {!loading && btnText}
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const headingStyle = {
    fontWeight: "700",
  };

  const handleClose = () => {
    closeDialog();
  };

  const handleApproveRequest = async () => {
    const values = {
      request_id: partRequestNumber,
    };
    try {
      setLoading(true);
      const response = await automationHttpRequest.post(
        "/modification_update/",
        { ...values },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setLoading(false);
        toast.success(response?.data?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        closeDialog();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };

  const ValueDisplay = ({
    future,
    justification,
    justifyValue,
    futureValue,
    current,
    currentValue,
    comment,
    comments,
  }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {current && (
            <Typography className="smallNarrowValue" style={headingStyle}>
              {currentValue}
            </Typography>
          )}
          {future && (
            <Typography className="smallNarrowValue" style={headingStyle}>
              {futureValue}
            </Typography>
          )}
          {comment && (
            <Typography
              className="narrowValue"
              style={{ ...headingStyle, width: "600px" }}
            >
              {comments}
            </Typography>
          )}
          {justification && (
            <Typography
              className="narrowValue"
              style={{ ...headingStyle, width: "300px" }}
            >
              {justifyValue.length > 30 ? (
                <Tooltip title={justifyValue} arrow>
                  <span>{justifyValue.slice(0, 30) + "..."}</span>
                </Tooltip>
              ) : (
                justifyValue
              )}
            </Typography>
          )}
        </div>
      </>
    );
  };

  const showInformation = () => {
    return (
      <>
        <div
          style={{
            height: "540px",
            width: "500px",
            position: "relative",
            top: "-20px",
            left: "-56px",
          }}
        >
          <MatrixCriteria />
        </div>
      </>
    );
  };

  const MaterialCardData = () => {
    const attributeData = dialogData?.attribute_data;
    let cardData;

    try {
      const jsonObject =
        typeof attributeData === "object" &&
        Object.keys(attributeData).length > 0
          ? attributeData
          : {};
      cardData = jsonObject;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    const cardDataValue = Object.entries(cardData);
    const middleIndex = Math.ceil(cardDataValue.length / 2);
    const materialNumberDetailsFirstRow = cardDataValue.slice(0, middleIndex);
    const materialNumberDetailsSecondRow = cardDataValue.slice(middleIndex);

    return (
      <>
        <div
          style={{
            display: "flex",
            margin: "10px",
            borderRadius: "4px",
          }}
        >
          <div className="row">
            {materialNumberDetailsFirstRow.map(([element, value], index) => (
              <>
                <div className="rowelement">
                  <div key={index} className="elementHeading">
                    {element} :-
                  </div>
                  <div key={index} className="element">
                    {value ? value : "--"}
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row">
            {materialNumberDetailsSecondRow.map(([element, value], index) => (
              <>
                <div className="rowelement">
                  <div key={index} className="elementHeading">
                    {element} :-
                  </div>
                  <div key={index} className="element">
                    {value ? value : "--"}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            maxWidth: "1400px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="dialogHeading">
            <ModificationIconSetting />
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              Part Modification
            </Typography>
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "14px",
                fontWeight: "600",
                background: "#EFEFEF",
                padding: "6px",
                borderRadius: "4px",
              }}
            >
              Requested ID-{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "300",
                  marginLeft: "10px",
                }}
              >
                #{partRequestNumber}
              </span>
            </Typography>
          </div>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ width: "1260px", margin: "10px" }}>
          {dialogLoading ? (
            <>
              <Box sx={{ display: "flex" }}>
                <CircularProgress style={{ color: "#C6003D" }} />
              </Box>
            </>
          ) : (
            <DialogContentText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px",
                }}
              >
                <div className="headers">
                  <Setting />
                  <div style={inputStyle}>
                    <Typography>Material Number</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.material_number || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <Plant />
                  <div style={inputStyle}>
                    <Typography>Extension Required For Plant</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.plant_name || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <User />
                  <div style={inputStyle}>
                    <Typography>Approved By</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.submitted_by || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <Date />
                  <div style={inputStyle}>
                    <Typography>Date of Approval</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.date_of_submission || "-"}
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider style={{ margin: "20px 0px", background: "#EFEFEF" }} />
              {modificationType === "plant_specific" && (
                <>
                  <Card>
                    <CardContent sx={{ margin: "20px" }}>
                      <>
                        <div className="creationHeading">
                          <Typography style={{ color: "black", fontWeight: 600, }}>
                            Current Value
                          </Typography>
                          <Typography style={{ color: "black",  fontWeight: 600, }}>
                            Future Value
                          </Typography>
                          <Typography style={{ color: "black", fontWeight: 600, }}>
                            Justification for Requested Changes
                          </Typography>
                        </div>
                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Minimum Stock
                          </Typography>
                          <ValueDisplay
                            current={true}
                            future={true}
                            justification={true}
                            justifyValue={
                              dialogData?.justification_for_change_in_minimum_stock
                                ? dialogData?.justification_for_change_in_minimum_stock
                                : "-"
                            }
                            currentValue={
                              dialogData?.minimum_stock_level
                                ? dialogData?.minimum_stock_level
                                : "-"
                            }
                            futureValue={
                              dialogData?.future_minimum_stock_level
                                ? dialogData?.future_minimum_stock_level
                                : "-"
                            }
                          />
                        </div>

                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Maximum Stock
                          </Typography>
                          <ValueDisplay
                            future={true}
                            current={true}
                            justification={true}
                            justifyValue={
                              dialogData?.justification_for_change_in_maximum_stock
                                ? dialogData?.justification_for_change_in_maximum_stock
                                : "-"
                            }
                            currentValue={
                              dialogData?.maximum_stock_level
                                ? dialogData?.maximum_stock_level
                                : "-"
                            }
                            futureValue={
                              dialogData?.future_maximum_stock_level
                                ? dialogData?.future_maximum_stock_level
                                : "-"
                            }
                          />
                        </div>
                      </>
                    </CardContent>
                  </Card>
                  <Card sx={{ marginTop: "20px" }}>
                    <CardContent sx={{ margin: "20px" }}>
                      <>
                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Df Plant
                          </Typography>
                          <ValueDisplay
                            future={true}
                            current={true}
                            currentValue={
                              dialogData?.df_plant
                                ? dialogData?.df_plant === "X"
                                  ? "Deactivate"
                                  : "Active"
                                : "Active"
                            }
                          />
                        </div>

                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Df Storage Location
                          </Typography>
                          <ValueDisplay
                            future={true}
                            current={true}
                            currentValue={
                              dialogData?.df_storage_location
                                ? dialogData?.df_storage_location === "X"
                                  ? "Deactivate"
                                  : "Active"
                                : "Active"
                            }
                          />
                        </div>
                      </>
                    </CardContent>
                  </Card>
                  <Card sx={{ marginTop: "20px" }}>
                    <CardContent sx={{ margin: "20px" }}>
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "10px",
                          }}
                        ></div>

                        {/* <Divider
                    style={{ background: "#EFEFEF", margin: "20px 0px" }}
                  /> */}
                        <div className="creationHeading">
                          <Typography style={{ color: "black", fontWeight: 600, }}>
                            Current Value
                          </Typography>
                          <Typography style={{ color: "black",  fontWeight: 600, }}>
                            Future Value
                          </Typography>
                          <Typography style={{ color: "black", fontWeight: 600, }}>
                            Justification for Requested Changes
                          </Typography>
                        </div>
                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            ABC Indicator
                          </Typography>
                          <ValueDisplay
                            future={true}
                            current={true}
                            justification={true}
                            futureValue={
                              dialogData?.future_abc_indicator
                                ? dialogData?.future_abc_indicator
                                : "-"
                            }
                            currentValue={
                              dialogData?.ABC_indicator
                                ? dialogData?.ABC_indicator
                                : "-"
                            }
                            justifyValue={
                              dialogData?.justification_for_change_in_abc_indicator
                                ? dialogData?.justification_for_change_in_abc_indicator
                                : "-"
                            }
                          />
                        </div>

                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Criticality Indicator
                          </Typography>
                          <ValueDisplay
                            future={true}
                            justification={false}
                            futureValue={
                              dialogData?.critical_part
                                ? dialogData?.critical_part
                                : "-"
                            }
                          />
                        </div>
                        {/* <div className="valueDisplay">
                    <Typography className="subHeading">
                      Minimum Stock
                    </Typography>
                    <ValueDisplay
                      future={true}
                      justification={true}
                      justifyValue={
                        dialogData?.justification_for_change_in_minimum_stock
                          ? dialogData?.justification_for_change_in_minimum_stock
                          : "-"
                      }
                      futureValue={
                        dialogData?.minimum_stock_level
                          ? dialogData?.minimum_stock_level
                          : "-"
                      }
                    />
                  </div> */}
                        {/* <Divider
                    style={{ background: "#EFEFEF", margin: "4px 0px" }}
                  />
                  <div className="valueDisplay">
                    <Typography className="subHeading">
                      Maximum Stock
                    </Typography>
                    <ValueDisplay
                      future={true}
                      justification={true}
                      justifyValue={
                        dialogData?.justification_for_change_in_maximum_stock
                          ? dialogData?.justification_for_change_in_maximum_stock
                          : "-"
                      }
                      futureValue={
                        dialogData?.maximum_stock_level
                          ? dialogData?.maximum_stock_level
                          : "-"
                      }
                    />
                  </div> */}

                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Storage Location
                          </Typography>
                          <ValueDisplay
                            future={true}
                            justification={false}
                            futureValue={
                              dialogData?.storage_location
                                ? dialogData?.storage_location
                                : "-"
                            }
                          />
                        </div>

                        <div className="valueDisplay">
                          <Typography className="subHeading">
                            Storage Bin
                          </Typography>
                          <ValueDisplay
                            future={true}
                            justification={false}
                            futureValue={
                              dialogData?.storage_bin
                                ? dialogData?.storage_bin
                                : "-"
                            }
                          />
                        </div>
                        {/* <Divider
                    style={{ background: "#EFEFEF", margin: "4px 0px" }}
                  /> */}
                        {/* <div>
                    <Typography className="equipmentNumber">
                      Equipment Number(s) For BOM
                    </Typography>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20%",
                        }}
                      >
                        <Typography>
                          {dialogData?.equipment_number_for_bom}
                        </Typography>
                      </div>
                    </div>
                  </div> */}
                      </>
                    </CardContent>
                  </Card>
                </>
              )}

              {modificationType === "attribute" && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "10px",
                    }}
                  >
                    <Typography
                      style={headingStyle}
                      sx={{ color: "#212F37", fontSize: "18px" }}
                    >
                      Noun
                    </Typography>
                    <Typography
                      style={{
                        margin: "0px 20px",
                        background: "#EFEFEF",
                        padding: "4px",
                        borderRdius: "4px",
                      }}
                    >
                      {dialogData?.noun_indentifier}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "10px",
                    }}
                  >
                    <Typography
                      style={headingStyle}
                      sx={{ color: "#212F37", fontSize: "18px" }}
                    >
                      Modifier
                    </Typography>
                    <Typography
                      style={{
                        margin: "0px 20px",
                        background: "#EFEFEF",
                        padding: "4px",
                        borderRdius: "4px",
                      }}
                    >
                      {dialogData?.modifier}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "10px",
                    }}
                  >
                    <Typography
                      style={headingStyle}
                      sx={{ color: "#212F37", fontSize: "18px" }}
                    >
                      Clean Short Description
                    </Typography>
                    <Typography style={{ margin: "10px 0px" }}>
                      {dialogData?.clean_short_description}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "10px",
                    }}
                  >
                    <Typography
                      style={headingStyle}
                      sx={{ color: "#212F37", fontSize: "18px" }}
                    >
                      Clean Long Description
                    </Typography>
                    <Typography style={{ margin: "10px 0px" }}>
                      {MaterialCardData()}
                    </Typography>
                  </div>
                </div>
              )}
              <div className="valueDisplay">
                <Typography className="subHeading" sx={{ marginLeft: "20px" }}>
                  Comments
                </Typography>
                <ValueDisplay
                  comment={true}
                  comments={
                    dialogData?.modification_comment
                      ? dialogData?.modification_comment
                      : "-"
                  }
                />
              </div>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-start !important",
          }}
        >
          <ButtonFamily btnText="Quality Score the Entry" />
        </DialogActions>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {approveResult === "Pass" && (
            <>
              <button
                type="submit"
                className="submitButton"
                style={{
                  fontSize: 16,
                  position: "relative",
                  fontFamily: "Verdana",
                }}
              >
                {approveResult}
              </button>
              <Typography
                sx={{
                  padding: "8px",
                  marginLeft: "10px",
                  border: "2px dotted red",
                  borderRadius: "20px",
                  background: "#EFEFEF",
                }}
              >
                Details matched with internal records and requestor notified
              </Typography>
            </>
          )}
          {approveResult === "Fail" && (
            <>
              <button
                type="submit"
                className="submitButton"
                style={{
                  fontSize: 16,
                  position: "relative",
                  fontFamily: "Verdana",
                }}
              >
                {approveResult}
              </button>
              <Typography
                sx={{
                  padding: "8px",
                  marginLeft: "10px",
                  border: "2px dotted red",
                  borderRadius: "20px",
                  background: "#EFEFEF",
                }}
              >
                Details mismatched with the internal record please revalidate
              </Typography>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AtcModificationDialog;
