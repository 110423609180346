import React, {useState} from "react";
import { Typography, Paper, Divider, IconButton } from "@mui/material";
import { ReactComponent as Rotate } from '../../assets/images/Rotate.svg';
import '../../styles/partCreation.css';
import { ReactComponent as ButtonPlus } from '../../assets/images/ButtonPlus.svg';
import {automationHttpRequest} from "../../api/httpRequests";

const PartInformation = ({setPartInformationData, handleContinue, setEquipment_number_for_bom}) => {
  
  const [abcIndicator, setAbcIndicator] = useState({
    abcIndicatorFutureValue: "",
    // abcIndicatorJustify: ""
  })
  const [minimumValue, setMinimumValue] = useState({
    minimumFutureValue: "",
    // minimumValueJustify: ""
  })
  const [maximumValue, setMaximumValue] = useState({
    maximumFutureValue: "",
    // maximumValueJustify: ""
  })
  const [criticalityIndicator, setCriticalityIndicator] = useState("")
  const [sloc, setSloc] = useState("")
  const [bin, setBin] = useState("")
  const [inputCount, setInputCount] = useState(1);
  const [inputValues, setInputValues] = useState([''])

    const inputStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        width: "25%"
      }
    
      const headingStyle = {
        fontWeight: "700",
      }

      const handleFutureValue = (event) => {

        if (event?.target?.name === "abcIndicator") {
          setAbcIndicator((prevObject) => ({
            ...prevObject,
            abcIndicatorFutureValue: event?.target?.value,
          }));
          setPartInformationData((prevObject) => ({
            ...prevObject,
            ABC_indicator: event?.target?.value,
          }));
        }
        if (event?.target?.name === "minimumValue") {
          setMinimumValue((prevObject) => ({
            ...prevObject,
            minimumFutureValue: event?.target?.value,
          }));
          setPartInformationData((prevObject) => ({
            ...prevObject,
            minimum_stock_level: event?.target?.value,
          }));
        }
        if (event?.target?.name === "maximumValue") {
          setMaximumValue((prevObject) => ({
            ...prevObject,
            maximumFutureValue: event?.target?.value,
          }));
          setPartInformationData((prevObject) => ({
            ...prevObject,
            maximum_stock_level: event?.target?.value,
          }));
        }
        if (event?.target?.name === "criticalityIndicator") {
          setCriticalityIndicator(event?.target?.value)
          setPartInformationData((prevObject) => ({
            ...prevObject,
            critical_part: event?.target?.value,
          }));
        }
        if (event?.target?.name === "sloc") {
          setSloc(event?.target?.value)
          setPartInformationData((prevObject) => ({
            ...prevObject,
            storage_location: event?.target?.value,
          }));
        }
        if (event?.target?.name === "bin") {
          setBin(event?.target?.value)
          setPartInformationData((prevObject) => ({
            ...prevObject,
            storage_bin: event?.target?.value,
          }));
        }
      }
      
      // const handleJustification = (event) => {
      //   if (event?.target?.name === "abcIndicator") {
      //     setAbcIndicator((prevObject) => ({
      //       ...prevObject,
      //       abcIndicatorJustify: event?.target?.value,
      //     }));
      //     setPartInformationData((prevObject) => ({
      //       ...prevObject,
      //       justification_for_change_in_abc_indicator: event?.target?.value,
      //     }));
      //   }
      //   if (event?.target?.name === "minimumValue") {
      //     setMinimumValue((prevObject) => ({
      //       ...prevObject,
      //       minimumValueJustify: event?.target?.value,
      //     }));
      //     setPartInformationData((prevObject) => ({
      //       ...prevObject,
      //       justification_for_change_in_minimum_stock: event?.target?.value,
      //     }));
      //   }
      //   if (event?.target?.name === "maximumValue") {
      //     setMaximumValue((prevObject) => ({
      //       ...prevObject,
      //       maximumValueJustify: event?.target?.value,
      //     }));
      //     setPartInformationData((prevObject) => ({
      //       ...prevObject,
      //       justification_for_change_in_maximum_stock: event?.target?.value,
      //     }));
      //   }
      // }

      const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
        setEquipment_number_for_bom(newInputValues);
      };
    
      const handleAddInput = () => {
        setInputCount(inputCount + 1);
        setInputValues([...inputValues, '']);   
      };

    return (
        <>
            <Paper sx={{
                width: "100%",
                // height: "100px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignItems: "stretch",
                margin: "20px 0px",
                borderRadius: "18px",
                padding: "20px 0px"
            }} elevation={2}>
              <div className="mainHeading">
                <Typography style={{fontWeight: 600,}}>Future Value</Typography>
                {/* <Typography>Justification for Requested Changes</Typography> */}
              </div>
              <div className="value_Display">
                <Typography className="subHeading">ABC Indicator</Typography>
                <input
                  // //placeholder="future Value"
                  name="abcIndicator"
                  type="text"
                  className="narrow_input"
                  value={abcIndicator?.abcIndicatorFutureValue}
                  onChange={handleFutureValue}
                />
                {/* <input
                  // //placeholder="Justify Value"
                  name="abcIndicator"
                  // disabled= {abcIndicator?.abcIndicatorFutureValue.length > 0 ? false : true}
                  type="text"
                  // style= {{
                  //   background : abcIndicator?.abcIndicatorFutureValue.length > 0 ? "#FFF" : "#EFEFEF" 
                  // }}
                  className="narrow_input"
                  value={abcIndicator?.abcIndicatorJustify}
                  onChange={handleJustification}
                /> */}
              </div>
              <Divider />
              <div className="value_Display">
                <Typography className="subHeading">Criticality Indicator</Typography>
                <input
                  //placeholder="future Value"
                  name="criticalityIndicator"
                  type="text"
                  className="narrow_input"
                  value={criticalityIndicator}
                  onChange={handleFutureValue}
                />
              </div>
              <Divider />
              <div className="value_Display">
                <Typography className="subHeading">Minimum Stock</Typography>
                <input
                  //placeholder="future Value"
                  name="minimumValue"
                  type="text"
                  className="narrow_input"
                  value={minimumValue?.minimumFutureValue}
                  onChange={handleFutureValue}
                />
                {/* <input
                  //placeholder="Justify Value"
                  name="minimumValue"
                  // disabled= {abcIndicator?.abcIndicatorFutureValue.length > 0 ? false : true}
                  type="text"
                  // style= {{
                  //   background : abcIndicator?.abcIndicatorFutureValue.length > 0 ? "#FFF" : "#EFEFEF" 
                  // }}
                  className="narrow_input"
                  value={minimumValue?.minimumValueJustify}
                  onChange={handleJustification}
                /> */}
              </div>
              <Divider />
              <div className="value_Display">
                <Typography className="subHeading">Maximum Stock</Typography>
                <input
                  //placeholder="future Value"
                  name="maximumValue"
                  type="text"
                  className="narrow_input"
                  value={maximumValue?.maximumFutureValue}
                  onChange={handleFutureValue}
                />
                {/* <input
                  //placeholder="Justify Value"
                  name="maximumValue"
                  // disabled= {abcIndicator?.abcIndicatorFutureValue.length > 0 ? false : true}
                  type="text"
                  // style= {{
                  //   background : abcIndicator?.abcIndicatorFutureValue.length > 0 ? "#FFF" : "#EFEFEF" 
                  // }}
                  className="narrow_input"
                  value={maximumValue?.maximumValueJustify}
                  onChange={handleJustification}
                /> */}
              </div>
              <Divider />
              <div className="value_Display">
                <Typography className="subHeading">Storage Location</Typography>
                <input
                  //placeholder="future Value"
                  name="sloc"
                  type="text"
                  className="narrow_input"
                  value={sloc}
                  onChange={handleFutureValue}
                />
              </div>
              <Divider />
              <div className="value_Display">
                <Typography className="subHeading">Bin</Typography>
                <input
                  //placeholder="future Value"
                  name="bin"
                  type="text"
                  className="narrow_input"
                  value={bin}
                  onChange={handleFutureValue}
                />
              </div>
              <Divider />
              <div style ={{margin: "0px 100px"}}>
              <Typography sx={{
                width: "100%",
                fontFamily: "Verdana",
                fontSize: "14px",
                fontWeight: "700",
                margin: "20px 0px"
              }}>Equipment Number(s) For BOM</Typography>
              <div>
                <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
                  {Array.from({ length: inputCount }).map((_, index) => (
                    <input
                      key={index}
                      className="partInputField"
                      type="text"
                      value={inputValues[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      //placeholder={`Input ${index + 1}`}
                    />
                  ))}
                </div>
                <IconButton onClick={handleAddInput} sx={{ height: "40px" }}><ButtonPlus /></IconButton>
              </div>
              </div>
            </Paper>
        </>
    )
}

export default PartInformation;