import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Typography,
  Divider,
  Box,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";
import { ReactComponent as Setting } from "../../assets/images/Setting.svg";
import { ReactComponent as Date } from "../../assets/images/Date.svg";
import { ReactComponent as User } from "../../assets/images/User.svg";
import { ReactComponent as PartCreation } from "../../assets/images/PartCreation.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import "../../styles/ownerPartRequest.css";
import { ReactComponent as Info } from "../../assets/images/Info.svg";
import { ReactComponent as MatrixCriteria } from "../../assets/images/MatrixCriteria.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { automationHttpRequest } from "../../api/httpRequests";

const AtcPartCreationDialog = ({
  partRequestNumber,
  open,
  dialogData,
  closeDialog,
  dialogLoading,
}) => {
  const [approveResult, setApproveResult] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    color: "#212F37",
    margin: "0px 10px",
  };

  const headingStyle = {
    fontWeight: "700",
  };

  const handleApprovePartRequest = async () => {
    // const values = {
    //     "material_number": partRequestNumber.toString(),
    // }
    try {
      setLoading(true);
      const response = await automationHttpRequest.get(
        `/compare_data_creation/?material_number=${dialogData?.material_number}/`,
        {},
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
        }
      );
      if (response?.data) {
        // toast.success(response?.data?.data?.message , {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        setApproveResult(response?.data?.result);
        setLoading(false);
      }
    } catch (error) {}
  };

  const handleClose = () => {
    closeDialog();
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleApprovePartRequest();
          }}
        >
          {loading && (
            <div
              className="loader"
              style={{ borderTop: "2px dotted white" }}
            ></div>
          )}
          {!loading && btnText}
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Cancel
        </button>
      </>
    );
  };

  const ValueDisplay = ({
    future,
    justification,
    justifyValue,
    futureValue,
  }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {future && (
            <Typography className="smallNarrowValue" style={headingStyle}>
              {futureValue}
            </Typography>
          )}
          {justification && (
            <Typography
              className="narrowValue"
              style={{ ...headingStyle, width: "300px" }}
            >
              {justifyValue.length > 18 ? (
                <Tooltip title={justifyValue} arrow>
                  <span>{justifyValue.slice(0, 18) + "..."}</span>
                </Tooltip>
              ) : (
                justifyValue
              )}
            </Typography>
          )}
        </div>
      </>
    );
  };

  const showInformation = () => {
    return (
      <>
        <div
          style={{
            height: "540px",
            width: "500px",
            position: "relative",
            top: "-20px",
            left: "-56px",
          }}
        >
          <MatrixCriteria />
        </div>
      </>
    );
  };

  const MaterialCardData = () => {
    const cardData = { ...dialogData?.attribute_data };

    const cardDataValue = Object.entries(cardData);
    const middleIndex = Math.ceil(cardDataValue.length / 2);
    const materialNumberDetailsFirstRow = cardDataValue.slice(0, middleIndex);
    const materialNumberDetailsSecondRow = cardDataValue.slice(middleIndex);

    return (
      <>
        <div
          style={{
            display: "flex",
            margin: "10px",
            borderRadius: "4px",
          }}
        >
          <div className="row">
            {materialNumberDetailsFirstRow.map(([element, value], index) => (
              <>
                <div className="rowelement">
                  <div key={index} className="elementHeading">
                    {element} :-
                  </div>
                  <div key={index} className="element">
                    {value ? value : "--"}
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row">
            {materialNumberDetailsSecondRow.map(([element, value], index) => (
              <>
                <div className="rowelement">
                  <div key={index} className="elementHeading">
                    {element} :-
                  </div>
                  <div key={index} className="element">
                    {value ? value : "--"}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            maxWidth: "1400px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="dialogHeading">
            <PartCreation style={{ height: "60px" }} onClick={handleClose} />
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              Part Creation
            </Typography>
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "14px",
                fontWeight: "600",
                background: "#EFEFEF",
                padding: "6px",
                borderRadius: "4px",
              }}
            >
              Requested ID-{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "300",
                  marginLeft: "10px",
                }}
              >
                #{partRequestNumber}
              </span>
            </Typography>
          </div>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ width: "1260px", margin: "10px" }}>
          {dialogLoading ? (
            <>
              <Box sx={{ display: "flex" }}>
                <CircularProgress style={{ color: "#C6003D" }} />
              </Box>
            </>
          ) : (
            <DialogContentText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px",
                }}
              >
                <div className="headers">
                  <Plant />
                  <div style={inputStyle}>
                    <Typography>Creation Required For Plant</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.plant_name || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <User />
                  <div style={inputStyle}>
                    <Typography>Submitted By</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.submitted_by || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <Date />
                  <div style={inputStyle}>
                    <Typography>Date of Submission</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.date_of_submission || "-"}
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider style={{ margin: "20px 0px", background: "#EFEFEF" }} />
              {/* <div className="formikExtension" style={{ width: "60%", justifyContent: "space-around" }}>
                                <div style={inputStyle}>
                                    <Typography style={headingStyle}>Manufacturer</Typography>
                                    <Typography style={{ margin: "4px 0px" }}>{dialogData?.vendor_account}</Typography>
                                </div>
                                <div style={inputStyle}>
                                    <Typography style={headingStyle}>Manufacturer Part Number</Typography>
                                    <Typography style={{ margin: "4px 0px" }}>{dialogData?.supplier_part_number}</Typography>
                                </div>
                                <div style={inputStyle}>
                                    <Typography style={headingStyle}>Supplier Part Number</Typography>
                                    <Typography style={{ margin: "4px 0px" }}>{dialogData?.lead_time_from_pir}</Typography>
                                </div>
                            </div>

                            <Paper sx={{
                                width: "100%",
                                // height: "200px",
                                // display: "flex",
                                // alignItems: "center",
                                margin: "20px 0px",
                                paddingBottom: "40px",
                                borderRadius: "18px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <Typography sx={{
                                        fontFamily: "Verdana",
                                        fontSize: "24px",
                                        fontWeight: 400,
                                        padding: "30px"
                                    }}>
                                        Decision Matrix
                                    </Typography>
                                    <Tooltip disableFocusListener disableTouchListener placement="top-end" title={showInformation()}>
                                        <IconButton><Info /></IconButton>
                                    </Tooltip>
                                </div>
                                <Divider />
                                <div className='checkDecisionPart' style={{
                                    padding: "20px 0px"
                                }}>
                                    <Paper elevation={2} sx={{
                                        width: "120px",
                                        height: "100px",
                                        borderBottom: "2px solid red"
                                    }}>
                                        <Typography style={headingStyle} sx={{textAlign:"center", padding: "10px"}}>Impact</Typography>
                                        <Divider />
                                        <Typography style={headingStyle} sx={{textAlign:"center", padding: "10px"}}>3</Typography>
                                    </Paper>
                                    <Paper elevation={2} sx={{
                                        width: "120px",
                                        height: "100px",
                                        borderBottom: "2px solid red"
                                    }}>
                                        <Typography style={headingStyle} sx={{textAlign:"center", padding: "10px"}}>Probability</Typography>
                                        <Divider />
                                        <Typography style={headingStyle} sx={{textAlign:"center", padding: "10px"}}>4</Typography>
                                    </Paper>
                                    <div>
                                        <Typography style={headingStyle}>Stock Decision</Typography>
                                        <Typography className="checkbutton" style={{ top: "0px" }}>
                                            stockValue
                                        </Typography>
                                    </div>
                                </div>
                            </Paper> */}
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "10px",
                  }}
                >
                  <Typography
                    style={headingStyle}
                    sx={{ color: "#212F37", fontSize: "18px" }}
                  >
                    Noun
                  </Typography>
                  <Typography
                    style={{
                      margin: "0px 20px",
                      background: "#EFEFEF",
                      padding: "4px",
                      borderRdius: "4px",
                    }}
                  >
                    {dialogData?.noun_indentifier}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "10px",
                  }}
                >
                  <Typography
                    style={headingStyle}
                    sx={{ color: "#212F37", fontSize: "18px" }}
                  >
                    Modifier
                  </Typography>
                  <Typography
                    style={{
                      margin: "0px 20px",
                      background: "#EFEFEF",
                      padding: "4px",
                      borderRdius: "4px",
                    }}
                  >
                    {dialogData?.modifier}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                  }}
                >
                  <Typography
                    style={headingStyle}
                    sx={{ color: "#212F37", fontSize: "18px" }}
                  >
                    Clean Short Description
                  </Typography>
                  <Typography style={{ margin: "10px 0px" }}>
                    {dialogData?.clean_short_description}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                  }}
                >
                  <Typography
                    style={headingStyle}
                    sx={{ color: "#212F37", fontSize: "18px" }}
                  >
                    Clean Long Description
                  </Typography>
                  <Typography style={{ margin: "10px 0px" }}>
                    {MaterialCardData()}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                  }}
                >
                  <Typography
                    style={headingStyle}
                    sx={{ color: "#212F37", fontSize: "18px" }}
                  >
                    Substitution Part
                  </Typography>
                  <Typography style={{ margin: "10px 0px" }}>
                    {dialogData?.substitution_status}
                  </Typography>
                </div>
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "20px 0px" }} />
              <div
                className="formikExtension"
                style={{ width: "100%", justifyContent: "space-around" }}
              >
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Vendor Number</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.vendor_account || "-"}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>
                    Supplier Part Number
                  </Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.supplier_part_number || "-"}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Lead Time</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.lead_time_from_pir || "-"}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Order Unit</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.order_unit_from_pir || "-"}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Predicted Usage</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.predticed_usage || "-"}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Net Price</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.standard_price || "-"}
                  </Typography>
                </div>
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "20px 0px" }} />
              <div className="creationHeading">
                <Typography style={{ color: "black",  fontWeight: 600, }}>Future Value</Typography>
                <Typography style={{ color: "black",  fontWeight: 600, }}>
                  Justification for Requested Changes
                </Typography>
              </div>
              <div className="valueDisplay">
                <Typography className="subHeading">ABC Indicator</Typography>
                <ValueDisplay
                  future={true}
                  justification={true}
                  justifyValue={
                    dialogData?.justification_for_change_in_abc_indicator
                      ? dialogData?.justification_for_change_in_abc_indicator
                      : "-"
                  }
                  futureValue={
                    dialogData?.ABC_indicator ? dialogData?.ABC_indicator : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "4px 0px" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">
                  Criticality Indicator
                </Typography>
                <ValueDisplay
                  future={true}
                  justification={false}
                  futureValue={
                    dialogData?.critical_part ? dialogData?.critical_part : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "4px 0px" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Minimum Stock</Typography>
                <ValueDisplay
                  future={true}
                  justification={true}
                  justifyValue={
                    dialogData?.justification_for_change_in_minimum_stock
                      ? dialogData?.justification_for_change_in_minimum_stock
                      : "-"
                  }
                  futureValue={
                    dialogData?.minimum_stock_level
                      ? dialogData?.minimum_stock_level
                      : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "4px 0px" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Maximum Stock</Typography>
                <ValueDisplay
                  future={true}
                  justification={true}
                  justifyValue={
                    dialogData?.justification_for_change_in_maximum_stock
                      ? dialogData?.justification_for_change_in_maximum_stock
                      : "-"
                  }
                  futureValue={
                    dialogData?.maximum_stock_level
                      ? dialogData?.maximum_stock_level
                      : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "4px 0px" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Storage Location</Typography>
                <ValueDisplay
                  future={true}
                  justification={false}
                  futureValue={
                    dialogData?.storage_location
                      ? dialogData?.storage_location
                      : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "4px 0px" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Bin</Typography>
                <ValueDisplay
                  future={true}
                  justification={false}
                  futureValue={
                    dialogData?.storage_bin ? dialogData?.storage_bin : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF", margin: "4px 0px" }} />
              <div>
                <Typography className="equipmentNumber">
                  Equipment Number(s) For BOM
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                  >
                    <Typography>
                      {dialogData?.equipment_number_for_bom}
                    </Typography>
                  </div>
                </div>
              </div>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-start !important",
          }}
        >
          <ButtonFamily btnText="Quality Score the Entry" />
        </DialogActions>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {approveResult === "Pass" && (
            <>
              <button
                type="submit"
                className="submitButton"
                style={{
                  fontSize: 16,
                  position: "relative",
                  fontFamily: "Verdana",
                }}
              >
                {approveResult}
              </button>
              <Typography
                sx={{
                  padding: "8px",
                  marginLeft: "10px",
                  border: "2px dotted red",
                  borderRadius: "20px",
                  background: "#EFEFEF",
                }}
              >
                Details matched with internal records and requestor notified
              </Typography>
            </>
          )}
          {approveResult === "Fail" && (
            <>
              <button
                type="submit"
                className="submitButton"
                style={{
                  fontSize: 16,
                  position: "relative",
                  fontFamily: "Verdana",
                }}
              >
                {approveResult}
              </button>
              <Typography
                sx={{
                  padding: "8px",
                  marginLeft: "10px",
                  border: "2px dotted red",
                  borderRadius: "20px",
                  background: "#EFEFEF",
                }}
              >
                Details mismatched with the internal record please revalidate
              </Typography>
            </>
          )}
        </div>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default AtcPartCreationDialog;
