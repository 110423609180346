import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Tooltip, IconButton, Input, Divider } from "@mui/material";
import MaterialGroupDialog from "./MaterialGroupDialog";
import "../../styles/tableComponent.css";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import FeedbackDialog from "./FeedbackDialog";
import {httpRequest} from "../../api/httpRequests";
import "../../styles/dashboard.css";
import { ReactComponent as SortDown } from "../../assets/images/SortDown.svg";
import { ReactComponent as SortUp } from "../../assets/images/SortUp.svg";
import { ReactComponent as ResultDown } from "../../assets/images/ResultDown.svg";
import { ReactComponent as ResultUp } from "../../assets/images/ResultUp.svg";
import { red } from "@mui/material/colors";
import { ReactComponent as AlertCircle } from "../../assets/images/AlertCircle.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchSvgIcon.svg";
import Popover from '@mui/material/Popover';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchFilter from "./SearchFilter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAF0F0",
    color: "rgb(198, 0, 61)",
    fontSize: "15px",
    fontWeight: "600",
    fontFamily: "Verdana",
    border: "1px solid #BDBDBD", // Add border style
    borderBottom: "2px solid #BDBDBD", // Add bottom border style
    padding: "32px 20px",
    position: "sticky",
    top: 0,
    zIndex: 2,
    minWidth: "150px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: red,
    border: "1px solid #BDBDBD", // Add border style
    borderBottom: "2px solid #BDBDBD", // Add bottom border style
  },
}));

const StyledTable = styled(Table)({
  border: "1px solid #BDBDBD",
});

export default function TableComponent(props) {
  const {
    table_data,
    maxRows,
    setOrder,
    setOrderBy,
    handleUserFeedback,
    enableFeedBack,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    order,
    filterValues,
    sqlDescription,
    handleFiltersearch
  } = props;
  const rows = table_data;
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false);
  const [thumbsUpHovered, setThumbsUpHovered] = useState(false);
  const [materialNumber, setMaterialNumber] = React.useState("");
  const [materialNumberDialogOpen, setMaterialNumberDialogOpen] =
    React.useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = React.useState(false);
  const [materialNumberDetails, setMaterialNumberDetails] = React.useState([]);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [feedBackDialog, setFeedBackDialog] = React.useState(false);
  const [positiveFeedback, setPositiveFeedback] = React.useState(false);
  const [uid, setUid] = React.useState(0);
  const columnNames = rows.length > 0 ? Object.keys(rows[0]) : [];
  const [searchFilterOPen, setSearchFilterOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [column_Name, setColumnName]= React.useState("");
  const disallowedColumns = ["Unique Id", "Active Flag", "Df Client", "Df Plant", "Df Storage Location", "Unique Id Modal Search", "Plant Id", "Vendor Name", "Vendor Account"];

  const filteredColumns = columnNames.filter(
    (column) => !disallowedColumns.includes(column.trim())
  );

  const filterColumns = {
    "Manufacturing Part Number" : "manufacturing_part_number",
    "Plant Name":"plant_name",
    "Description": "description",
    "Storage Bin" :"storage_bin",
    "Storage Location": "storage_location",
    "Material Number": "material_number"
  }

  const handleRequestSort = (property, orderValue) => {
    setOrder(orderValue);
    setOrderBy(property);
  };

  const tableColumnNames = (camelCase) => {
    const transformedColumnName =
      camelCase === "Manufacturing Part Number"
        ? "Manufacturer Part Number"
        : camelCase;

    return transformedColumnName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleThumbsUpClick = () => {
    setThumbsUpClicked(true);
    setThumbsDownClicked(false);
    setPositiveFeedback(true);
    setFeedbackDialogOpen(true);
  };

  const handleThumbsDownClick = () => {
    setThumbsDownClicked(true);
    setThumbsUpClicked(false);
    setPositiveFeedback(false);
    setFeedbackDialogOpen(true);
  };

  const handleUserFeedbackData = (feedBackData) => {
    handleUserFeedback(feedBackData);
  };

  const handleDialogOpen = () => {
    setMaterialNumberDialogOpen(false);
  };

  const handleFeedBackDialogOpen = () => {
    setFeedbackDialogOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if(event.target.value === maxRows ){
      setPage(0)
    }
    // console.log('rowsPerPage',event.target.value)
  };

  // const visibleRows = React.useMemo(
  //   () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  //   [page, rowsPerPage, rows]
  // );

  const handleMaterialChange = (value, uid) => {
    if (/<\/?mark>/.test(value)) {
      // Remove <mark> tags if they exist
      setMaterialNumber(value.replace(/<\/?mark>/g, ''))
    }else{
      setMaterialNumber(value);
    }
    
    setUid(uid)
    const handleMaterialNumber = async (value) => {
      if (value) {
        setDialogLoading(true);
        try {
          const response = await httpRequest.post(
            "/modal/",
            {
              "material_number": value,
              // Unique_ID: uid,
            },
            {
              headers: {
                session_id: localStorage.getItem("session_id"),
              },
              params: {},
            }
          );
          if (response?.data?.success) {
            setDialogLoading(false);
            setMaterialNumberDetails(response?.data?.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    handleMaterialNumber(value);
    localStorage.setItem('Unique_ID',uid)
    setMaterialNumberDialogOpen(true);
  };

  function highlightSubstring(text) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const handleSearchfilterOpen = (event, col_name) => {
    setSearchFilterOpen(true)
    setAnchorEl(event.currentTarget);
    setColumnName(col_name)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setSearchFilterOpen(false)
  }

  return (
    <Box sx={{ width: "98%", margin: "auto" }}>
      <Box
        sx={{ display: "flex", alignItems: "baseline", padding: "30px 0px" }}
      >
        <Typography
          sx={{
            fontFamily: "Verdana",
            color: "rgb(209, 31, 63)",
            fontSize: "20px",
            fontWeight: "700",
            margin: "0px 0px 10px 20px",
          }}
        >
          Materials Found
        </Typography>
        <>
          {enableFeedBack ? (
            <Typography
              sx={{
                top: "-10px !important",
                fontFamily: "Verdana",
                color: "#8a8e95",
                fontSize: "15px",
                fontWeight: "400",
                margin: "0px",
                marginRight: "10px",
                marginLeft: "auto",
                border: "1px solid #e5dba5",
                padding: "10px 15px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            >
              Did you find this helpful?
              <div
                className={thumbsUpClicked ? "thumbs-up-clicked" : "thumbs-up"}
              >
                {thumbsUpClicked ? (
                  <ThumbUpAltIcon
                    className="thumbs thumbsUp active"
                    onClick={handleThumbsUpClick}
                    style={{ marginBottom: "-4px" }}
                  />
                ) : (
                  <ThumbUpAltOutlinedIcon
                    className="thumbs thumbsUp"
                    onClick={handleThumbsUpClick}
                    style={{ marginBottom: "-4px" }}
                  />
                )}
              </div>
              <div
                className={
                  thumbsDownClicked ? "thumbs-down-clicked" : "thumbs-down"
                }
              >
                {thumbsDownClicked ? (
                  <ThumbDownAltIcon
                    className="thumbs thumbsDown active"
                    onClick={handleThumbsDownClick}
                    style={{ marginBottom: "-4px" }}
                  />
                ) : (
                  <ThumbDownAltOutlinedIcon
                    className="thumbs thumbsDown"
                    onClick={handleThumbsDownClick}
                    style={{ marginBottom: "-4px" }}
                  />
                )}
              </div>
            </Typography>
          ) : (
            ""
          )}
        </>
      </Box>
      {table_data && table_data.length > 0 ? (
        <>
          <Paper sx={{ width: "100%", mb: 2, border: "1px solid #BDBDBD" }} elevation={2}>
            <TableContainer
              sx={{
                maxHeight: "600px",
                overflowY: "auto",
                borderBottom: "1px solid #BDBDBD",
              }}
            >
              <StyledTable
                sx={{ minWidth: 750, border: "1px solid #BDBDBD" }}
                aria-labelledby="tableTitle"
              >
                <TableHead>
                  <TableRow>
                    {filteredColumns.map((columnName, index) => (
                      <StyledTableCell
                        key={columnName}
                        align="left"
                        padding="normal"
                        sx={{
                          backgroundColor: "#FAF0F0 !important",
                          position: "sticky",
                          top: 0,
                          zIndex: 3, // Adjust the zIndex to make sure it appears above the scrolling rows
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                          <div>
                            {columnName === "Description"
                              ? "Material Description"
                              : tableColumnNames(columnName)}
                          </div>
                            {/* {(columnName === "Plant Name" || columnName === "Manufacturing Part Number" || columnName === "Description") && ( */}
                          {(columnName === "Plant Name" || columnName === "Material Number" || columnName === "Description" || columnName === "Manufacturing Part Number" ||
                            columnName === "Storage Bin" || columnName === "Storage Location") && (
                              <div>
                                <IconButton
                                  style={{
                                    position: "relative",
                                    left: "10px",
                                    top: "0px",
                                    zIndex: 2,
                                    fontSize: "0px",
                                  }}
                                  onClick={(e) => handleSearchfilterOpen(e, columnName)}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </div>
                            )}
                          {columnName !== "Remarks" && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", "marginLeft": "14px" }}>
                              {orderBy === columnName && order === "asc" ? (
                                <ResultUp style={{ cursor: "pointer", marginBottom: "4px" }} onClick={() => handleRequestSort(columnName, "asc")} />
                              ) : (
                                <SortUp style={{ cursor: "pointer", marginBottom: "4px" }} onClick={() => handleRequestSort(columnName, "asc")} />
                              )}
                              {orderBy === columnName && order === "desc" ? (
                                <ResultDown style={{ cursor: "pointer" }} onClick={() => handleRequestSort(columnName, "asc")} />
                              ) : (
                                <SortDown style={{ cursor: "pointer" }} onClick={() => handleRequestSort(columnName, "desc")} />
                              )}
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                    ))}
                  </TableRow>

                </TableHead>
                <TableBody>
                  {table_data.map((row, index) => {
                    if (row["Active Flag"] === "False") {
                      return null;
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row[filteredColumns[0]]}
                      >
                        {filteredColumns.map((columnName) => (
                          <TableCell
                            key={columnName}
                            align="left"
                            sx={{
                              border: "1px solid #BDBDBD",
                              fontFamily: "Verdana",
                              textAlign: "center",
                            }}
                            className={
                              columnName === "Material Number"
                                ? "fixed-column"
                                : null
                            }
                          >
                            {columnName === "Material Number" ? (
                              <button
                                style={{
                                  border: "0px",
                                  background: "transparent",
                                  color: "#C6003D",
                                  cursor: "pointer",
                                  fontWeight: 550,
                                  fontFamily: "Verdana",
                                }}
                                onClick={() =>
                                  handleMaterialChange(
                                    row[columnName],
                                    row["Unique Id"]
                                  )
                                }
                              >
                                {highlightSubstring(row[columnName])}
                              </button>
                            ) : (
                              highlightSubstring(row[columnName])
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </StyledTable>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <SearchFilter filter_values = {filterValues?.[filterColumns?.[column_Name]]} handleFiltersearch={handleFiltersearch} column_Name ={column_Name} filterColumns={filterColumns} searchFilterOPen= {searchFilterOPen} handleSearchClose = {handleClose} />
              </Popover>
            </TableContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Verdana",
                  marginLeft: "22px",
                }}
              >
                {" "}
                Total: <span style={{ fontWeight: "600" }}>{maxRows}</span>{" "}
                records
              </Typography>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  20,
                  { value: maxRows, label: "All" },
                ]}
                component="div"
                count={maxRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Paper>
        </>
      ) : (
        <>
          <Typography
            sx={{
              backgroundColor: "#FAF0F0",
              fontFamily: "Verdana",
              border: "1px solid #BDBDBD",
              padding: "32px 20px",
              textAlign: "center",
              color: "#2D3748",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {" "}
            No Data{" "}
          </Typography>
        </>
      )}
      {materialNumberDialogOpen && (
        <MaterialGroupDialog
          materialNumber={materialNumber}
          materialNumberDetails={materialNumberDetails}
          open={materialNumberDialogOpen}
          handleDialogOpen={handleDialogOpen}
          dialogLoading={dialogLoading}
          uid={uid}
        />
      )}
      <FeedbackDialog
        handleUserFeedbackData={handleUserFeedbackData}
        positiveFeedback={positiveFeedback}
        open={feedbackDialogOpen}
        handleFeedBackDialogOpen={handleFeedBackDialogOpen}
      />
    </Box>
  );
}
TableComponent.propTypes = {
  table_data: PropTypes.array.isRequired,
};
