import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import { Divider, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import "../../styles/materialDialog.css";
import PartExtension from "./PartExtension";
import PartModification from "./PartModification";
import AttributeChange from "../PartModification/AttributeChange";

const MaterialGroupDialog = ({
  materialNumber,
  materialNumberDetails,
  open,
  handleDialogOpen,
  dialogLoading,
  uid,
}) => {
  const [materialCard, setMaterialCard] = React.useState([]);
  const [dots, setDots] = React.useState("");
  // const [dots, setDots] = React.useState("");
  const [selectAction, setSelectAction] = React.useState("");
  const [primaryVendorData, setPrimaryVendorData] = React.useState([]);
  const [basicData, setBasicData] = React.useState([]);

  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     setDots((prevDots) => (prevDots.length < 4 ? prevDots + "." : ""));
  //   }, 300);

  //   return () => clearInterval(interval);
  // }, []);

  const handleChange = (event) => {
    setSelectAction(event);
  };

  const handleBack = () => {
    setSelectAction("");
  };

  React.useEffect(() => {
    const vendorData = [
      "Vendor Account ID",
      "Vendor Name",
      "Supplier Part Number",
      "Lead Time From Pir",
      "Order Unit From Pir",
      "Standard Price"
    ]

    const basic_data = [
      "Industry Sector",
      "Material Group",
      "Material Group Description",
      "Material Type",
      "Manufacturing Part Number"
    ]

    const val = [
      "Material Number",
      "Description"
    ]

    if (materialNumberDetails && !dialogLoading) {
      const maindataArray = [];
      const primarydataArray = [];
      const dataArray = [];
      for (let i = 0; i < materialNumberDetails.length; i++) {
        const keyData = Object.keys(materialNumberDetails[i]);
        const materialData = keyData?.filter(value => {
          const bool = !vendorData.includes(value) && !basic_data.includes(value) && !val.includes(value)
          return bool && value;
        })
        const filterObject = (obj, predicate) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => predicate(key, value))
          );
        };
        const filteredObject = filterObject(materialNumberDetails[i], (key, value) => materialData?.includes(key));
        const maindata = [filteredObject]
        maindataArray.push(maindata)
        const basicInfoData = keyData?.filter(value => {
          const bool = basic_data.includes(value)
          return bool && value;
        })
        const basicFilterObject = (obj, predicate) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => predicate(key, value))
          );
        };
        const basicFilteredObject = basicFilterObject(materialNumberDetails[i], (key, value) => basicInfoData?.includes(key));
        const data = [basicFilteredObject]
        dataArray.push(data)
        const primaryInfoData = keyData?.filter(value => {
          const bool = vendorData.includes(value)
          return bool && value;
        })
        const primaryFilterObject = (obj, predicate) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => predicate(key, value))
          );
        };
        const primaryFilteredObject = primaryFilterObject(materialNumberDetails[i], (key, value) => primaryInfoData?.includes(key));
        const primarydata = [primaryFilteredObject]
        primarydataArray.push(primarydata)
      }
      setBasicData(dataArray);
      setMaterialCard(maindataArray);
      setPrimaryVendorData(primarydataArray)
    }
  }, [materialNumberDetails, dialogLoading]);

  const handleClose = () => {
    handleDialogOpen();
  };

  const style = {
    fontFamily: "Verdana",
    color: "rgb(209, 31, 63)",
    fontSize: "20px",
    fontWeight: "700",
    margin: "0px 0px 10px 20px",
  }

  const cardstyle = {
    fontFamily: "Verdana",
    color: "#FFFFFF",
    background: "rgb(209, 31, 63)",
    fontSize: "18px",
    fontWeight: "600",
    width: "140px",
    borderRadius: "8px",
    padding: "4px",
    textAlign: "center",
    margin: "0px 0px 10px 20px",
  }

  const MaterialCardData = ({ cardData }) => {
    const cardDataValue = Object.entries(cardData);

    const middleIndex = Math?.ceil(cardDataValue.length / 2);
    const materialNumberDetailsFirstRow = cardDataValue?.slice(1, middleIndex);
    const materialNumberDetailsSecondRow = cardDataValue?.slice(middleIndex);

    return (
      <>
        <div
          style={{
            display: "flex",
            margin: "10px",
            borderRadius: "4px",
            // boxShadow: "2px 2px 2px 2px #e8a6a6",
          }}
        >
          <div className="row">
            {materialNumberDetailsFirstRow?.map(([element, value], index) => (
              <>
                {(element !== "Material Number" && element !== "Description") && (
                  <>
                    <div className="elementRow">
                      <div key={index} className="elementHeading">
                        {element === "Manufacturing Part Number"
                          ? "Manufacturer Part Number"
                          : element === "Order Unit From Pir"
                            ? "Order Unit"
                            : element}
                      </div>
                      <div key={index} className="element">
                        {value ? value : "--"}
                      </div>
                      <Divider />
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          <div className="row">
            {materialNumberDetailsSecondRow?.map(([element, value], index) => (
              <>
                {(element !== "Material Number" && element !== "Description") && (
                  <>
                    <div className="elementRow">
                      <div key={index} className="elementHeading">
                        {element === "Manufacturing Part Number"
                          ? "Manufacturer Part Number"
                          : element === "Order Unit From Pir"
                            ? "Order Unit"
                            : element}
                      </div>
                      <div key={index} className="element">
                        {value ? value : "--"}
                      </div>
                      <Divider />
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
      </>
    );
  };

  const PrimaryVendor = ({ cardData, basic }) => {
    const cardDataValue = cardData && Object.entries(cardData);
    return (
      <>
        <div
          style={{
            display: "flex",
            margin: basic? "0px" : "10px",
            borderRadius: "4px",
            // boxShadow: "2px 2px 2px 2px #e8a6a6",
          }}
        >
          <div style={{ width: "100%" }}>
            {cardDataValue?.map(([element, value], index) => (
              <>
                <div className="elementRow">
                  <div key={index} className="elementHeading">
                    {element === "Lead Time From Pir"
                      ? "Lead Time"
                      : element === "Order Unit From Pir"
                        ? "Order Unit"
                        : element === "Manufacturing Part Number"
                          ? "Manufacturer Part Number"
                          : element === "Standard Price"
                            ? "Price"
                            : element}
                  </div>
                  <div key={index} className="element">
                    {value ? value : "--"}
                  </div>
                  <Divider />
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  };

  function highlightSubstring(text) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleChange("attributeAddition");
          }}
        >
          {btnText}
        </button>

        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleChange("modification");
          }}
        >
          Plant Specific Modification
        </button>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleChange("extension");
          }}
        >
          Extend Part
        </button>
      </>
    );
  };

  const handleBackClick = () => {
    setSelectAction("");
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            maxWidth: "1400px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Verdana",
              fontSize: "16px",
              display: "flex",
              fontWeight: "700",
              lineHeight: "21px",
              marginLeft: "10px",
            }}
          >
            Material Number:{" "}
            <span
              style={{
                fontSize: "18px",
                fontWeight: "700",
                marginLeft: "10px",
              }}
            >
              {highlightSubstring(materialNumber)}
            </span>
          </Typography>
          {materialNumberDetails && materialNumberDetails.length > 0 && materialNumberDetails[0].Description ? (
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "21px",
              }}
            >
              Material Description:{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginLeft: "10px",
                }}
              >
                {materialNumberDetails[0].Description}
              </span>
            </Typography>) : ""}


          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </DialogTitle>
        <Divider sx={{ width: "96%", position: "relative", left: "20px" }} />
        <DialogContent sx={{ width: dialogLoading ? "1000px" : "1260px", height: "600px", }}>
          {dialogLoading ? (
            <>
              <div>Loading..</div>
            </>
          ) : (
            <Paper
              style={{
                width: "1200px",
                // height: "600px",
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
              }}
            >
              {selectAction === "" && (
                <>
                  {materialNumberDetails && basicData?.length && materialCard?.length && primaryVendorData?.length && materialNumberDetails?.map((value, index) => {
                    return (
                      <>
                        <Paper elevation={2} sx={{
                          margin: "20px 0px"
                        }}>
                          <div className="dialogContent">

                            <>
                              <Typography sx={cardstyle}>Record {index + 1}</Typography>
                              <Typography sx={style}>Plant Specific Data</Typography>
                              <MaterialCardData cardData={materialCard && materialCard?.[index]?.[0]} />
                              <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" }}>
                                  <Typography sx={style}>Primary Vendor Data</Typography>
                                  <PrimaryVendor cardData={primaryVendorData && primaryVendorData?.[index]?.[0]}  basic={false}  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <Typography sx={style} style={{position:"relative", left:"-16px"}}>Basic Data</Typography>
                                  <PrimaryVendor cardData={basicData && basicData?.[index]?.[0]} basic={true} />
                                </div>
                              </div>
                            </>
                          </div>
                        </Paper>
                      </>
                    )
                  })}
                </>
              )}

              {selectAction === "modification" && (
                <PartModification
                  materialNumber={materialNumber}
                  handleDialogClose={handleClose}
                  uid={uid}
                  handleBack={handleBack}
                  handleBackWardClick={handleBackClick}
                />
              )}
              {selectAction === "extension" && (
                <PartExtension
                  materialNumber={materialNumber}
                  handleDialogClose={handleClose}
                  uid={uid}
                  handleBackClick={handleBackClick}
                  handleBack={handleBack}
                />
              )}
              {selectAction === "attributeAddition" && (
                <AttributeChange
                  selectAction={selectAction}
                  handleBackClick={handleBackClick}
                  uniqueId={uid}
                  materialNumber={materialNumber}
                  handleClose={handleClose}
                />
              )}
            </Paper>
          )}
        </DialogContent>
        {((localStorage.getItem("MRO_user_type") === "edp-mro-llm-app-plant-owner" || localStorage.getItem("MRO_user_type") === "edp-mro-llm-app-maintenance-manager" || localStorage.getItem("MRO_user_type") === "edp-mro-llm-app-admin") && selectAction === "" && !dialogLoading && process.env.REACT_APP_ENV_TYPE === "DEV") && (
          <DialogActions>
            <ButtonFamily btnText="Attribute Addition" />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default MaterialGroupDialog;
