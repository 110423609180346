import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";

import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./azure/config/authConfig";
import { useNavigate } from "react-router-dom";
import "./App.css";

import Filter from "./components/dashboard/Filters";
import SearchResult from "./components/dashboard/SearchResult";
import Login from "../src/components/login/login";
import Search from "./components/dashboard/Search";
import Header from "./components/dashboard/Header";
import Dashboard from "./components/dashboard/Dashboard";
import VersionHistory from "./components/dashboard/VersionHistory";
import AtcPartRequestHistory from './components/atcFlow/AtcPartRequestHistory';
import PartRequestHistory from './components/plantOwnerFlow/PartRequestHistory';
import PartCreation from './components/PartCreation/PartCreation'
import OwnerPartRequestHistory from './components/ownerflow/OwnerPartRequetHistory';

const WrappedView = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate(); // Get the navigation function

  const getLoginInfo = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        account: instance.getActiveAccount(),
        scopes: ["User.Read"],
      });

      const userEmail = response.account.idTokenClaims.email;

      const userLoginInfo = {
        token_type: "Bearer",
        scope: (response.scopes || []).join(" "),
        expires_in: Math.floor(
          (new Date(response.expiresOn) - new Date()) / 1000
        ),
        ext_expires_in: Math.floor(
          (new Date(response.extExpiresOn) - new Date()) / 1000
        ),
        access_token: response.accessToken || "",
        refresh_token: response.refreshToken || "",
        refresh_token_expires_in: response.refreshTokenExpiresOn || "",
        id_token: response.idToken || "",
        client_info: response.clientInfo || "",
        userEmail: response.account.idTokenClaims.email || "",
      };

      // Use the navigation function to redirect to /dashboard
      localStorage.setItem("userLoginInfo", JSON.stringify(userLoginInfo));
      navigate("/dashboard");
    } catch (error) {
      console.error("Error retrieving user email:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getLoginInfo();
    }
  }, [isAuthenticated]);

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Login  handleRedirect={handleRedirect} />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/version" element={<VersionHistory />} />
          <Route path="/partcreation" element={<PartCreation />} />
          <Route path="/partrequesthistory" element={<OwnerPartRequestHistory />}/>
          <Route path="/atcpartrequesthistory" element={<AtcPartRequestHistory />}/>
          <Route path="/parthistoryrequest" element={<PartRequestHistory />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login handleRedirect={handleRedirect} />
      </UnauthenticatedTemplate>
    </>
  );
};

function App({ instance }) {
  return (
    <Router>
      <MsalProvider instance={instance}>
        <WrappedView />
      </MsalProvider>
    </Router>
  );
}

export default App;
