// SessionExpiredDialog.jsx
import React from "react";
import { Dialog, DialogContent, Button, Typography } from "@mui/material";
import { dialogStyles } from "./styles"; // Adjust the import path accordingly

const SessionExpiredDialog = ({
  open,
  onClose,
  errorMessage,
  handleReLogin,
}) => {
  const handleReloginClick = () => {
    // window.location.reload()
    onClose()
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          ...dialogStyles.backgroundStyle,
          maxHeight: "90vh",
          minHeight: "30vh",
          maxWidth: "105vh",
          minWidth: "45vh",
        },
      }}
    >
      <DialogContent style={dialogStyles.backgroundStyle}>
        <Typography
          sx={{
            color: "#2D3748",
            fontFamily: "Verdana",
            fontWeight: 500,
            textAlign: "center", // Center align the text
            width: "100%", // Full width
            position: "relative",
            marginTop: "10px",
            marginBottom: "15px",
            fontFamily: "Verdana",
            fontSize: "30px",
          }}
        >
          {errorMessage}
        </Typography>
        {/* <Typography
          sx={{
            color: "#2D3748",
            fontFamily: "Verdana",
            fontWeight: 400,
            fontSize: "28px",
            textAlign: "center", // Center align the text
            width: "100%", // Full width
            position: "relative",
            marginBottom: "15px",
            fontFamily: "Verdana",
          }}
        >
          Please log in again.
        </Typography> */}

        {/* Add your content here */}
        <div style={dialogStyles.centeredButtonContainer}>
        <Button onClick={handleReloginClick} style={dialogStyles.centeredButton}>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "18px",
              fontWeight: "400",
              fontFamily: "Verdana",
            }}
          >
            Relogin
          </Typography>
        </Button>
      </div>
      </DialogContent>
    </Dialog>
  );
};

export default SessionExpiredDialog;
