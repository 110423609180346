import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Paper, Divider, Select, MenuItem } from "@mui/material";
import "../../styles/partCreation.css";
import { automationHttpRequest } from "../../api/httpRequests";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as DropdownIcon } from "../../assets/images/DropdownIcon.svg";

const PirData = ({ setShowPirData, setPirValue, handleContinue }) => {
  const [currency, setCurrency] = useState("$");

  const [pidDataValue, setPirDataValue] = useState({
    vendor_account: "",
    supplier_part_number: "",
    lead_time_from_pir: "",
    base_uom:"EA",
    standard_price: "",
    order_uom: "EA",
    predticed_usage: "",
    justification_for_net_price_change: "",
  });

  const [conversionValue, setConversionValue] = useState({
    order_unit: "",
    base_unit: ""
  });

  const options = ["$", "€", "£", "¥", "CA$"];
  const uomOptions = [
    "DZ",
    "FT",
    "RL",
    "MIL",
    "M",
    "TH",
    "BOX",
    "DR",
    "BTE",
    "KG",
    "BAG",
    "L",
    "EA",
    "PAK",
    "GLL",
    "PAA",
    "CS",
    "LB",
    "PAQ",
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };
  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    color: "#212F37",
    margin: "0px 10px",
  };
  const handleSelectChange = (event) => {
    setCurrency(event?.target?.value);
  };

  const headingStyle = {
    fontWeight: "700",
  };

  const handleConversionChange = (event) => {
    if (event?.target?.name === "conversionOrder") {
      setConversionValue((prevObject) => ({
        ...prevObject,
        order_unit: event?.target?.value,
      }));
    }
    if (event?.target?.name === "conversionBase") {
      setConversionValue((prevObject) => ({
        ...prevObject,
        base_unit: event?.target?.value,
      }));
    }
  }


  const handleChange = (event) => {
    if (event?.target?.name === "vendorNumber") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        vendor_account: event?.target?.value,
      }));
    }
    if (event?.target?.name === "supplierPartNumber") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        supplier_part_number: event?.target?.value,
      }));
    }
    if (event?.target?.name === "leadTime") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        lead_time_from_pir: event?.target?.value,
      }));
    }
    if (event?.target?.name === "netPrice") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        standard_price: event?.target?.value,
      }));
      if (parseInt(event?.target?.value) > 15) {
        setPirDataValue((prevObject) => ({
          ...prevObject,
          justification_for_net_price_change: "",
        }));
      }
    }
    if (event?.target?.name === "orderUOM") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        order_uom: event?.target?.value,
      }));
    }
    if (event?.target?.name === "baseUOM") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        base_uom: event?.target?.value,
      }));
    }
    if (event?.target?.name === "predictedUsage") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        predticed_usage: event?.target?.value,
      }));
    }
    if (event?.target?.name === "enterJustification") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        justification_for_net_price_change: event?.target?.value,
      }));
    }
    if (event?.target?.name === "baseUOM") {
      setPirDataValue((prevObject) => ({
        ...prevObject,
        base_uom: event?.target?.value,
      }));
    }
  };

  const handleSubmit = () => {
    // Assuming pidDataValue is an object
    let formattedValue = parseFloat(pidDataValue.standard_price);

    // Check if the value has a decimal part
    formattedValue = formattedValue.toFixed(2);
    // formattedValue += currency;
    // Update the standard_price property in pidDataValue with the formatted value
    
    const updatedDataValue = {
      ...pidDataValue,
      currency,
      uom_conversion: conversionValue?.base_unit+":"+conversionValue?.order_unit,
      standard_price: formattedValue.toString(), // convert to string for consistency
    };
    setPirValue(updatedDataValue);
  };

  const handleReset = () => {
    setPirDataValue({
      vendor_account: "",
      supplier_part_number: "",
      lead_time_from_pir: "",
      standard_price: "",
      order_uom: "",
      predticed_usage: "",
    });
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
            handleContinue();
          }}
        >
          {btnText}
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleReset();
          }}
        >
          Reset
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            setShowPirData(false);
          }}
        >
          Back
        </button>
      </>
    );
  };

  return (
    <>
      {/* <Typography className="pirdata">PIR Data Purchasing Info Record (PIR) Data’ </Typography> */}
      {/* <Divider /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          // justifyContent: "space-between"
        }}
      >
        <div style={inputStyle}>
          <Typography style={headingStyle}>Vendor Number</Typography>
          <input
            value={pidDataValue?.vendor_account}
            //placeholder="Vendor Number"
            type="number"
            name="vendorNumber"
            onChange={handleChange}
            className="partInputField"
          />
        </div>
        <div style={inputStyle}>
          <Typography style={headingStyle}>Supplier Part Number</Typography>
          <input
            value={pidDataValue?.supplier_part_number}
            //placeholder="Supplier Part Number"
            type="text"
            name="supplierPartNumber"
            onChange={handleChange}
            className="partInputField"
          />
        </div>
        <div style={inputStyle}>
          <Typography style={headingStyle}>Lead Time</Typography>
          <input
            value={pidDataValue?.lead_time_from_pir}
            //placeholder="Lead Time"
            type="number"
            name="leadTime"
            onChange={handleChange}
            className="partInputField"
          />
        </div>
        <div style={inputStyle}>
          <Typography style={headingStyle}>Base UOM</Typography>
          <FormControl sx={{ m: 1, width: "100px" }}>
              <Select
                value={pidDataValue?.base_uom}
                name="baseUOM"
                onChange={handleChange}
                IconComponent={() => (
                  <DropdownIcon
                    style={{
                      width: "30px",
                      position: "relative",
                      left: "-10px",
                    }}
                  />
                )}
                MenuProps={MenuProps}
                sx={{ height: "32px", borderRadius: "8px" }}
              >
                {uomOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </div>
        <div style={inputStyle}>
          <Typography style={headingStyle}>Order UOM</Typography>
          <FormControl sx={{ m: 1, width: "100px" }}>
              <Select
                value={pidDataValue?.order_uom}  
                name="orderUOM"
                onChange={handleChange}
                IconComponent={() => (
                  <DropdownIcon
                    style={{
                      width: "30px",
                      position: "relative",
                      left: "-10px",
                    }}
                  />
                )}
                MenuProps={MenuProps}
                sx={{ height: "32px", borderRadius: "8px" }}
              >
                {uomOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </div>
        {(pidDataValue?.base_uom !== pidDataValue?.order_uom) && (pidDataValue?.base_uom !== "" && pidDataValue?.order_uom !== "" ) && (
          <div style={inputStyle}>
            <Typography style={headingStyle}>Conversion</Typography>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }} >
              <input
                value={conversionValue?.base_unit}
                type="number"
                name="conversionBase"
                onChange={handleConversionChange}
                className="conversionInputField"
              />
              <Typography className="conversionValue">{pidDataValue?.base_uom}</Typography>
              <Typography className="conversionEqual">==</Typography>
              <input
                value={conversionValue?.order_unit}
                type="number"
                name="conversionOrder"
                onChange={handleConversionChange}
                className="conversionInputField"
              />
              <Typography className="conversionValue">{pidDataValue?.order_uom}</Typography>
            </div>
          </div>
        )}

        <div style={inputStyle}>
          <Typography style={headingStyle}>Predicted Usage</Typography>
          <input
            value={pidDataValue?.predticed_usage}
            //placeholder="Predicted Usage"
            type="number"
            name="predictedUsage"
            onChange={handleChange}
            className="partInputField"
          />
        </div>
        <div style={inputStyle}>
          <Typography style={{fontWeight: "700",position: "relative", left: "12px"}}>Net Price</Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              value={pidDataValue?.standard_price}
              //placeholder="Net Price($)"
              type="number"
              name="netPrice"
              onChange={handleChange}
              className="partInputField"
              style={{ width: "80px" }}
            />
            <FormControl sx={{ m: 1, width: "70px", position: "relative", left: "-10px" }}>
              <Select
                value={currency}
                onChange={handleSelectChange}
                IconComponent={() => (
                  <DropdownIcon
                    style={{
                      width: "40px",
                      position: "relative",
                      left: "-10px",
                    }}
                  />
                )}
                sx={{ height: "32px",  borderRadius: "8px" }}
              >
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {pidDataValue?.standard_price <= 15 &&
          pidDataValue?.standard_price !== "" && (
            <div style={inputStyle}>
              <Typography style={headingStyle}>Enter Justification</Typography>
              <input
                value={pidDataValue?.justification_for_net_price_change}
                //placeholder="Enter Justification"
                type="text"
                // style={{ background: pidDataValue?.standard_price > 15 ? "#EFEFEF" : "#FFFFFF" }}
                // disabled={pidDataValue?.standard_price > 15}
                name="enterJustification"
                onChange={handleChange}
                className="partInputField"
              />
            </div>
          )}

        {/* <button className="buttonCancel">
          Confirm
        </button> */}
      </div>
      <div style={{ margin: "25px" }}>
        <ButtonFamily btnText="Next" />
      </div>
    </>
  );
};
export default PirData;
