import axios from "axios";
let httpRequest = axios.create({
  // baseURL: `http://localhost:3001/api`,
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`
});

httpRequest.interceptors.request.use(
  function (config) {
    if (config) {
      //axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpRequest.interceptors.response.use(
  function (response) {
    if (response) {
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let automationHttpRequest = axios.create({
  // baseURL: `http://localhost:8000/api`,
  baseURL:`${process.env.REACT_APP_BASE_URL_AUTOMATION}/api`
});

automationHttpRequest.interceptors.request.use(
  function (config) {
    if (config) {
      //axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

automationHttpRequest.interceptors.response.use(
  function (response) {
    if (response) {
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export {
  httpRequest,
  automationHttpRequest
};
