import React, { useDebugValue, useEffect, useState } from "react";
import { Typography, Tooltip, IconButton, Input, Divider } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchSvgIcon.svg";
import Popover from '@mui/material/Popover';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { httpRequest, automationHttpRequest } from "../../api/httpRequests";


const SearchFilter = ({ filter_values, searchFilterOPen, handleSearchClose, column_Name, filterColumns, handleFiltersearch }) => {

    const [final_filter_values, setFinal_filter_values] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilterValues] = useState({
        description: [],
        storage_bin: [],
        storage_location: [],
        material_number: [],
        manufacturing_part_number: [],
        plant_name: [],
    });

    useEffect(() => {
        if (localStorage.getItem("description")?.length) {
            setFilterValues({
                ...filterValues,
                description: JSON.parse(localStorage.getItem("description")),
            });
        }
        if (localStorage.getItem("manufacturing_part_number")?.length) {
            setFilterValues({
                ...filterValues,
                manufacturing_part_number: JSON.parse(localStorage.getItem("manufacturing_part_number")),
            });
        }
        if (localStorage.getItem("plant_name")?.length) {
            setFilterValues({
                ...filterValues,
                plant_name: JSON.parse(localStorage.getItem("plant_name")),
            });
        }
        if (localStorage.getItem("material_number")?.length) {
            setFilterValues({
                ...filterValues,
                material_number: JSON.parse(localStorage.getItem("material_number")),
            });
        }
        if (localStorage.getItem("storage_location")?.length) {
            setFilterValues({
                ...filterValues,
                storage_location: JSON.parse(localStorage.getItem("storage_location")),
            });
        }
        if (localStorage.getItem("storage_bin")?.length) {
            setFilterValues({
                ...filterValues,
                storage_bin: JSON.parse(localStorage.getItem("storage_bin")),
            });
        }
        let temp = []
        if (filterColumns?.[column_Name] === "description") {
            temp = JSON.parse(localStorage.getItem("description"))
        }
        if (filterColumns?.[column_Name] === "manufacturing_part_number") {
            temp = JSON.parse(localStorage.getItem("manufacturing_part_number"))
        }
        if (filterColumns?.[column_Name] === "plant_name") {
            temp = JSON.parse(localStorage.getItem("plant_name"));
        }
        if (filterColumns?.[column_Name] === "material_number") {
            temp = JSON.parse(localStorage.getItem("material_number"))
        }
        if (filterColumns?.[column_Name] === "storage_location") {
            temp = JSON.parse(localStorage.getItem("storage_location"));
        }
        if (filterColumns?.[column_Name] === "storage_bin") {
            temp = JSON.parse(localStorage.getItem("storage_bin"))
        }
        if (filter_values?.length) {
            let all = [];
            let values = []
            if (temp?.length === filter_values?.length) {
                if(temp?.length === 1) {
                    all = [];
                } else {
                    all = [{ "key": "Select All", "checked": true }];
                }
                values = filter_values && filter_values?.map((val, index) => {
                    let temp = {};
                    temp.key = val;
                    temp.checked = true;
                    return { ...temp };
                })
            } else {
                all = [{ "key": "Select All", "checked": false }];
                values = filter_values && filter_values?.map((val, index) => {
                    let tempVal = {};
                    if (temp?.includes(val)) {
                        tempVal.key = val;
                        tempVal.checked = true;
                    } else {
                        tempVal.key = val;
                        tempVal.checked = false;
                    }
                    return { ...tempVal };
                })
            }

            setFinal_filter_values([...all, ...values])
        }
    }, [filter_values])

    useEffect(() => {
        const delay = setTimeout(async () => {
            if (searchTerm) {
                setLoading(true)
                try {
                    const response = await httpRequest.post(
                        "/filter_find",
                        {},
                        {
                            headers: {
                                session_id: localStorage.getItem("session_id"),
                            },
                            params: {
                                filter_name: filterColumns?.[column_Name],
                                search_str: searchTerm.trim()
                            },
                        }
                    );

                    if (response?.data?.success) {
                        const data = response.data.filtered_list.slice(0, 100);
                        if (data?.length > 1) {
                            const all = [{ "key": "Select All", "checked": false }];
                            const values = data && data?.map((val, index) => {
                                let temp = {};
                                temp.key = val;
                                temp.checked = false;
                                return { ...temp };
                            })
                            setFinal_filter_values([...all, ...values])
                        } else if (data?.length === 1) {
                            const values = data && data?.map((val, index) => {
                                let temp = {};
                                temp.key = val;
                                temp.checked = false;
                                return { ...temp };
                            })
                            setFinal_filter_values([...values])
                        } else {
                            setFinal_filter_values([])
                        }
                        setLoading(false)
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        }, 300);

        return () => clearTimeout(delay);
    }, [searchTerm]);


    const ButtonFamily = () => {
        return (
            <>
                <div style={{ display: "flex"}}>
                    <button
                        type="submit"
                        className="ApplyButton"
                        style={{
                            fontSize: 16,
                            cursor: "pointer",
                            position: "relative",
                            fontFamily: "Verdana",
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleFiltersearch(filterValues)
                        }}
                    >
                        Apply
                    </button>
                    <button
                        type="submit"
                        className="checkbutton"
                        style={{
                            fontSize: 16,
                            cursor: "pointer",
                            position: "relative",
                            fontFamily: "Verdana",
                            top: "-1px"
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSearchClose()
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    };

    const handleUpdateFilterValue = (newSelected) => {
        let colName = filterColumns?.[column_Name]
        if (colName === "description") {
            setFilterValues({
                ...filterValues,
                description: typeof newSelected === "string" ? newSelected.split(",") : newSelected,
            });
            localStorage.setItem("description", JSON.stringify(newSelected))
        }
        if (colName === "plant_name") {
            setFilterValues({
                ...filterValues,
                plant_name: typeof newSelected === "string" ? newSelected.split(",") : newSelected,
            });
            localStorage.setItem("plant_name", JSON.stringify(newSelected))
        }
        if (colName === "manufacturing_part_number") {
            setFilterValues({
                ...filterValues,
                manufacturing_part_number: typeof newSelected === "string" ? newSelected.split(",") : newSelected,
            });
            localStorage.setItem("manufacturing_part_number", JSON.stringify(newSelected))
        }
        if (colName === "storage_location") {
            setFilterValues({
                ...filterValues,
                storage_location: typeof newSelected === "string" ? newSelected.split(",") : newSelected,
            });
            localStorage.setItem("storage_location", JSON.stringify(newSelected))
        }
        if (colName === "material_number") {
            setFilterValues({
                ...filterValues,
                material_number: typeof newSelected === "string" ? newSelected.split(",") : newSelected,
            });
            localStorage.setItem("material_number", JSON.stringify(newSelected))
        }
        if (colName === "storage_bin") {
            setFilterValues({
                ...filterValues,
                storage_bin: typeof newSelected === "string" ? newSelected.split(",") : newSelected,
            });
            localStorage.setItem("storage_bin", JSON.stringify(newSelected))
        }
    }

    const handleCheckbox = (e, data) => {

        if (e.target.checked) {
            if (data?.key === "Select All") {
                const checkedArray = final_filter_values?.map((ele, index) => {
                    return { ...ele, checked: true };
                })
                const selectItems = checkedArray?.filter(val => val.checked && val?.key !== "Select All")
                const newSelected = selectItems.map((val) => val?.key)
                handleUpdateFilterValue(newSelected)
                setFinal_filter_values(checkedArray);
            } else {
                const checkedArray = final_filter_values?.map((ele, index) => {
                    if (ele?.key === data?.key) {
                        return { ...ele, checked: true };
                    } else {
                        return { ...ele };
                    }
                })
                const selectItems = checkedArray?.filter(val => val.checked && val?.key !== "Select All")
                const newSelected = selectItems.map((val) => val?.key)
                handleUpdateFilterValue(newSelected)
                setFinal_filter_values(checkedArray);
            }
        } else {
            if (data?.key === "Select All") {
                const checkedArray = final_filter_values?.map((ele, index) => {
                    return { ...ele, checked: false };
                })
                const selectItems = checkedArray?.filter(val => val.checked && val?.key !== "Select All")
                const newSelected = selectItems.map((val) => val?.key)
                handleUpdateFilterValue(newSelected)
                setFinal_filter_values(checkedArray);
            } else {
                const checkedArray = final_filter_values?.map((ele, index) => {
                    if (ele?.key === data?.key) {
                        return { ...ele, checked: false };
                    } else {
                        return { ...ele };
                    }
                })
                const selectItems = checkedArray?.filter(val => val.checked && val?.key !== "Select All")
                const newSelected = selectItems.map((val) => val.request_id)
                handleUpdateFilterValue(newSelected)
                setFinal_filter_values(checkedArray);
            }
        }
    };

    const handleChange = (event) => {
        setSearchTerm(event?.target?.value)
    }

    return (
        <>
            <div className="popOverDialog">
                <div
                    style={{
                        position: "relative",
                        display: "inline-block",
                        width: "90%",
                    }}
                >
                    <IconButton
                        style={{
                            position: "absolute",
                            zIndex: 2,
                            fontSize: "0px",
                        }}
                    >
                        <SearchIcon />
                    </IconButton>
                    <input
                        placeholder="Search"
                        name="searchFilter"
                        onChange={handleChange}
                        type="text"
                        value={searchTerm}
                        className="searchInput"
                    />
                </div>
                <Divider style={{ margin: "4px 0px" }} />
                {loading ? (
                    <>
                        <div>Loading..</div>
                    </>
                ) : (
                    <>{final_filter_values?.length ? (
                        <>
                            <div>
                                {final_filter_values && final_filter_values?.map((val, index) => {
                                    return (
                                        <>
                                            {val?.key.length > 15 ? (
                                                <Tooltip title={val?.key} arrow placement="bottom">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        <FormGroup>
                                                            <FormControlLabel sx={{ margin: "2px 10px" }} control={<Checkbox checked={val?.checked} onChange={(e) => handleCheckbox(e, val)} />} label={val?.key?.substring(0, 15)} />
                                                        </FormGroup>
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    <FormGroup>
                                                        <FormControlLabel sx={{ margin: "2px 10px" }} control={<Checkbox checked={val?.checked} onChange={(e) => handleCheckbox(e, val)} />} label={val?.key} />
                                                    </FormGroup>
                                                </>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <div>
                            <Typography sx={{
                                textAlign: "center",
                                color: "#C6003D"
                            }}>No option found</Typography>
                        </div>
                    )}
                    </>
                )}
                <div className="fixed-bottom">
                    <ButtonFamily />
                </div>
            </div>
        </>
    )


}

export default SearchFilter;
