import React from "react";
import '../styles/loading.css';

const Loading = () => {
    return (
        <>
            <div className="loader-container" >
                <div className="spinner"></div>
            </div>
        </>
    )
}

export default Loading;
