import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import { Select, MenuItem, Input, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../dashboard/Header";
import AtcPartRequestDialog from "./AtcPartRequestDialog";
import "../../styles/ownerPartRequest.css";
import { ReactComponent as DropdownIcon } from "../../assets/images/DropdownIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../assets/images/DatePickerIcon.svg";
import AtcPartCreationDialog from "./AtcPartCreationDialog";
import { automationHttpRequest } from "../../api/httpRequests";
import AtcModificationDialog from "./AtcModificationDialog";
import { ToastContainer, toast } from "react-toastify";


const AtcPartRequestHistory = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [partRequestNumber, setPartRequestNumber] = useState();
  const [partRequestDialogOpen, setPartRequestDialogOpen] = useState(false);
  const [partRequestDetails, setPartRequestDetails] = useState();
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dfDetais, setDfDetais] = useState();
  const [partCreationDialogOpen, setPartCreationDialogOpen] = useState(false);
  const [partCreationDetails, setPartCreationDetails] = useState();
  const [partModificationDialogOpen, setPartModificationDialogOpen] =
    useState(false);
  const [partModificationDetails, setPartModificationDetails] = useState();
  const [actionType, setActionType] = useState([
    "Extension",
    "Modification",
    "Creation",
  ]);
  const [partModificationType, setPartModificationType] = useState();

  useEffect(() => {
    fetchPartRequestHistory();
  }, [actionType, fromDate, toDate, page, rowsPerPage]);

  const handleRequest = (value, type) => {
    if (type === "Extension") {
      handlePartRequestDetails(value);
    }
    if (type === "Creation") {
      handlePartCreationDetails(value);
    }
    if (type === "Modification") {
      handlePartModificationDetails(value);
    }
  };

  const handlePartModificationDetails = async (value) => {
    setPartRequestNumber(value);
    setPartModificationDialogOpen(true);
    if (value) {
      setDialogLoading(true);
      try {
        const response = await automationHttpRequest.post(
          "/modification_details_for_ETC/",
          { request_id: value.toString() },
          {
            headers: {
              session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
            },
            params: {},
          }
        );
        if (response?.data?.status) {
          setDialogLoading(false);
          setPartModificationDetails(response?.data?.data[0]);
          setPartModificationType(response?.data?.type);
        }
      } catch (error) {
        setDialogLoading(false);
        toast.error("Unable to process the request. Please try again!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      }
    }
  };

  const fetchPartRequestHistory = async () => {
    setLoading(true);
    try {
      const response = await automationHttpRequest.post(
        "/logbook_global/", 
        {
          request_type: actionType,
          from_date: handleDateFormat(fromDate) ?  handleDateFormat(fromDate):"",
          to_date:  handleDateFormat(toDate) ? handleDateFormat(toDate) : "",
          page_size: rowsPerPage,
          page: page + 1,
        }, 
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          }
        }
      );
      if (response?.data?.status) {
        setTableData(response?.data?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handlePartRequestDetails = (value) => {
    setPartRequestNumber(value);
    const handlePartRequest = async (value) => {
      if (value) {
        setDialogLoading(true);
        try {
          const response = await automationHttpRequest.post(
            "/etc_extension/",
            { request_id: value.toString() },
            {
              headers: {
                session_id: localStorage.getItem("session_id"),
                userEmail: localStorage.getItem("MRO_user_email"),
              },
              params: {},
            }
          );
          if (response?.data?.status) {
            setDialogLoading(false);
            setPartRequestDetails(response?.data?.data?.data[0]);
            setDfDetais(response?.data?.data?.df_data);
          }
        } catch (error) {
          setDialogLoading(false);
          toast.error("Unable to process the request. Please try again!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 800,
          });
        }
      }
    };
    handlePartRequest(value);
    setPartRequestDialogOpen(true);
  };

  const handlePartCreationDetails = (value) => {
    setPartRequestNumber(value);
    const handlePartCreation = async (value) => {
      if (value) {
        setDialogLoading(true);
        try {
          const response = await automationHttpRequest.post(
            "/get_ETC_History_For_Creation/",
            { request_id: value.toString() },
            {
              headers: {
                session_id: localStorage.getItem("session_id"),
                userEmail: localStorage.getItem("MRO_user_email"),
              },
              params: {},
            }
          );
          if (response?.data?.status) {
            setDialogLoading(false);
            setPartCreationDetails(response?.data?.data[0]);
          }
        } catch (error) {
          setDialogLoading(false);
          toast.error("Unable to process the request. Please try again!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 800,
          });
        }
      }
    };
    handlePartCreation(value);
    setPartCreationDialogOpen(true);
  };

  const handleDateFormat = (value) => {    
    if(!value){
      return ""
    }
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
  
    const inputDate = new Date(value);
    const formattedDate = formatDate(inputDate);    
    return formattedDate;
  };

  const filteredColumns = {
    "Request ID": "request_id",
    "Material Number": "material_number",
    "Date of Approval": "date_of_approval",
    "Request Type": "request_type",
    "Approved by": "approved_by",
    "Requested for plant": "plant_name",
    Status: "status",
  };

  const options = ["Extension", "Modification", "Creation"];

  const handleSelectChange = (event) => {
    const selectedValues = event.target.value;
    const updatedSelectedOptions = selectedValues.includes("All")
      ? options.filter((option) => option !== "All")
      : selectedValues;
    setSelectedOptions(updatedSelectedOptions);
    setActionType(updatedSelectedOptions);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApprove = () => {
    console.log("Approved");
  };

  const handleReject = () => {
    console.log("Rejected");
  };

  const handleDialogClose = () => {
    setPartRequestDialogOpen(false);
  };

  const handleCreationDialogClose = () => {
    setPartCreationDialogOpen(false);
  };

  const handleModificationDialogClose = () => {
    setPartModificationDialogOpen(false);
  };
  return (
    <>
      <div>
        <Header handleHideInsights={""} />
        <div style={{ margin: "50px" }}>
          <div>
            <Typography className="approval">Approval Status</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <div className="selectOption">
              <Typography className="selectHeading">
                Select Request Type
              </Typography>
              <FormControl sx={{ m: 1, width: "250px" }}>
                <Select
                  multiple
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  IconComponent={() => (
                    <DropdownIcon
                      style={{
                        width: "20px",
                        position: "relative",
                        left: "-10px",
                      }}
                    />
                  )}
                  renderValue={(selected) => selected.join(", ")}
                  sx={{ height: "32px" }}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox
                        checked={selectedOptions.indexOf(option) > -1}
                      />
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ flex: 1 }}></div>
            {/* <div style={{ display: "flex", gap: "10px" }}>
                        <button
                            // onClick={handleApply}
                            className="actionButton"
                        // disabled={filterDisable}
                        >
                            <Typography className="actionButtonName"> Reject </Typography>
                        </button>
                        <button className="actionButton"
                        // onClick={handleApply}
                        >
                            <Typography className="actionButtonName"> Approve </Typography>
                        </button>
                    </div> */}
            <div
              style={{
                // position: "relative",
                margin: "0px 20px",
                display: "flex",
              }}
            >
              {/* <IconButton className="buttonIcon">
                <SearchIcon />
              </IconButton>
              <Input
                fullWidth
                disableUnderline
                // value={search_value}
                placeholder="Search history"
                className="searchInputField"
                // onChange={handleInputChange}
                // onKeyDown={handleKeyPress}
              /> */}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div>
                <label htmlFor="fromDate" className="datePickerLabel">
                  From{" "}
                </label>
                <DatePicker
                  selected={fromDate}
                  onChange={(newDate) => setFromDate(newDate)}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  customInput={
                    <div style={{ position: "relative" }}>
                      <DatePickerIcon className="datePickerIcon" />
                      <input
                        className="datePickerInput"
                        type="text"
                        value={
                          fromDate ? handleDateFormat(fromDate) : "Choose Date"
                        }
                        readOnly
                      />
                    </div>
                  }
                />
              </div>
              <div>
                <label htmlFor="toDate" className="datePickerLabel">
                  To{" "}
                </label>
                <DatePicker
                  selected={toDate}
                  onChange={(newDate) => setToDate(newDate)}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  customInput={
                    <div style={{ position: "relative" }}>
                      <DatePickerIcon className="datePickerIcon" />
                      <input
                        className="datePickerInput"
                        type="text"
                        value={
                          toDate ? handleDateFormat(toDate) : "Choose Date"
                        }
                        readOnly
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <Paper sx={{ width: "100%", mb: 2, border: "1px solid #BDBDBD" }} elevation={2}>
              {loading ? (
                <>
                  <Typography
                    sx={{
                      height: "70px",
                      fontSize: "18px",
                      padding: "20px",
                      background: "#EFEFEF",
                    }}
                  >
                    Loading...
                  </Typography>
                </>
              ) : (
                <>
                  <TableContainer
                    sx={{
                      maxHeight: "600px",
                      overflowY: "auto",
                      borderBottom: "1px solid #BDBDBD",
                    }}
                  >
                    <Table
                      sx={{ minWidth: 750, border: "1px solid #BDBDBD" }}
                      aria-labelledby="tableTitle"
                    >
                      <TableHead>
                        <TableRow>
                          {/* Add a TableCell for the checkbox */}
                          {/* <TableCell
                                                    key="checkbox"
                                                    align="center"
                                                    padding="checkbox"
                                                    sx={{
                                                        backgroundColor: "#EAF0FD !important",
                                                        position: "sticky",
                                                        top: 0,
                                                        //   zIndex: 3,
                                                        borderRight: "1px solid #BDBDBD",
                                                    }}
                                                    className="fixed-column"
                                                >
                                                    <Checkbox color="primary" />
                                                </TableCell> */}
                          {/* Continue with other columns */}
                          {Object.keys(filteredColumns).map(
                            (columnName, index) => (
                              <TableCell
                                key={columnName}
                                align="center"
                                padding="normal"
                                sx={{
                                  backgroundColor: "#EAF0FD !important",
                                  position: "sticky",
                                  top: 0,
                                  // zIndex: 3,
                                  borderRight:
                                    index <
                                    Object.keys(filteredColumns).length - 1
                                      ? "1px solid #BDBDBD"
                                      : "none", // Add border-right
                                }}
                                className={
                                  columnName === "Request ID"
                                    ? "fixed-column"
                                    : null
                                }
                              >
                                {columnName === "Date of Submission"
                                  ? "Approval Date"
                                  : columnName}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {tableData?.map((row, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row["Request ID"]}
                          >
                            {/* Add TableCell for the checkbox */}
                            {/* <TableCell
                                                        key="checkbox"
                                                        align="center"
                                                        padding="checkbox"
                                                        sx={{
                                                            border: "1px solid #BDBDBD",
                                                            fontFamily: "Verdana",
                                                            textAlign: "center",
                                                            borderRight: "1px solid #BDBDBD", // Add border-right
                                                        }}
                                                        className="fixed-column"
                                                    >
                                                        <Checkbox color="primary" />
                                                    </TableCell> */}
                            {/* Continue with other columns */}
                            {Object.values(filteredColumns).map(
                              (columnName, columnIndex) => (
                                <TableCell
                                  key={columnName}
                                  align="center"
                                  sx={{
                                    border: "1px solid #BDBDBD",
                                    fontFamily: "Verdana",
                                    textAlign: "center",
                                    borderRight:
                                      columnIndex <
                                      Object.values(filteredColumns).length - 1
                                        ? "1px solid #BDBDBD"
                                        : "none", // Add border-right
                                  }}
                                  className={
                                    columnName === "request_id"
                                      ? "fixed-column"
                                      : null
                                  }
                                >
                                  {columnName === "request_id" ? (
                                    <button
                                      style={{
                                        border: "0px",
                                        background: "transparent",
                                        color: "#C6003D",
                                        cursor: "pointer",
                                        fontWeight: 550,
                                        fontFamily: "Verdana",
                                      }}
                                      onClick={() =>
                                        handleRequest(
                                          row[columnName],
                                          row["request_type"]
                                        )
                                      }
                                    >
                                      {row[columnName]}
                                    </button>
                                  ) : (
                                    row[columnName]
                                  )}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 16px", // Add padding
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Verdana",
                        marginLeft: "22px",
                      }}
                    >
                      {" "}
                      Total:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {tableData?.length}
                      </span>{" "}
                      records
                    </Typography>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15,50]}
                      component="div"
                      count={tableData?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </>
              )}
            </Paper>
          </div>
        </div>
      </div>
      <AtcPartRequestDialog
        open={partRequestDialogOpen}
        dialogData={partRequestDetails}
        closeDialog={handleDialogClose}
        partRequestNumber={partRequestNumber}
        dialogLoading={dialogLoading}
        dfDetais={dfDetais}
      />
      <AtcPartCreationDialog
        open={partCreationDialogOpen}
        dialogData={partCreationDetails}
        closeDialog={handleCreationDialogClose}
        partRequestNumber={partRequestNumber}
        dialogLoading={dialogLoading}
      />
      <AtcModificationDialog
        open={partModificationDialogOpen}
        dialogData={partModificationDetails}
        closeDialog={handleModificationDialogClose}
        modificationType={partModificationType}
        partRequestNumber={partRequestNumber}
        dialogLoading={dialogLoading}
      />
    </>
  );
};

export default AtcPartRequestHistory;
