import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MSAL_CONFIG } from './azure/config/authConfig';

const msalInstance = new PublicClientApplication(MSAL_CONFIG)

if(!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length>0){
  msalInstance.setActiveAccount(msalInstance.getActiveAccount([0]))
}

msalInstance.addEventCallback((event)=>{
  if(event.eventType === EventType.LOGIN_SUCCESS && event.payload.account){
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App instance = {msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
