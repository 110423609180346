import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  OutlinedInput,
  Divider,
  Chip,
  ListSubheader,
  InputAdornment,
  TextField, Autocomplete
} from "@mui/material";
import { ReactComponent as DropdownIcon } from "../../assets/images/DropdownIcon.svg";
import "../../styles/partCreation.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {automationHttpRequest} from "../../api/httpRequests";


const PartDescription = ({ handleContinue, setSearchString, setPartDescriptionData }) => {
  const [noun, setNoun] = useState();
  const [modifier, setModifier] = useState();
  const [nouns, setNouns] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [attributeValues, setAttributeValues] = useState({});
  const [uomValues, setUOMValues] = useState({});
  const [attributeValue, setAttributeValue] = useState({});
  const [chipValues, setChipValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [uomValue, setUomValue] = useState({});
  const [updateAttributeValues, setUpdatedAttributeValues] = useState({})
  const [typeAttributeValue, setTypeAttributeValue] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [modifierSearch, setModifierSearch] = useState('');
  const [optionToShow, setOptionToShow] = useState([]);
  const [topAttribute, setTopAttribute] = useState([]);
  const [registeredAttribute, setRegisteredAttribute] = useState({});
  const [attributeTypeValue, setAttributeTypeValue] = useState({});

  useEffect(() => {
    if (uomValue && Object.keys(uomValue).length) {
      const mappedObject = Object.fromEntries(
        Object.entries(attributeValue).map(([key, value]) => {
          if (Object.keys(uomValue).includes(key)) {
            const result = uomValue[key];
            return [key, `${value} ${result}`]
          } else {
            return [key, value]
          }
        })
      );
      setUpdatedAttributeValues(mappedObject)
    } else {
      setUpdatedAttributeValues(attributeValue)
    }
  }, [attributeValue, uomValue])


  useEffect(() => {
    if (updateAttributeValues) {
      setPartDescriptionData((prevValue) => ({
        ...prevValue,
        attribute_data: updateAttributeValues,
      }));
    }
  }, [updateAttributeValues])

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    width: "400px"
  };

  const headingStyle = {
    fontSize: 16,
    fontFamily: "Verdana",
    fontWeight: "700",
    margin: "30px 0px 10px 0px",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };

  const uomProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };

  useEffect(() => {
    fetchNouns();
  }, []);

  useEffect(() => {
    if (chipValues) {
      const val = chipValues.join(" ");
      setPartDescriptionData((prevValue) => ({
        ...prevValue,
        clean_short_description: val,
      }));
    }
  }, [chipValues]);

  const fetchNouns = async () => {
    setFetching(true);
    try {
      const response = await automationHttpRequest.get(
        "/get_noun/",
       {
        headers: {
          session_id: localStorage.getItem("session_id"),
              userEmail: localStorage.getItem("MRO_user_email"),
        },
      });
      if (response?.data?.status) {
        setNouns(response?.data?.data);
        setOptionToShow(response?.data?.data.slice(0, 100))
        setFetching(false);
      }
    } catch (error) {
      console.error("Error fetching nouns:", error);
    }
  };

  const fetchModifiers = async (selectedNoun) => {
    try {
      const response = await automationHttpRequest.post(
        "/get_modifier/",
        {},
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
              userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {
            noun: selectedNoun,
          },
        }
      );
      if (response?.data?.status) {
        setModifiers(response?.data?.data);
      }
    } catch (error) {
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const fetchAttributes = async (selected_modifier) => {
    setLoading(true);
    setAttributeValue({});
    setAttributeTypeValue({})
    setUomValue({})
    try {
      const response = await automationHttpRequest.post(
        "/get_attribute/",
        {},
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
              userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {
            noun: noun,
            modifier: selected_modifier,
          },
        }
      );
      const attributeData = response.data.data;
      setTopAttribute(attributeData.map((item) => item.attribute_name).slice(0, 4));
      setAttributes(attributeData.map((item) => item.attribute_name));
      // Create an object to store attribute values
      const attributeValuesObject = {};
      attributeData.forEach((item) => {
        attributeValuesObject[item.attribute_name] =
          item.attribute_values || [];
      });
      setAttributeValues(attributeValuesObject);
      const uomValuesObject = {};
      attributeData.forEach((item) => {
        uomValuesObject[item.attribute_name] =
          item.uom || [];
      });
      const typeValuesObject = {};
      attributeData.forEach((item) => {
        typeValuesObject[item.attribute_name] =
          item.type || [];
      });
      setUOMValues(uomValuesObject)
      setTypeAttributeValue(typeValuesObject)
      setLoading(false);
    } catch (error) {
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const handleAttributeChange = (attribute, value, type) => {
    if (type === "select") {
      setAttributeValue((prevAttributeValue) => ({
        ...prevAttributeValue,
        [attribute]: value,
      }));
    } else {
      setAttributeValue((prevAttributeValue) => ({
        ...prevAttributeValue,
        [attribute]: value,
      }));
      setAttributeTypeValue((prevAttributeValue) => ({
        ...prevAttributeValue,
        [attribute]: value,
      }));
    }
    const index = topAttribute.indexOf(attribute) + 2;
    if (index !== -1 && type === "select") {
      if (chipValues.length < 6 || (chipValues.length === 6 && Object.keys(registeredAttribute).includes(attribute))) {
        if (chipValues.length - 1 < index && !Object.keys(registeredAttribute).includes(attribute)) {
          setChipValues([...chipValues, value]);
          setRegisteredAttribute((prev) => ({
            ...prev, [attribute]: value
          }))
        } else {
          const keys = Object.keys(registeredAttribute);
          if (keys.includes(attribute)) {
            let val = registeredAttribute?.[attribute];
            const myArray = [...chipValues];
            myArray.splice(myArray.indexOf(val), 1, value);
            setChipValues([...myArray]);
            setRegisteredAttribute((prev) => ({
              ...prev, [attribute]: value
            }))
          } else {
            const array = [...chipValues];
            array.splice(index, 0, value);
            setChipValues([...array]);
          }
        }
      }
    }
  };

  const handleUomChange = (attribute, value) => {
    setUomValue((prevAttributeValue) => ({
      ...prevAttributeValue,
      [attribute]: value,
    }));
    // if (chipValues.length < 6) {
    //   setChipValues([...chipValues, value]);
    // }
  };

  const handleNounChange = (val) => {
    setChipValues([val]);
    setNoun(val);
    setPartDescriptionData((prevValue) => ({
      ...prevValue,
      noun_indentifier: val,
    }));
    setModifier("");
    setRegisteredAttribute({})
    fetchModifiers(val);
  };

  const handleReset = () => {
    setNouns([]);
    setModifiers([]);
    setNoun();
    setModifier();
    setAttributes([]);
    setAttributeValues({});
    setChipValues([]);
    fetchNouns();
    setRegisteredAttribute({})
  };

  const handleNext = () => {
    handleContinue()
  };

  const handleModifierChange = (val) => {
    setModifier(val);
    // Retain only the first element in chipValues and append the new value
    setPartDescriptionData((prevValue) => ({
      ...prevValue,
      modifier: val,
    }));
    setChipValues([chipValues[0], val]);
    setRegisteredAttribute({})
    fetchAttributes(val);
  };

  const handlechipsValues = (values) => {
    const val = values.join(" ");
    localStorage.setItem("shortDesc", val)
    return val;
  }
  const handleSearchTerm = (event) => {
    event.preventDefault();
    // setSearchTerm(event?.target?.value)
    const value = nouns.filter((option) =>
      option.toLowerCase().includes(event?.target?.value.toLowerCase()))
    setOptionToShow(value.slice(0, 100));
  }

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          margin: "20px 0px",
          borderRadius: "18px",
        }} elevation={2}
      >
        {fetching ? (
          <>
            <Typography sx={{
              height: "70px",
              fontSize: "18px",
              padding: "20px",
              background: '#EFEFEF'
            }}>Loading...</Typography>
          </>
        ) : (
          <>
            <div style={{ margin: "40px" }}>
              <div className="partDesc" style={{ paddingBottom: "20px" }}>
                <div style={inputStyle} className="selectComponent">
                  <Typography style={headingStyle}>Noun</Typography>
                  {/* <Select
                    sx={{
                      height: "32px",
                      borderRadius: "8px",
                      width: "250px !important",
                      background: "#FFFFFF",
                      marginRight: "300px",
                    }}
                    value={noun ? noun : "select"}
                    onChange={(event) => handleNounChange(event?.target?.value)}
                    inputProps={{ "aria-label": "Without label" }}
                    input={<OutlinedInput />}
                    IconComponent={() => <DropdownIcon style={{ width: "20px", position: "relative", left: "-10px" }} />}
                    MenuProps={MenuProps}
                  >
                    <ListSubheader sx={{ background: "#EAEFF8", padding: "4px" }}>
                      <TextField
                        size="small"
                        autoFocus
                        placeholder='Search'
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                        onChange={handleSearchTerm}
                        onKeyDown={(e) => {
                          // if (e.key !== "Escape") {
                          e.stopPropagation();
                          // }
                        }}
                      />
                    </ListSubheader>
                    <MenuItem disabled value="select">
                      <span style={{ color: "#333333" }}>Select Noun</span>
                    </MenuItem>
                    {optionToShow.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Autocomplete
                    popupIcon={
                      <DropdownIcon style={{ width: "20px", position: "relative", left: "0px" }} />
                    }
                    id="combo-box-demo"
                    options={nouns}
                    disableClearable={true}
                    value={noun}
                    onChange={(e, value) => {
                      handleNounChange(value)
                      setModifier("")
                    }}
                    renderInput={(params) => <TextField className="formControlSelect" placeholder="Select Noun" {...params} variant="outlined" />}
                  />
                </div>
                <div style={inputStyle} className="selectComponent">
                  <Typography style={headingStyle}>Modifier</Typography>
                  {/* <Select
                    sx={{
                      height: "32px",
                      borderRadius: "8px",
                      width: "250px !important",
                      background: "#FFFFFF",
                    }}
                    value={modifier ? modifier : "select"}
                    onChange={(event) => {
                      handleModifierChange(event?.target?.value);
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    input={<OutlinedInput />}
                    IconComponent={() => <DropdownIcon style={{ width: "20px", position: "relative", left: "-10px" }} />}
                    MenuProps={MenuProps}
                  >
                    <ListSubheader sx={{ background: "#EAEFF8", padding: "4px" }}>
                      <TextField
                        size="small"
                        autoFocus
                        placeholder='Search'
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setModifierSearch(e?.target?.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    <MenuItem disabled value="select">
                      <span style={{ color: "#333333" }}>Select Modifier</span>
                    </MenuItem>
                    {modifiers
                      .filter((option) =>
                        option.toLowerCase().includes(modifierSearch.toLowerCase()))?.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                  </Select> */}

                  <Autocomplete
                    popupIcon={
                      <DropdownIcon style={{ width: "20px", position: "relative", left: "0px" }} />
                    }
                    id="combo-box-demo"
                    options={modifiers}
                    disableClearable={true}
                    value={modifier}
                    onChange={(e, value) => {
                      handleModifierChange(value)
                    }}
                    renderInput={(params) => <TextField className="formControlSelect" placeholder="Select Modifier" {...params} variant="outlined" />}
                  />
                </div>
              </div>

              {noun && (
                <div>
                  <Divider />
                  <div style={inputStyle}>
                    <Typography style={headingStyle}>Description</Typography>
                    <Typography
                      style={{ fontFamily: "Verdana", margin: "10px 0px 10px 0px" }}
                    >
                      Clean Short Description
                    </Typography>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        border: "1px solid #BDBDBD",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <Typography className="shortDesc">{handlechipsValues(chipValues)}</Typography>
                    </div>

                    {modifier && (
                      <>
                        {loading && <p>Loading...</p>}
                        {!loading && (
                          <>
                            <Typography
                              style={{
                                fontFamily: "Verdana",
                                margin: "10px 0px 10px 0px",
                              }}
                            >
                              Clean Long Description
                            </Typography>
                            <div
                              style={{
                                // display: "flex",
                                // flexWrap: "wrap",
                                border: "1px solid #BDBDBD",
                                borderRadius: "8px",
                                padding: "20px",
                              }}
                            >
                              <Typography
                                style={{
                                  marginBottom: "10px",
                                  fontFamily: "Verdana",
                                  background: "#EFEFEF",
                                  padding: "10px",
                                  fontSize: "14px",
                                  borderRadius: "8px",
                                  width: "fit-content"
                                }}
                              >
                                {noun} {modifier}
                              </Typography>
                              <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap"
                              }}>
                                {attributes.map((attribute) => (
                                  <div
                                    key={attribute}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "baseline",
                                      width: "48%",
                                      margin: "0px 10px",
                                    }}
                                  >
                                    <Typography style={headingStyle} sx={{ width: "250px" }}>
                                      {attribute}
                                    </Typography>
                                    <Typography sx={{
                                      margin: uomValues[attribute].length > 0 ? "0px 0px 0px 40px " : null,
                                    }}>
                                      :-
                                    </Typography>
                                    {typeAttributeValue[attribute] === "string" ? (
                                      <>
                                        <Select
                                          displayEmpty
                                          sx={{
                                            height: "32px",
                                            borderRadius: "8px",
                                            width: uomValues[attribute].length > 0 ? "120px" : "200px",
                                            background: "#FFFFFF",
                                            left: uomValues[attribute].length > 0 ? "25px" : "0px",
                                            margin: uomValues[attribute].length > 0 ? "10px 0px 10px 22px " : "10px 30px",
                                          }}
                                          value={attributeValue[attribute] || ""}
                                          onChange={(event) =>
                                            handleAttributeChange(
                                              attribute,
                                              event.target.value,
                                              "select"
                                            )
                                          }
                                          inputProps={{ "aria-label": "Without label" }}
                                          input={<OutlinedInput />}
                                          IconComponent={() => <DropdownIcon style={{ width: "20px", position: "relative", left: "-10px" }} />}
                                          MenuProps={MenuProps}
                                        >
                                          <MenuItem disabled value="">
                                            <span style={{ color: "#333333" }}>
                                              Select
                                            </span>
                                          </MenuItem>
                                          {attributeValues[attribute]?.map((value) => (
                                            <MenuItem key={value} value={value}>
                                              {value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </>
                                    ) : (
                                      <>
                                        <input
                                          placeholder="Enter Value"
                                          name={attribute}
                                          type="text"
                                          onChange={(event) =>
                                            handleAttributeChange(
                                              attribute,
                                              event.target.value,
                                              "nonSelect"
                                            )
                                          }
                                          value={attributeTypeValue[attribute]}
                                          style={{
                                            left: uomValues[attribute].length > 0 ? "10px" : "0px",
                                            margin: uomValues[attribute].length > 0 ? "10px 0px 10px 22px " : "10px 30px",
                                            borderRadius: "8px",
                                            padding: "6px 10px",
                                            margin: "10px 20px 10px 60px",
                                            height: "32px",
                                            width: "120px",
                                            position: "relative",
                                            top: "-2px",
                                            outlineStyle: "none",
                                            border: "1px solid #cbc3c3"
                                          }}
                                        />
                                      </>
                                    )}
                                    {uomValues[attribute].length > 0 && (
                                      <Select
                                        displayEmpty
                                        sx={{
                                          height: "32px",
                                          borderRadius: "8px",
                                          width: "80px",
                                          background: "#FFFFFF",
                                          margin: "10px 0px",
                                          right: "25px"
                                        }}
                                        value={uomValue[attribute] || ""}
                                        onChange={(event) =>
                                          handleUomChange(
                                            attribute,
                                            event.target.value
                                          )
                                        }
                                        inputProps={{ "aria-label": "Without label" }}
                                        input={<OutlinedInput />}
                                        IconComponent={() => <DropdownIcon style={{ width: "20px", position: "relative", left: "-10px" }} />}
                                        MenuProps={uomProps}
                                      >
                                        <MenuItem disabled value="">
                                          <span style={{ color: "#333333" }}>
                                            Select
                                          </span>
                                        </MenuItem>
                                        {uomValues[attribute]?.map((value) => (
                                          <MenuItem key={value} value={value}>
                                            {value}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}

                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              <div style={{ margin: "25px 0px" }}>
                <button
                  type="submit"
                  className="submitButton"
                  style={{
                    fontSize: 16,
                    cursor: "pointer",
                    position: "relative",
                    fontFamily: "Verdana",
                    margin: "0px"
                  }}
                  onClick={(e) => {
                    handleNext();
                  }}
                >
                  Next
                </button>
                <button
                  type="submit"
                  className="cancelButton"
                  style={{
                    fontSize: 16,
                    cursor: "pointer",
                    position: "relative",
                    fontFamily: "Verdana",
                  }}
                  onClick={(e) => {
                    handleReset();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </>
        )}
      </Paper>
    </>
  );
};

export default PartDescription;
