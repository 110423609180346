import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, Divider, Box, Tooltip } from "@mui/material";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";
import { ReactComponent as Setting } from "../../assets/images/Setting.svg";
import { ReactComponent as Date } from "../../assets/images/Date.svg";
import { ReactComponent as User } from "../../assets/images/User.svg";
import { ReactComponent as PartExtension } from "../../assets/images/PartExtension.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import "../../styles/ownerPartRequest.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { automationHttpRequest } from "../../api/httpRequests";

const OwnerPartRequestDialog = ({
  partRequestNumber,
  open,
  dialogData,
  closeDialog,
  dialogLoading,
  dfDetais,
  fetchPartRequestHistory
}) => {
  const [ownerComment, setOwnerComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingReject, setLoadingReject] = React.useState(false);

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    color: "#212F37",
    margin: "0px 10px",
  };

  const headingStyle = {
    fontWeight: "700",
  };

  const handleClose = () => {
    setOwnerComment("")
    closeDialog();
  };
   
  // React.useEffect(()=>{
  //   setOwnerComment("")
  // },[partRequestNumber])

  const handleRejectRequest = async () => {
    setLoadingReject(true);
    try {
      const response = await automationHttpRequest.post(
        "/extension_reject/",
        {
          request_id: partRequestNumber,
          data_owner_extension_comment: ownerComment,
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setLoadingReject(false);
        toast.success(response?.data?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOwnerComment("")
        closeDialog();
        fetchPartRequestHistory()
      }
    } catch (error) {
      setLoadingReject(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const handleApproveRequest = async () => {
    const values = {
      request_id: partRequestNumber,
      data_owner_extension_comment: ownerComment,
    };
    try {
      setLoading(true);
      const response = await automationHttpRequest.post(
        "/extension_update/",
        { ...values },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setLoading(false);
        toast.success(response?.data?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOwnerComment("")
        closeDialog();
        fetchPartRequestHistory()
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleApproveRequest();
          }}
        >
           {loading ? (
              // Render loader or loading state here
              <div>Loading...</div>
            ) : (
              // Render button text
              "Approve"
            )}
        </button>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleRejectRequest();
          }}
        >
           {loadingReject ? (
              // Render loader or loading state here
              <div>Loading...</div>
            ) : (
              // Render button text
              "Reject"
            )}
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Cancel
        </button>
      </>
    );
  };

  const ValueDisplay = ({
    current,
    suggested,
    future,
    justification,
    currentValue,
    suggestedValue,
    futureValue,
    justifyValue,
  }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {current && (
            <Typography className="narrowValue" style={headingStyle}>
              {currentValue}
            </Typography>
          )}
          {suggested && (
            <Typography className="narrowValue" style={headingStyle}>
              {suggestedValue}
            </Typography>
          )}
          {future && (
            <Typography className="narrowValue" style={headingStyle}>
              {futureValue}
            </Typography>
          )}
          {justification && (
            <Typography className="narrowValue" style={headingStyle}>
              {justifyValue.length > 8 ? (
                <Tooltip title={justifyValue} arrow>
                  <span>{justifyValue.slice(0, 8) + "..."}</span>
                </Tooltip>
              ) : (
                justifyValue
              )}
            </Typography>
          )}
        </div>
      </>
    );
  };

  const StatusLevel = ({ current, suggested, Future }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Typography className="narrowValue" style={headingStyle}>
            {current}
          </Typography>
          <Typography className="narrowValue" style={headingStyle}>
            {suggested}
          </Typography>
          <Typography className="narrowValue" style={headingStyle}>
            {Future}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            maxWidth: "1400px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="dialogHeading">
            <PartExtension style={{ height: "60px" }} />
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              Part Extension
            </Typography>
            <Typography
              sx={{
                fontFamily: "Verdana",
                fontSize: "14px",
                fontWeight: "600",
                background: "#EFEFEF",
                padding: "6px",
                borderRadius: "4px",
              }}
            >
              Requested ID-{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "300",
                  marginLeft: "10px",
                }}
              >
                #{partRequestNumber}
              </span>
            </Typography>
          </div>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ width: "1260px", margin: "10px" }}>
          {dialogLoading ? (
            <>
              <Box sx={{ display: "flex" }}>
                <CircularProgress style={{ color: "#C6003D" }} />
              </Box>
            </>
          ) : (
            <DialogContentText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  margin: "20px",
                }}
              >
                <div className="headers">
                  <Setting />
                  <div style={inputStyle}>
                    <Typography>Material Number</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.material_number|| "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <Plant />
                  <div style={inputStyle}>
                    <Typography>Extension Required For Plant</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.plant_name || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <User />
                  <div style={inputStyle}>
                    <Typography>Submitted By</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.submitted_by || "-"}
                    </Typography>
                  </div>
                </div>
                <div className="headers">
                  <Date />
                  <div style={inputStyle}>
                    <Typography>Date of Submission</Typography>
                    <Typography style={headingStyle}>
                      {dialogData?.date_of_submission || "-"}
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider style={{ margin: "20px 0px", background: "#EFEFEF" }} />
              <div
                className="formikExtension"
                style={{ width: "100%", justifyContent: "space-around" }}
              >
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Vendor Number</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.vendor_account}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>
                    Vendor Material Number
                  </Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.supplier_part_number}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Lead Time</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.lead_time_from_pir}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Net Price</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.standerd_price}
                  </Typography>
                </div>
                <div style={inputStyle}>
                  <Typography style={headingStyle}>Order Unit</Typography>
                  <Typography style={{ margin: "4px 0px" }}>
                    {dialogData?.order_unit_from_pir}
                  </Typography>
                </div>
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              <div className="headings">
                <Typography style={{ color: "black", fontWeight: 600, }}>
                  Current Value
                </Typography>
                <Typography style={{ color: "black", fontWeight: 600, }}>
                  Suggested Value
                </Typography>
                <Typography style={{ color: "black", fontWeight: 600, }}>Future Value</Typography>
                <Typography style={{ color: "black", fontWeight: 600, }}>
                  Justification for Requested Changes
                </Typography>
              </div>
              <div className="valueDisplay">
                <Typography className="subHeading">ABC Indicator</Typography>
                <ValueDisplay
                  current={true}
                  suggested={true}
                  future={true}
                  justification={true}
                  currentValue={dialogData?.ABC_indicator}
                  justifyValue={
                    dialogData?.justification_for_change_in_abc_indicator
                      ? dialogData?.justification_for_change_in_abc_indicator
                      : "-"
                  }
                  futureValue={
                    dialogData?.future_abc_indicator
                      ? dialogData?.future_abc_indicator
                      : "-"
                  }
                  suggestedValue={
                    dialogData?.suggested_abc_indicator
                      ? dialogData?.suggested_abc_indicator
                      : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">
                  Criticality Indicator
                </Typography>
                <ValueDisplay
                  current={true}
                  suggested={false}
                  future={false}
                  justification={false}
                  currentValue={dialogData?.critical_part}
                />
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Minimum Stock</Typography>
                <ValueDisplay
                  current={true}
                  suggested={true}
                  future={true}
                  justification={true}
                  currentValue={dialogData?.minimum_stock_level}
                  justifyValue={
                    dialogData?.justification_for_change_in_minimum_stock
                      ? dialogData?.justification_for_change_in_minimum_stock
                      : "-"
                  }
                  futureValue={
                    dialogData?.future_minimum_stock_level
                      ? dialogData?.future_minimum_stock_level
                      : "-"
                  }
                  suggestedValue={
                    dialogData?.suggested_minimum_stock_level
                      ? dialogData?.suggested_minimum_stock_level
                      : "-"
                  }
                />
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Maximum Stock</Typography>
                <ValueDisplay
                  current={true}
                  suggested={true}
                  future={true}
                  justification={true}
                  suggestedValue={
                    dialogData?.suggested_maximum_stock_level
                      ? dialogData?.suggested_maximum_stock_level
                      : "-"
                  }
                  futureValue={
                    dialogData?.future_maximum_stock_level
                      ? dialogData?.future_maximum_stock_level
                      : "-"
                  }
                  justifyValue={
                    dialogData?.justification_for_change_in_maximum_stock
                      ? dialogData?.justification_for_change_in_maximum_stock
                      : "-"
                  }
                  currentValue={dialogData?.maximum_stock_level}
                />
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Storage Location</Typography>
                <ValueDisplay
                  current={true}
                  suggested={false}
                  future={false}
                  justification={false}
                  currentValue={dialogData?.storage_location}
                />
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              <div className="valueDisplay">
                <Typography className="subHeading">Bin</Typography>
                <ValueDisplay
                  current={true}
                  suggested={false}
                  future={false}
                  justification={false}
                  currentValue={dialogData?.storage_bin}
                />
              </div>
              <Divider style={{ background: "#EFEFEF" }} />
              {/* <>
                                <Typography className="subHeading" style={{ margin: "40px 0px 20px 0px " }}>Deletion Flag Status</Typography>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className="subHeading">Sloc Level</Typography>
                                    <StatusLevel
                                        current={dfDetais?.df_storage_location}
                                        suggested="Active"
                                        Future="Active"
                                    />
                                </div>
                                <Divider style={{ background: "#EFEFEF" }} />
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className="subHeading">Plant Level</Typography>
                                    <StatusLevel
                                        current={dfDetais?.df_plant}
                                        suggested="Active"
                                        Future="Active"
                                    />
                                </div>
                                <Divider style={{ background: "#EFEFEF" }} />
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className="subHeading">Client Level</Typography>
                                    <StatusLevel
                                        current={dfDetais?.df_client}
                                        suggested="Active"
                                        Future="Active"
                                    />
                                </div>
                                <Divider style={{ background: "#EFEFEF" }} />
                            </> */}
              <div>
                <Typography className="equipmentNumber">
                  Equipment Number(s) For BOM
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                  >
                    <Typography>
                      {dialogData?.equipment_number_for_bom}
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "#212F37",
                }}
              >
                <Typography style={headingStyle}>Comments</Typography>
                <textarea
                  id="yourTextarea"
                  name="yourTextarea"
                  placeholder="Enter Comments"
                  value={ownerComment}
                  style={{ width: "50%" }}
                  onChange={(e) => setOwnerComment(e?.target?.value)}
                  className="partTextField"
                  rows={4}
                  cols={50}
                />
              </div>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-start !important",
          }}
        >
          <ButtonFamily btnText="Approve" />
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default OwnerPartRequestDialog;
