import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Paper, Divider } from "@mui/material";
import { ReactComponent as CloseIcon } from '../../assets/images/CloseIcon.svg';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {automationHttpRequest} from "../../api/httpRequests";

const CheckPartDialog = ({ checkDialog, setCheckDialog, checkTableData, loading, handleContinue, setExtension, setExtensionData}) => {

    const handleClose = () => {
        setCheckDialog(false)
    };

    // console.log(checkTableData)
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: "#EAF0FD",
            color: "#000000",
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: "Verdana",
            // border: "1px solid #BDBDBD", // Add border style
            // borderBottom: "2px solid #BDBDBD", // Add bottom border style
            padding: "32px 20px",
            position: "sticky",
            top: 0,
            zIndex: 2,
            minWidth: "150px",
            textAlign: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            position: "sticky",
            top: 0,
            zIndex: 2,
            // border: "1px solid #BDBDBD", // Add border style
            // borderBottom: "2px solid #BDBDBD", // Add bottom border style
        },
    }));

    const StyledTable = styled(Table)({
        border: "1px solid #BDBDBD",
    });

    const filteredColumns = [
        "material_number",
        "description",
        "vendor_name",
        "material_group",
        "plant_name",
        "storage_location",
        "select"
    ];

    const tableColumnNames = (camelCase) => {
        return camelCase
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    return (
        <>
            <Dialog
                open={checkDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        maxWidth: "1400px",
                        borderRadius: "10px"
                    }
                }}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px" }}>
                    <Typography sx={{ fontFamily: "Verdana", fontSize: "24px", fontWeight: "700", lineHeight: "21px" }}>
                        We found below details of this Part</Typography>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                </DialogTitle>
                <DialogContent sx={{ width: loading ? "1000px" : "1260px" }}>
                    <DialogContentText id="alert-dialog-description">
                        {loading ? (
                            <>
                                <Typography>loading...</Typography>
                            </>
                        ) : (
                            <>
                                {checkTableData && checkTableData.length > 0 ? (
                                    <>
                                        <Paper sx={{ width: "100%", mb: 2, }} elevation={2}>
                                            <TableContainer
                                                sx={{
                                                    maxHeight: "600px",
                                                    overflowY: "auto",
                                                    // borderBottom: "1px solid #BDBDBD",
                                                }}
                                            >
                                                <StyledTable
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {filteredColumns.map((columnName) => (
                                                                <StyledTableCell
                                                                    key={columnName}
                                                                    align="left"
                                                                    padding="normal"
                                                                    sx={{
                                                                        backgroundColor: "#FFFFFF !important",
                                                                        position: "sticky",
                                                                        top: 0,
                                                                        zIndex: 3,
                                                                    }}
                                                                >
                                                                    {columnName}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {checkTableData.map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={row[filteredColumns[0]]}
                                                                >
                                                                    {filteredColumns.map((columnName) => (
                                                                        <>
                                                                            {columnName !== "select" ? (<TableCell
                                                                                key={columnName}
                                                                                align="left"
                                                                                sx={{
                                                                                    fontFamily: "Verdana",
                                                                                    textAlign: "center",
                                                                                    borderBottom: "none"
                                                                                }}
                                                                            >
                                                                                {row[columnName]}
                                                                            </TableCell>
                                                                            ) : (
                                                                                <TableCell
                                                                                    key={columnName}
                                                                                    align="left"
                                                                                    sx={{
                                                                                        fontFamily: "Verdana",
                                                                                        textAlign: "center",
                                                                                        borderBottom: "none"
                                                                                    }}
                                                                                >
                                                                                    <button className='extendPart' onClick={() => {
                                                                                        setExtension(true)
                                                                                        setExtensionData({
                                                                                            materialNumber: row["material_number"],
                                                                                            uid: row["Unique_ID"]
                                                                                        })
                                                                                    }}>
                                                                                        Extend Part
                                                                                    </button>
                                                                                </TableCell>
                                                                            )}

                                                                        </>
                                                                    ))}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </StyledTable>
                                            </TableContainer>
                                        </Paper>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                // backgroundColor: "#EAF0FD",
                                                fontFamily: "Verdana",
                                                border: "1px solid #BDBDBD",
                                                padding: "32px 20px",
                                                textAlign: "center",
                                                color: "#2D3748",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {" "}
                                            No Data{" "}
                                        </Typography>
                                    </>
                                )}
                            </>
                        )}

                    </DialogContentText>
                </DialogContent>
                {!loading && <DialogActions style={{margin:"auto"}}>
                    <button onClick={() => {
                        handleClose()
                        handleContinue()
                    }} className='submitButton' style={{fontSize: "18px", fontWeight: "700", cursor: "pointer"}}>
                        Do you want to continue with part Creation?
                    </button>
                </DialogActions>}
            </Dialog>
        </>
    );
}

export default CheckPartDialog;