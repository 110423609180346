import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Typography, Paper, Divider } from "@mui/material";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PirData from "./PirData";
import '../../styles/partCreation.css'
import { ReactComponent as ManagerApproval } from '../../assets/images/ManagerApproval.svg';
import { ReactComponent as Arrowleft } from '../../assets/images/Arrowleft.svg';
import { automationHttpRequest } from "../../api/httpRequests";
import { httpRequest } from "../../api/httpRequests";

const Substitution = ({ setPirValue, handleContinue, searchString, setSubstitution_status }) => {

    const [showPirData, setShowPirData] = useState(false);
    const [approve, setApprove] = useState(false);
    const [checkTableData, setCheckTableData] = useState([]);
    const [maxRows, setMaxRows] = useState(0);
    const [columnValues, setColumnValues] = useState();
    const [loading, setLoading] = useState(false);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            color: "#000000",
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: "Verdana",
            padding: "32px 20px",
            position: "sticky",
            top: 0,
            zIndex: 2,
            minWidth: "150px",
            textAlign: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            position: "sticky",
            top: 0,
            zIndex: 2
        },
    }));

    const StyledTable = styled(Table)({
        // border: "1px solid #BDBDBD",
    });

    const filteredColumns = [
        "Material Number",
        "Description",
        "Vendor Name",
        "Material Group",
        "Plant Name",
        "Storage Location",
        "Select"
    ];

    useEffect(() => {
        fetchSubstitutionData();
    }, [])

    const fetchSubstitutionData = async () => {
        setLoading(true);
        // const value = "bearing,ball,deep groove,20x42x12";
        try {
            const response = await httpRequest.post(
                "/search",
                {},
                {
                    headers: {
                        session_id: localStorage.getItem("session_id"),
                    },
                    params: {
                        search_str: searchString && searchString.trim(),
                        limit: 10,
                        page: 1,
                        reset_filter: false,
                    },
                }
            );
            if (response?.data?.success) {
                const column_values = Object.keys(response?.data?.table);
                const table_data = handleTableData(response?.data?.table);
                setColumnValues(column_values);
                setMaxRows(response?.data?.max_rows)
                setLoading(false);
                setCheckTableData(table_data);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error:", error.message);
        }
    }

    const handleTableData = (value) => {
        const result = Object.keys(value[Object.keys(value)[0]]).map((i) =>
            Object.keys(value).reduce((obj, key) => {
                obj[key] = value[key][i];
                return obj;
            }, {})
        );

        return result;
    };

    const ButtonFamily = ({ btnText }) => {
        return (
            <>
                {/* <button
                    type="submit"
                    className="submitButton"
                    disabled={!maxRows}
                    style={{
                        fontSize: 16,
                        cursor: maxRows ? "pointer" : null,
                        position: "relative",
                        fontFamily: "Verdana",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        setApprove(true)
                        handleSubmit();
                    }}
                >
                    {btnText}
                </button> */}
                <button
                    type="submit"
                    className="cancelButton"
                    style={{
                        fontSize: 16,
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: "Verdana",
                    }}

                    onClick={(e) => {
                        e.preventDefault();
                        setShowPirData(true);
                    }}
                >
                    Next
                </button>
            </>
        )
    }

    const handleBack = () => {
        setApprove(false)
        setShowPirData(false)
    }

    const handleSubmit = () => {
        setShowPirData(false);
    }

    function highlightSubstring(text) {
        return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    return (
        <>
            <Paper sx={{
                width: "100%",
                margin: "20px 0px",
                padding: "20px",
                borderRadius: "18px"
            }} elevation={2}>
                {(!showPirData && !approve) ? (
                    <Typography className="substituionName">Substitution Part</Typography>
                ) : (!approve && (
                    <Typography className="pirdata">Purchasing Info Record (PIR) Data </Typography>
                ))}
                <Divider style={{
                    margin: "10px"
                }} />
                {loading ? (
                    <><Typography>Loading...</Typography></>
                ) : (
                    <> {(!showPirData && !approve) ? (
                        <>
                            {(checkTableData && checkTableData.length > 0 && maxRows) ? (
                                <>
                                    <Paper sx={{ width: "100%", mb: 2, }} elevation={2}>
                                        <TableContainer
                                            sx={{
                                                maxHeight: "600px",
                                                overflowY: "auto",
                                                // borderBottom: "1px solid #BDBDBD",
                                            }}
                                        >
                                            <StyledTable
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {filteredColumns.map((columnName) => (
                                                            <StyledTableCell
                                                                key={columnName}
                                                                align="left"
                                                                padding="normal"
                                                                sx={{
                                                                    // backgroundColor: "#EAF0FD !important",
                                                                    position: "sticky",
                                                                    top: 0,
                                                                    zIndex: 3,
                                                                }}
                                                            >
                                                                {columnName}
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {checkTableData.map((row, index) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row[filteredColumns[0]]}
                                                            >
                                                                {filteredColumns.map((columnName) => (
                                                                    <>
                                                                        {columnName !== "Select" ? (<TableCell
                                                                            key={columnName}
                                                                            align="left"
                                                                            sx={{
                                                                                fontFamily: "Verdana",
                                                                                textAlign: "center",
                                                                                borderBottom: "none"
                                                                            }}
                                                                        >
                                                                            {highlightSubstring(row[columnName])}
                                                                        </TableCell>
                                                                        ) : (
                                                                            <TableCell
                                                                                key={columnName}
                                                                                align="left"
                                                                                sx={{
                                                                                    fontFamily: "Verdana",
                                                                                    textAlign: "center",
                                                                                    borderBottom: "none"
                                                                                }}
                                                                            >
                                                                                <button className="buttonCancel">
                                                                                    <FormControl>
                                                                                        <FormControlLabel value="select" control={<Radio />} label="Selected" />
                                                                                    </FormControl>
                                                                                </button>
                                                                            </TableCell>
                                                                        )}

                                                                    </>
                                                                ))}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </StyledTable>
                                        </TableContainer>
                                    </Paper>
                                </>
                            ) : (
                                <>
                                    <Typography
                                        sx={{
                                            backgroundColor: "#EAF0FD",
                                            fontFamily: "Verdana",
                                            // border: "1px solid #BDBDBD",
                                            padding: "32px 20px",
                                            textAlign: "center",
                                            color: "#2D3748",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {" "}
                                        Substitution is not available{" "}
                                    </Typography>
                                </>
                            )}
                            <div style={{ margin: "25px" }}>
                                <ButtonFamily btnText="Send to Maintenance Manager" />
                            </div>
                        </>
                    ) : (
                        !approve && (
                            <PirData setShowPirData={setShowPirData} setPirValue={setPirValue} handleContinue={handleContinue} />)
                    )}
                        {approve && (<>
                            <div style={{
                                margin: "auto",
                                width: "40%"
                            }}>
                                <div>
                                    <ManagerApproval />
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}>
                                    <Typography className="substitution" >Request Is <span className="sentApproval">Sent To Maintenance Manager</span></Typography>
                                    <Typography className="substitution" >For Substitution Part</Typography>
                                    <button onClick={handleBack} className="buttonBack">
                                        <Arrowleft /> Back
                                    </button>
                                </div>
                            </div>
                        </>)}

                    </>
                )}

            </Paper>

        </>
    )
}
export default Substitution;