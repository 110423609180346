import { Box, Typography } from "@mui/material";
import React from "react";
import TableComponent from "./TableComponent";

const SearchResult = () => {


    return (
        <>
            <Box margin={2} paddingRight={2}>
                <Typography sx={{ color: "#2D3748", fontFamily: "Verdana", fontSize: "14px", fontWeight: "400" }}>About 25 results -for <span style={{ color: "#D11F3F" }}> Gear Parts are available in the plant?</span></Typography>
                <Box>
                    <Typography sx={{ color: "#2D3748", fontFamily: "Verdana", fontSize: "18px", fontWeight: "700" }} mt={3} mb={3}> Part Found in Plant</Typography>
                   
                </Box>
            </Box>
        </>
    )
}

export default SearchResult;