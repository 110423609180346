import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import BG from "../../assets/images/BG.png";
import "../../styles/login.css";
import Logo from "../../assets/images/Logo.png";
import { Typography } from "@mui/material";
import { httpRequest } from "../../api/httpRequests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as MicrosoftIcon } from "../../assets/images/MicrosoftIcon.svg";

const Login = ({ handleRedirect }) => {

  const [loggedIn, setLoggedIn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const backgroundStyle = {
    backgroundImage: `url(${BG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "100vh", // 100% of the viewport height
    width: "100vw", // 100% of the viewport width
    position: "fixed", // Fixed position to cover the entire viewport
    top: 0,
    left: 0,
  };

  const loginSectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "30vh", // Adjusted height (e.g., 30% of the viewport height)
    border: "0.5px solid #cccccc82", // Border style
    borderRadius: "8px", // Add border radius
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow at the bottom
    width: "30%", // Adjusted width (e.g., 30% of the viewport width)
    position: "absolute",
    top: "50%", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Centering trick
    padding: "20px", // Add some padding for better appearance
    backgroundColor: "white"
  };

  const handleClick = async () => {
    localStorage.removeItem("session_id");
    localStorage.removeItem("MRO_user_name");
    localStorage.removeItem("MRO_user_location");
    localStorage.removeItem("MRO_user_isSignedIn");
    handleRedirect();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div style={{ padding: "30px 0px 0px 30px" }}>
          <img
            src={Logo}
            alt="logo"
            style={{ width: "120px", height: "auto" }}
          />
        </div>
        <div style={loginSectionStyle}>
          <Typography
            sx={{
              m: 2,
              fontSize: "22px",
              fontFamily: "Verdana",
              fontWeight: 700,
              color: "black",
            }}
          >
            Sign in using Rich's Account ID
          </Typography>

          <button
            type="submit"
            className="loginButton"
            style={{
              fontSize: 16,
              cursor: "pointer",
              fontWeight: 600,
              backgroundColor: isButtonClicked, // Button in red
              position: "relative",
              fontFamily: "Verdana",
              marginTop: "20px", // Add some top margin if needed
              marginLeft: "30%", // Center horizontally
              transform: "translateX(-50%)", // Centering trick
              
            }}
            disabled={isButtonClicked}
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            {isLoading && (
              <div
                className="loader"
                style={{ borderTop: "2px dotted white" }}
              ></div>
            )}
            {!isLoading && "Sign In"}
          </button>
        </div>
        <ToastContainer />
        {/* <div>
          <Typography
            sx={{
              m: 1,
              color: "#666666",
              fontSize: "12px",
              fontFamily: "Verdana",
              fontWeight: 300,
              position: "relative",
              left: "200px",
              top: "180px",
            }}
          >
            Are you an agency that needs access?
          </Typography>
          <Typography
            sx={{
              m: 1,
              color: "#428BCA",
              fontSize: "12px",
              fontFamily: "Verdana",
              fontWeight: 300,
              position: "relative",
              left: "200px",
              top: "180px",
            }}
          >
            Please request access here
          </Typography>
        </div> */}
      </div>
    </>
  );
};

export default Login;
