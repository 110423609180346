import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContent from "@mui/material/DialogContent";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import { Divider, Typography } from "@mui/material";
import BG from "../../assets/images/Feeback_background.png";
import "../../styles/feedbackDialog.css";
import { styled } from "@mui/material/styles";

const RedBorderTextField = styled(TextField)`
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px; /* Adjust the border width as needed */
  }
`;

const FeedbackDialog = ({
  handleUserFeedbackData,
  open,
  positiveFeedback,
  handleFeedBackDialogOpen,
}) => {
  const [selectedValue, setSelectedValue] = useState(""); // Assuming you're using radio buttons
  const [searchType, setSearchType] = useState("");
  const [feedbackComments, setFeedbackComments] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };


  useEffect(() => {
    if (!open) {
      setSelectedValue("");
      setSearchType("");
      setFeedbackComments("");
    }
  }, [open]);

  const handleClose = () => {
    handleFeedBackDialogOpen();
  };

  const handleSkip = () => {
    const userFeedback = {
        feedback_positive: positiveFeedback.toString(),
        user_comment: "",
        categories: ""
    }
    handleUserFeedbackData(userFeedback)
    handleFeedBackDialogOpen(false)
  };

  const backgroundStyle = {
    backgroundSize: "100% 100%", // Adjust as needed
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100%", // Make sure the width is set to 100%
    height: "100%", // Make sure the height is set to 100%
    borderRadius: "50px",
    border: "0px",
    marginTop: "5px",
    padding: "40px",
  };

  const handleSubmit = () => {
    const userFeedback = {
        feedback_positive: positiveFeedback.toString(),
        user_comment: feedbackComments.trim(),
        categories: searchType
    }
    handleUserFeedbackData(userFeedback)
    handleFeedBackDialogOpen(false)
  };

  const handleCommentsChange = (event) => {
    setFeedbackComments(event.target.value);
  };

  const handleSearchTypeChange = (event) => {
    setSelectedValue(event.target.value);
    setSearchType(event.target.value); // Update searchType with the selected value
  };

  return (
    <>
      {positiveFeedback ? <Dialog
        open={open}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleClose();
          }
        }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            backgroundImage: `url(${BG})`,
            maxHeight: "90vh",
            minHeight: "30vh",
            maxWidth: "105vh",
            minWidth: "45vh",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent style={backgroundStyle}>
          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontWeight: 500,
              backgroundPosition: "left",
              width: "70%", // Set the width to 80% of the container
              position: "relative",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            We'd really like to hear any feedback that you might have for us!
          </Typography>
          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontWeight: 400,
              fontSize: "14px",
              backgroundPosition: "left",
              width: "70%", // Set the width to 80% of the container
              position: "relative",
              marginBottom: "15px",
            }}
          >
            This will help us improve our app and make it a better experience
            for you.
          </Typography>

          {/* Form Field */}
          <RedBorderTextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            InputProps={{
              sx: {
                fontFamily: "Verdana", // Apply Verdana font
              },
            }}
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              width: "80%",
              marginBottom: "10px",
              display: "block", 
            }}
            value={feedbackComments} // Set the value to the state value
            onChange={handleCommentsChange} // Handle changes
          />

          <button
            onClick={handleSubmit}
            style={{
              background: false ? "rgb(235 126 146)" : "#D11F3F",
              borderRadius: "30px",
              textTransform: "capitalize",
              border: "0px",
              cursor: "pointer",
              zIndex: 1000,
              color: "#fff",
              margin: "20px 20px 25px 0",
            }}
          >
            <Typography
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "16px",
                fontWeight: "400",
                color: "#fff",
                width: "100px", // Adjusted width
                border: "0px",
                lineHeight: 2.5,
              }}
            >
              Submit
            </Typography>
          </button>
          <button
            onClick={handleSkip}
            style={{
              background: "#fff",
              borderRadius: "30px",
              textTransform: "capitalize",
              border: "0px",
              cursor: "pointer",
              zIndex: 1000,
              color: "#fff",
              margin: "20px 20px 25px 0",
            }}
          >
            <Typography
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "16px",
                fontWeight: "400",
                color: "#D11F3F",
                width: "100px", // Adjusted width
                border: "0px",
                lineHeight: 2.5,
              }}
            >
              Skip
            </Typography>
          </button>
        </DialogContent>
      </Dialog> : <Dialog
        open={open}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleClose();
          }
        }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            backgroundImage: `url(${BG})`,
            maxHeight: "100vh",
            minHeight: "30vh",
            maxWidth: "105vh",
            minWidth: "45vh",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent style={backgroundStyle}>
          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontWeight: 500,
              backgroundPosition: "left",
              width: "70%", // Set the width to 80% of the container
              position: "relative",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            We'd really like to hear any feedback that you might have for us!
          </Typography>
          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontWeight: 400,
              fontSize: "14px",
              backgroundPosition: "left",
              width: "70%", // Set the width to 80% of the container
              position: "relative",
              marginBottom: "15px",
            }}
          >
            This will help us improve our app and make it a better experience
            for you.
          </Typography>
          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontWeight: 600,
              backgroundPosition: "left",
              width: "70%", // Set the width to 80% of the container
              position: "relative",
              marginBottom: "15px",
            }}
          >
            What not worked well?{" "}
            <Typography
              component="span"
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "13px",
                fontWeight: 400,
              }}
            >
              (Optional)
            </Typography>
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              aria-label="feedback"
              name="feedback"
              value={selectedValue}
              onChange={handleSearchTypeChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value="Basic Search"
                control={<Radio sx={{ color: "#2D3748" }} />}
                label={
                  <Typography sx={{ fontFamily: "Verdana", fontWeight: 500 }}>
                    Basic Search
                  </Typography>
                }
                style={{ marginRight: "20px", marginBottom: "10px" }}
              />
              <FormControlLabel
                value="Filtered Search"
                control={<Radio sx={{ color: "#2D3748" }} />}
                label={
                  <Typography sx={{ fontFamily: "Verdana", fontWeight: 500 }}>
                    Filtered Search
                  </Typography>
                }
                style={{ marginRight: "20px", marginBottom: "10px" }}
              />
            </RadioGroup>
          </FormControl>

          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontWeight: 600,
              backgroundPosition: "left",
              width: "90%", // Set the width to 80% of the container
              position: "relative",
              marginTop: "10px",
              marginBottom: "25px",
            }}
          >
            Do you have any specific comments?{" "}
            <Typography
              component="span"
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "13px",
                fontWeight: 400,
                marginBottom: "10px",
              }}
            >
              (Optional)
            </Typography>
          </Typography>

          {/* Form Field */}
          <RedBorderTextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            InputProps={{
              sx: {
                fontFamily: "Verdana", // Apply Verdana font
              },
            }}
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              width: "80%",
              marginBottom: "10px",
              display: "block", 
            }}
            value={feedbackComments} // Set the value to the state value
            onChange={handleCommentsChange} // Handle changes
          />

          <button
            onClick={handleSubmit}
            style={{
              background: false ? "rgb(235 126 146)" : "#D11F3F",
              borderRadius: "30px",
              textTransform: "capitalize",
              border: "0px",
              cursor: "pointer",
              zIndex: 1000,
              color: "#fff",
              margin: "20px 20px 25px 0",
            }}
          >
            <Typography
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "16px",
                fontWeight: "400",
                color: "#fff",
                width: "100px", // Adjusted width
                border: "0px",
                lineHeight: 2.5,
              }}
            >
              Submit
            </Typography>
          </button>
          <button
            onClick={handleSkip}
            style={{
              background: "#fff",
              borderRadius: "30px",
              textTransform: "capitalize",
              border: "0px",
              cursor: "pointer",
              zIndex: 1000,
              color: "#fff",
              margin: "20px 20px 25px 0",
            }}
          >
            <Typography
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "16px",
                fontWeight: "400",
                color: "#D11F3F",
                width: "100px", // Adjusted width
                border: "0px",
                lineHeight: 2.5,
              }}
            >
              Skip
            </Typography>
          </button>
        </DialogContent>
      </Dialog>}
    </>
  );
};

export default FeedbackDialog;
