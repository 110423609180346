import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Paper, Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ReactComponent as CloseIcon } from '../../assets/images/CloseIcon.svg';
import { ReactComponent as Successfull } from '../../assets/images/Successfull.svg';

const MassApproveRejectDialog = ({ open, dialogLoading, closeDialog, selectMembers }) => {

    const handleClose = () => {
        closeDialog()
    };


    const ButtonFamily = ({ btnText }) => {
        return (
            <>
                <button
                    type="submit"
                    className="submitButton"
                    style={{
                        fontSize: 16,
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: "Verdana",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose()
                    }}
                >
                    {btnText}
                </button>
            </>
        )
    }

    const filteredColumns = {
        "Request ID": "request_id",
        "Material Number": "material_number",
        "Date of Submission": "date_of_submission",
        "Request Type": "request_type",
        "Submitted by": "submitted_by",
        "Requested for plant": "plant_name"
    };

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        maxWidth: "1400px",
                        borderRadius: "10px"
                    }
                }}
            >
                <DialogTitle sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
                >
                    <div className='dialogHeading'>
                      <Successfull style={{ height: "60px" }} onClick={handleClose} />
                      <Typography sx={{ fontFamily: "Verdana", fontSize: "24px", fontWeight: "400" }}>Successfully approved {selectMembers?.length} part requests.</Typography>
                    </div>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                </DialogTitle>
                <DialogContent sx={{ width: "1260px", margin: '10px' }}>
                    {dialogLoading ? (
                        <>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{ color: '#C6003D' }} />
                            </Box>
                        </>
                    ) :
                        <DialogContentText>
                            <Paper elevation={2}>
                                <TableContainer
                                    sx={{
                                        maxHeight: "600px",
                                        overflowY: "auto",
                                        borderBottom: "1px solid #BDBDBD",
                                    }}
                                >
                                    <Table
                                        sx={{ minWidth: 750, border: "1px solid #BDBDBD" }}
                                        aria-labelledby="tableTitle"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {Object.keys(filteredColumns).map((columnName, index) => (
                                                    <TableCell
                                                        key={columnName}
                                                        align="center"
                                                        padding="normal"
                                                        sx={{
                                                            // backgroundColor: "#EAF0FD !important",
                                                            position: "sticky",
                                                            top: 0,
                                                            // zIndex: 3,
                                                            // borderRight:
                                                            //     index < Object.keys(filteredColumns).length - 1
                                                            //         ? "1px solid #BDBDBD"
                                                            //         : "none",
                                                        }}
                                                        className={
                                                            columnName === "Request ID" ? "fixed-column" : null
                                                        }
                                                    >
                                                        {columnName === "Date of Submission"
                                                            ? "Date Submitted"
                                                            : columnName}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {selectMembers?.map((row, index) => (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row["Request ID"]}
                                                >
                                                    {Object.values(filteredColumns).map(
                                                        (columnName, columnIndex) => (
                                                            <TableCell
                                                                key={columnName}
                                                                align="center"
                                                                sx={{
                                                                    // border: "1px solid #BDBDBD",
                                                                    fontFamily: "Verdana",
                                                                    textAlign: "center",
                                                                    // borderRight:
                                                                    //     columnIndex <
                                                                    //         Object.values(filteredColumns).length - 1
                                                                    //         ? "1px solid #BDBDBD"
                                                                    //         : "none", // Add border-right
                                                                }}
                                                                className={
                                                                    columnName === "request_id"
                                                                        ? "fixed-column"
                                                                        : null
                                                                }
                                                            >
                                                                   { row[columnName]}
                                                            </TableCell>
                                                        )
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </DialogContentText>}
                </DialogContent>
                <DialogActions sx={{
                    justifyContent: "flex-start !important"
                }}>
                    <ButtonFamily btnText="Close" />
                </DialogActions>
            </Dialog>
        </>
    );
}


export default MassApproveRejectDialog;