import React, { useEffect, useState } from "react";
import { Typography, Paper, Tooltip, Divider, IconButton } from "@mui/material";
import { ReactComponent as Rotate } from "../../assets/images/Rotate.svg";
import "../../styles/partCreation.css";
import { ReactComponent as DropdownIcon } from "../../assets/images/DropdownIcon.svg";
import { Select, MenuItem } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ReactComponent as Info } from "../../assets/images/Info.svg";
import { ReactComponent as MatrixCriteria } from "../../assets/images/MatrixCriteria.svg";
import axios from "axios";
import { automationHttpRequest } from "../../api/httpRequests";

const DecisionMatrix = ({ setDecisionData, handleContinue }) => {
  const [impact, setImpact] = useState("");
  const [loading, setLoading] = useState(false);
  const [probability, setProbability] = useState("");
  const [empty, setEmpty] = useState(false);
  const [stockValue, setStockValue] = useState("-");
  const [ownerComment, setOwnerComment] = React.useState("");

  useEffect(() => {
    if (impact) {
      setDecisionData((prev) => ({
        ...prev,
        impact_score: impact,
      }));
    }
  }, [impact]);

  useEffect(() => {
    if (probability) {
      setDecisionData((prev) => ({
        ...prev,
        probability_score: probability,
      }));
    }
  }, [probability]);

  useEffect(() => {
    if (ownerComment) {
      setDecisionData((prev) => ({
        ...prev,
        stock_decision_comment: "",
      }));
    }
  }, [ownerComment]);

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  };

  const headingStyle = {
    fontWeight: "700",
  };

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };

  const showInformation = () => {
    return (
      <>
        <div
          style={{
            height: "540px",
            width: "500px",
            position: "relative",
            top: "-20px",
            left: "-56px",
          }}
        >
          <MatrixCriteria />
        </div>
      </>
    );
  };

  const fetchPartCheckResult = async (val) => {
    setLoading(true);
    if (impact) {
      try {
        const response = await automationHttpRequest.get(
          `/stock_decision/${impact}/${val}/`,
          // {},
          {
            headers: {
              session_id: localStorage.getItem("session_id"),
              userEmail: localStorage.getItem("MRO_user_email"),
            },
            params: {},
          }
        );
        if (response?.data) {
          setDecisionData((prev) => ({
            ...prev,
            stock_decision: response?.data?.stock_decision,
          }));
          if (response?.data?.stock_decision !== "Non-Stock") {
            setDecisionData((prev) => ({
              ...prev,
              stock_decision_comment: "",
            }));
          }
          setLoading(false);
          setEmpty(false);
          setStockValue(response?.data?.stock_decision);
        }
      } catch (error) {}
    } else {
      if (!impact) {
        setEmpty(true);
      }
    }
  };

  const ButtonFamily = ({ btnText }) => {
    return (
      <>
        <button
          type="submit"
          className="submitButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleContinue();
          }}
        >
          {btnText}
        </button>
        <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}
          onClick={(e) => {
            e.preventDefault();
            setImpact("");
            setProbability("");
          }}
        >
          Reset
        </button>
        {/* <button
          type="submit"
          className="cancelButton"
          style={{
            fontSize: 16,
            cursor: "pointer",
            position: "relative",
            fontFamily: "Verdana",
          }}

          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Send for Maintenance Manager Approval
        </button> */}
      </>
    );
  };

  const options = ["1", "2", "3", "4", "5"];

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          margin: "20px 0px",
          paddingBottom: "40px",
          borderRadius: "18px",
        }}
        elevation={2}
      >
        <div>
          <Typography
            sx={{
              fontFamily: "Verdana",
              fontSize: "24px",
              fontWeight: 400,
              padding: "30px",
            }}
          >
            Decision Matrix
          </Typography>
          <Divider />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 30px",
            }}
          >
            <Typography>Decision Matrix Criteria</Typography>
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="top-end"
              title={showInformation()}
            >
              <IconButton>
                <Info />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          className="checkDecisionPart"
          style={{
            padding: "0px 20px",
            width: "52%"
          }}
        >
          <div style={inputStyle}>
            <Typography style={headingStyle}>Impact</Typography>
            <Select
              sx={{
                height: "32px",
                borderRadius: "8px",
                width: "250px !important",
                background: "#FFFFFF",
              }}
              value={impact ? impact : "Select"}
              onChange={(event) => {
                setImpact(event?.target?.value);
                setEmpty(false);
              }}
              inputProps={{ "aria-label": "Without label" }}
              input={<OutlinedInput />}
              IconComponent={() => (
                <DropdownIcon
                  style={{ width: "20px", position: "relative", left: "-10px" }}
                />
              )}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="Select">
                <span style={{ color: "#333333" }}>Select Impact</span>
              </MenuItem>
              {options?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {empty && (
              <span style={{ color: "#C6003D" }}>Please Select Impact!!</span>
            )}
          </div>
          <div style={inputStyle}>
            <Typography style={headingStyle}>Probability</Typography>
            <Select
              displayEmpty
              sx={{
                height: "32px",
                borderRadius: "8px",
                width: "250px !important",
                background: "#FFFFFF",
              }}
              value={probability ? probability : "Select"}
              onChange={(event) => {
                setProbability(event?.target?.value);
                fetchPartCheckResult(event?.target?.value);
              }}
              inputProps={{ "aria-label": "Without label" }}
              input={<OutlinedInput />}
              IconComponent={() => (
                <DropdownIcon
                  style={{ width: "20px", position: "relative", left: "-10px" }}
                />
              )}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="Select">
                <span style={{ color: "#333333" }}>Select Probability</span>
              </MenuItem>
              {options?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div>
            <Typography style={headingStyle}>Stock Decision</Typography>
            <Typography className="checkbutton" style={{ top: "0px", width: stockValue === "Manager Decision" ? "150px" :"100px" }}>
              {stockValue}
            </Typography>
          </div>
        </div>
        {stockValue === "Non-Stock" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#212F37",
              margin: "20px 30px",
            }}
          >
            <Typography style={headingStyle}>Comments</Typography>
            <textarea
              id="yourTextarea"
              name="yourTextarea"
              placeholder="Enter Comments"
              value={ownerComment}
              style={{ width: "50%" }}
              onChange={(e) => setOwnerComment(e?.target?.value)}
              className="partTextField"
              rows={4}
              cols={50}
            />
          </div>
        )}
        <div style={{ margin: "25px" }}>
          <ButtonFamily btnText="Next" />
        </div>
      </Paper>
    </>
  );
};

export default DecisionMatrix;
