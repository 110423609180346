import React, { useState } from "react";

import {
  Box,
  Typography,
  Button,
  Divider,
  MenuItem,
  Select,
  Input,
  InputAdornment,
  Paper,
  IconButton,
  Tooltip
} from "@mui/material";
import {httpRequest} from "../../api/httpRequests";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchSvgIcon.svg";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { ReactComponent as ClearIcon } from '../../assets/images/clearIcon.svg';
import { ReactComponent as BeforeSearch } from '../../assets/images/BeforeSearch.svg';
import { ReactComponent as AfterSearch } from '../../assets/images/AfterSearch.svg';

export default function Question({
  searchValue,
  handleSearchAgainFunction,
  handleSearchBool,
  filterDisable,
  sqlDescription
}) {
  const navigate = useNavigate();
  const [search_value, setSearch_value] = useState(searchValue);
  const [searchAgain, setSearchAgain] = useState(filterDisable);
  const [searchSuggestion, setSearchSuggestion] = useState([]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (search_value.length > 0) {
      setSearchAgain(false);
      handleSearchAgainFunction(search_value);
      setSearchSuggestion([]);
    }
  };

  const fetchData = async (searchValue) => {
    try {
      const response = await httpRequest.post(
        "/search_suggestion",
        {
          // Request body (if any)
          // ...
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
          },
          params: {
            search_str: searchValue,
          },
        }
      );
      setSearchSuggestion(response.data.suggestions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearch_value(suggestion);
    setSearchSuggestion([]);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.charAt(inputValue.length - 1) === " ") {
      fetchData(inputValue.trim());
    }

    setSearch_value(inputValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && search_value.length > 0) {
      setSearchAgain(false);
      handleSearchAgainFunction(search_value);
      setSearchSuggestion([]);
    } else if (event.key === "Escape") {
      setSearchSuggestion([]);
    }
  };

  const handleSearchAgain = (e) => {
    e.preventDefault();
    handleSearchBool();
    setSearch_value("");
    setSearchAgain(true);
  };

  const handleClearSearch = () => {
    setSearch_value("");
    setSearchSuggestion([]);
  };

  const ShowDescription = () => {
    return (
      <>
        <Paper elevation={2} style={{
          background: "#FFFFFF",
          display: "flex",
          width: sqlDescription?.length > 50 ? "500px": "200px",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px 20px",
          margin: "-8px"
        }}>
          <AfterSearch style={{ margin: "10px",  width: sqlDescription?.length > 50 ? "50px": "30px", height: sqlDescription?.length > 50 ? "50px" : "30px" }} />
          <Typography sx={{ fontFamily: "Verdana", color: "rgb(209, 31, 63)", }}>
            {sqlDescription}
          </Typography>
        </Paper>
      </>
    )
  }


  return (
    <div style={{ margin: "0", padding: "0", textAlign: "left" }}>
      <Typography
        sx={{
          color: "rgb(198, 0, 61)",
          fontFamily: "Verdana",
          fontSize: "22px",
          fontWeight: "700",
          marginTop: "30px",
          marginBottom: "30px",
          width: "60%",
        }}
      >
        Rich's Material Search
        {/* <InfoOutlinedIcon sx={{ marginLeft: "10px", opacity: 0.4 }} /> */}
      </Typography>

      <div
        style={{ position: "relative", display: "inline-block", width: "95%" }}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "90%",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              left: "10px",
              top: "5px",
              zIndex: 2,
              fontSize: "0px",
            }}
          >
            <SearchIcon />
          </IconButton>
          <Input
            fullWidth
            disableUnderline
            value={search_value}
            placeholder="Search Materials"
            sx={{
              width: "calc(100% - 78px)",
              padding: "10px 75px 10px 50px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "16px",
              fontWeight: "400",
              borderRadius: "25px",
              fontFamily: "Verdana",
              borderWidth: "1px",
              borderColor: "#ccc",
              borderStyle: "solid",
              paddingLeft: "50px",
              backgroundColor: "#f5f5f5",
            }}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />

          {search_value.length > 0 && (
            <IconButton
              style={{
                position: "absolute",
                right: "35px",
                top: "6px",
                zIndex: 2,
                fontSize: "0px",
                marginRight: "85px",
              }}
              onClick={handleClearSearch}
            >
              <ClearIcon/>
            </IconButton>
          )}

          <button
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              height: "100%",
              padding: "10px",
              borderRadius: "25px",
              background: "#C6003D",
              border: "0px",
              cursor: "pointer",
            }}
            onClick={handleSearch}
          >
            <Typography
              sx={{
                color: "#2D3748",
                fontFamily: "Verdana",
                fontSize: "16px",
                fontWeight: "400",
                color: "#fff",
                width: "100px",
                border: "0px",
                cursor: "pointer",
              }}
            >
              Search
            </Typography>
          </button>
         
          <Paper
            elevation={3}
            style={{
              position: "absolute",
              top: "56px",
              width: "calc(100% - 40px)",
              zIndex: 10000,
              maxHeight: "200px",
              overflowY: "auto",
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 6px 6px rgba(0, 0, 0, 0.1)",
              display: searchSuggestion.length > 0 ? "block" : "none",
            }}
          >
            {searchSuggestion.map((suggestion, index) => (
              <div
                key={index}
                style={{
                  padding: "10px",
                  fontFamily: "Verdana",
                  fontSize: "15px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ccc",
                }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </Paper>
        </div>
        {sqlDescription.length > 0 ? (
          <>
            <Tooltip title={<ShowDescription />} placement="right-end">
              <AfterSearch style={{ marginLeft: "20px", position: "relative", top: "5px" }} />
            </Tooltip></>
          ) : (
            <BeforeSearch style={{ marginLeft: "20px", position: "relative", top: "15px" }} />
          )}
      </div>
      <br />
    </div>
  );
}
