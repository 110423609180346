import React from "react";
import { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../styles/Insights.css";
import { ReactComponent as IdeaIcon } from "../../assets/images/ideaIcon.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import Loading from "../Loading";
import "../../styles/loading.css";
const Insights = (props) => {
  const { insights, hideInsights, handleHideInsights, insightsLoading } = props;
  const [dots, setDots] = useState("");
  let isInsights = true;

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 4 ? prevDots + "." : ""));
    }, 300);

    return () => clearInterval(interval);
  }, []);
  if (insights.length === 0) {
    isInsights = false;
  }

  return (
    <>
      <div
        className="insights-container"
        style={{ display: hideInsights ? "block" : "none" }}
      >
        <div className="insights-cards">
          <Typography
            sx={{
              color: "#2D3748",
              fontFamily: "Verdana",
              fontSize: "17px",
              display: "flex",
              fontWeight: 500,
            }}
          >
            <IdeaIcon
              style={{
                color: "#ffa26c",
                fontSize: "1.5em",
                marginRight: "0.5em",
              }}
            />
            Insights
            <RemoveIcon
              sx={{
                width: "24px",
                height: "24px",
                background: "#ffd2e0",
                borderRadius: "50px",
                position: "relative",
                left: "300px",
                cursor: "pointer",
              }}
              onClick={() => handleHideInsights()}
            />
          </Typography>

          <div className="cards-container">
            {insightsLoading ? (
              <div>loading {dots}</div>
            ) : (
              <>
                {isInsights ? (
                  <>
                    {insights.map((insight, index) => (
                      <Card key={index} sx={{ minWidth: 275, padding: '6px 6px 6px 6px', borderRadius: 0 }}>
                        <CardContent >
                          <Typography
                            sx={{
                              color: "#2D3748",
                              fontFamily: "Verdana",
                              fontSize: "15px",
                              height: "auto", // Set height to auto
                            }}
                          >
                            {insight}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </>
                ) : (
                  <Typography
                    sx={{
                      color: "#2D3748",
                      fontFamily: "Verdana",
                      fontSize: "15px",
                    }}
                  >
                    No data available for the question you asked
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Insights;
