import React, { useEffect, useState, useRef } from "react";
import "../../styles/dashboard.css";
import { httpRequest } from "../../api/httpRequests";
import Filter from "./Filters";
import Question from "./Question";
import TableComponent from "./TableComponent";
import SessionExpiredDialog from "./SessionExpiredDialog";
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Insights from "./Insights";
import Header from "./Header";
import Loading from "../Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react"; // Import useMsal
import MaterialGroupDialog from "./MaterialGroupDialog";

export default function Dashboard() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState({});
  const [columnValues, setColumnValues] = useState();
  const [tableData, setTableData] = useState([]);
  const [insights, setInsights] = useState([]);
  const [appLoading, setAppLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [qsloading, set] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const [filterDisable, setFilterDisable] = useState(true);
  const [hideInsights, setHideInsights] = useState(false);
  const [maxRows, setMaxRows] = useState(0);
  const [insightsLoading, setInsightsLoading] = useState(false);
  const [enableFeedBack, setEnableFeedBack] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterData, setFilterData] = useState({});
  const [boolApi, setBoolApi] = useState(false);
  const [loadPage, setLoadPage] = useState(true);
  const [filterBool, setFilterBool] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [currentAppVersion, setCurrentApplicationVersion] = useState("");
  const shouldExecuteEffectRef = useRef(true);
  const versionData = "";
  const hasMountedRef = useRef(false);
  const isMounted = useRef(true);
  const [sqlDescription, setSqlDescription] = useState("");
  const [reLogin, setReLogin] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionExpiredDialogOpen, setSessionExpiredDialogOpen] =
    useState(false);
  // const [searchActive, setSearchActive] = useState(false);
  // useEffect(()=> {
  //   if(tableData){
  //     const columnNames = tableData.length > 0 ? Object.keys(tableData[0]) : [];
  //     const disallowedColumns = ["Unique Id", "Active Flag"];

  //     const filteredColumns = columnNames.filter(
  //       (column) => !disallowedColumns.includes(column.trim())
  //     );
  //     setOrderBy(filteredColumns[0])
  //   }
  // },[tableData])

  // useEffect(()=>{
  //   fetchApplicationVersion()
  // },[])
  useEffect(() => {
    if (isMounted.current) {
      const isSignedIn = localStorage.getItem("MRO_user_isSignedIn");
      const storedUserLoginInfo = localStorage.getItem("userLoginInfo");
      const parsedUserLoginInfo = JSON.parse(storedUserLoginInfo);
      if (parsedUserLoginInfo && !isSignedIn) {
        handleLogin(parsedUserLoginInfo, false);
        isMounted.current = false;
      } else {
        setAppLoading(false);
      }
    }
  });

  useEffect(() => {}, [reLogin]);

  useEffect(() => {
    if (hasMountedRef.current && shouldExecuteEffectRef.current) {
      // Only execute when setPage(0) is called from other places
      fetchSearchResult(queryValue, filterBool, false, order, orderBy, false);
    } else {
      hasMountedRef.current = true;
      shouldExecuteEffectRef.current = true; // Reset the flag after using it
    }
  }, [rowsPerPage, page, order, orderBy]);

  const handleLogin = async (userLoginInfo, getNewSession) => {
    const headers = {};
    setAppLoading(true)
    try {
      const response = await httpRequest.post(
        "/login_v2/",
        {},
        {
          headers: {
            token_type: userLoginInfo?.token_type ?? "",
            scope: userLoginInfo?.scope ?? "",
            expires_in: userLoginInfo?.expires_in ?? "",
            ext_expires_in: userLoginInfo?.ext_expires_in ?? "",
            access_token: userLoginInfo?.access_token ?? "",
            refresh_token: userLoginInfo?.refresh_token ?? "",
            refresh_token_expires_in:
              userLoginInfo?.refresh_token_expires_in ?? "",
            id_token: userLoginInfo?.id_token ?? "",
            client_info: userLoginInfo?.client_info ?? "",
            userEmail: userLoginInfo?.userEmail ?? "",
            relogin: getNewSession ? true : false,
          },
          params:{
            relogin: getNewSession ? 'true' : 'false'
          },
        },
       
      );
      if (response?.data?.success) {
        localStorage.setItem("session_id", response?.data?.session_id);
        localStorage.setItem(
          "MRO_user_name",
          response?.data?.user_name.split("@")[0]
        );
        localStorage.setItem("MRO_user_location", response?.data?.location);
        localStorage.setItem("MRO_user_email", response?.data?.user_name);
        localStorage.setItem(
          "MRO_APP_VERSION",
          response?.data?.current_version
        );
        localStorage.setItem("MRO_user_type", response?.data?.user_type);
        localStorage.setItem("MRO_user_location", response?.data?.location);
        localStorage.setItem("MRO_user_isSignedIn", true);
        setUserEmail(response?.data?.user_name);
        setUserLocation(response?.data?.location);
        setCurrentApplicationVersion(response?.data?.current_version);
        // hard coding the user type here Rakesh Shetty
        // fetchApplicationVersion();
        toast.success("Successfully Logged in!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 800,
        });
        setAppLoading(false);
      }
    } catch (error) {
      setAppLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
      
    }
  };

  const handleVersionClick = (e) => {
    e.preventDefault();
    const newTab = window.open("/version", "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      // If the browser blocks opening a new tab, navigate in the current tab as a fallback
      navigate("/version");
    }
  };

  const handlePage = (val) => {
    setPage(val);
    setFilterBool(false);
  };

  const handleRowsPerPage = (val) => {
    setRowsPerPage(val);
    setFilterBool(false);
  };

  const handleFiltersearch = (val) => {
    fetchSearchFilterResult(val);
    setBoolApi(true);
    setRowsPerPage(5);
    // shouldExecuteEffectRef.current = false;
    setPage(0);
    setFilterData(val);
  };
  const clearAllData = () => {
    setQueryValue("");
    setSqlDescription("");
    setMaxRows("");
    setColumnValues("");
    setFilterValues("");
    setTableData("");
    setFilterDisable("");
    localStorage.removeItem("description");
    localStorage.removeItem("manufacturing_part_number");
    localStorage.removeItem("plant_name");
    localStorage.removeItem("material_number");
    localStorage.removeItem("storage_location");
    localStorage.removeItem("storage_bin");
  };


  const handleReLogin = () => {
    const storedUserLoginInfo = localStorage.getItem("userLoginInfo");
    const parsedUserLoginInfo = JSON.parse(storedUserLoginInfo);
    clearAllData();
    if (parsedUserLoginInfo) {
      handleLogin(parsedUserLoginInfo, true);
    } else {
      // Optionally, handle the case when userLoginInfo is not available
      console.log("User login information is not available in the location.");
      setAppLoading(false);
    }
  };

  const fetchSearchResult = async (
    value,
    filter_bool,
    resetFilter,
    orderValue,
    order_by,
    search_active
  ) => {
    setEnableFeedBack(true);
    if (value && value.trim() !== "") {
      if (loadPage) {
        setLoading(true);
        resetFilter = true;
      } else {
        setTableLoading(true);
      }

      try {
        const response = await httpRequest.post(
          "/search",
          {},
          {
            headers: {
              session_id: localStorage.getItem("session_id"),
            },
            params: {
              search_str: value && value.trim(),
              limit: rowsPerPage,
              page: page + 1,
              reset_filter: resetFilter,
              col_name: order_by ? order_by : "",
              asc: orderValue === "asc" ? true : false,
              search_active,
            },
          }
        );
        if (response?.data?.success) {
          const column_values = Object.keys(response?.data?.table);
          const table_data = handleTableData(response?.data?.table);
          const filterResult = handleFilterData(response?.data);
          if (response?.data?.sql_description)
            setSqlDescription(response?.data?.sql_description);
          setMaxRows(response?.data?.max_rows);
          setColumnValues(column_values);
          if (filter_bool && Object.keys(response?.data).length > 4) {
            setFilterValues(filterResult);
          }
          setTableData(table_data);
          setFilterDisable(false);
          if (!boolApi) {
            setLoading(false);
          } else {
            setTableLoading(false);
          }
        } else {
          toast.error("Unable to process the request. Please try again!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 500,
          });
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401) {
            setErrorMessage(error.response.data.detail.display_error);
            setSessionExpiredDialogOpen(true);
          } else {
            toast.error("Unable to process the request. Please try again!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          console.error("Error:", error.message);
        }
      }
      if (loadPage) {
        setTimeout(() => {
          setLoading(false);
          setLoadPage(false);
        }, 800);
      } else {
        setTableLoading(false);
      }
    }
  };

  const logUserFeedback = async (feedbackData) => {
    if (feedbackData !== "") {
      try {
        const response = await httpRequest.post(
          "/feedback_logging/",
          {
            feedback_positive: feedbackData.feedback_positive,
            user_comment: feedbackData.user_comment,
            categories: feedbackData.categories,
          },
          {
            headers: {
              session_id: localStorage.getItem("session_id"),
            },
          }
        );

        if (response?.data?.success) {
          if (feedbackData.user_comment.trim() != "") {
            toast.success("Thanks for the feedback and Comment", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          } else {
            toast.success("Thanks for the feedback!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
        }
      } catch (error) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          setErrorMessage(error.response.data.detail.display_error);
          setSessionExpiredDialogOpen(true);
        } else {
          toast.error("Unable to process the request. Please try again!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    }
  };

  const fetchSearchFilterResult = async (value) => {
    setTableLoading(true);
    // console.log('Search api called')
    const search_question = queryValue && queryValue.trim();
    try {
      const response = await httpRequest.post(
        "/filter_search",
        { ...value },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
          },
          params: {
            question: search_question,
            limit: rowsPerPage,
            page: page + 1,
          },
        }
      );
      if (response?.data?.success) {
        const column_values = Object.keys(response?.data?.table);
        const table_data = handleTableData(response?.data?.table);
        // const filterResult = handleFilterData(response?.data);
        setTableData(table_data);

        setMaxRows(response?.data?.max_rows);
        setColumnValues(column_values);
        setTableLoading(false);
        // setFilterValues(filterResult);
      }
    } catch (error) {
      setTableLoading(false);
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          setErrorMessage(error.response.data.detail.display_error);
          setSessionExpiredDialogOpen(true);
        } else {
          toast.error("Unable to process the request. Please try again!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchInsightsResult = async () => {
    setInsightsLoading(true);
    const search_question = queryValue && queryValue.trim();
    setTimeout(() => {});
    setTimeout(async () => {
      try {
        const response = await httpRequest.post(
          "/insights",
          {},
          {
            headers: {
              session_id: localStorage.getItem("session_id"),
            },
            params: {
              question: search_question,
            },
          }
        );
        if (response?.data?.success) {
          const insightsResult = handleInsightsData(response?.data.insights);
          setInsights(insightsResult);
          setInsightsLoading(false);
        } else {
          const insightsResult = handleInsightsData(
            response?.data.display_error
          );
          setInsights(insightsResult);
          setInsightsLoading(false);
        }
      } catch (error) {
        // setTableLoading(false);
        // toast.error("Unable to process the request. Please try again!", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        console.error("Error fetching data:", error);
        setInsightsLoading(false);
      }
    }, 30);
  };

  const handleTableData = (value) => {
    const result = Object.keys(value[Object.keys(value)[0]]).map((i) =>
      Object.keys(value).reduce((obj, key) => {
        obj[key] = value[key][i];
        return obj;
      }, {})
    );

    return result;
  };

  const handleFilterData = (data) => {
    let filterResult = {};
    if (data?.material_desc_list) {
      filterResult.description = data?.material_desc_list || [];
    }
    if (data?.material_grp_list) {
      filterResult.material_group = data?.material_grp_list || [];
    }
    if (data?.plant_list) {
      filterResult.plant_name = data?.plant_list || [];
    }
    if (data?.supplier_part_list) {
      filterResult.supplier_part_number = data?.supplier_part_list || [];
    }
    if (data?.manufacturing_part_list) {
      filterResult.manufacturing_part_number =
        data?.manufacturing_part_list || [];
    }
    if (data?.material_number_list) {
      filterResult.material_number = data?.material_number_list || [];
    }
    if (data?.storage_location_list) {
      filterResult.storage_location = data?.storage_location_list || [];
    }
    if (data?.storage_bin_list) {
      filterResult.storage_bin = data?.storage_bin_list || [];
    }
    return filterResult;
  };

  function handleInsightsData(data) {
    if (data === null || data === undefined || data === "") {
      return [];
    } else {
      return data;
    }
  }

  const handleSearchBool = () => {
    setFilterDisable(true);
  };
  
  const handleCloseSessionExpire = () => {
    setSessionExpiredDialogOpen(false);
    handleReLogin();
  };

  const handleSearchAgain = (value) => {
    setFilterBool(true);
    setPage(0);
    setRowsPerPage(5);
    setBoolApi(false);
    setQueryValue(value);
    setLoadPage(true);
    setOrder("asc");
    setOrderBy("");
    setSqlDescription("");
    localStorage.removeItem("description");
    localStorage.removeItem("manufacturing_part_number");
    localStorage.removeItem("plant_name");
    localStorage.removeItem("material_number");
    localStorage.removeItem("storage_location");
    localStorage.removeItem("storage_bin");
    fetchSearchResult(value, true, false, "asc", "", true);
  };

  const handleResetFilter = () => {
    setBoolApi(false);
    setPage(0);
    setRowsPerPage(5);
    setOrder("asc");
    setOrderBy("");
    const search_question = queryValue && queryValue.trim();
    fetchSearchResult(search_question, true, true, "asc", "", true);
  };

  const handleShowInsights = () => {
    setHideInsights(!hideInsights);
    fetchInsightsResult();
  };

  const handleHideInsights = () => {
    setHideInsights(!hideInsights);
  };

  return (
    <>
      {appLoading ? (
        <Loading height="120vh" />
      ) : (
        <>
          <Header
            userEmail={userEmail}
            userLocation={userLocation}
            handleHideInsights={handleShowInsights}
          />
          <SessionExpiredDialog
            open={sessionExpiredDialogOpen}
            onClose={() => handleCloseSessionExpire()}
            errorMessage={errorMessage}
            handleReLogin={handleReLogin}
          />
          <div className="dashboard-container">
            <div className="left-panel">
              <Question
                searchValue={queryValue}
                handleSearchAgainFunction={handleSearchAgain}
                handleSearchBool={handleSearchBool}
                filterDisable={filterDisable}
                sqlDescription={sqlDescription}
              />
              {/* {loading ? (
                ""
              ) : (
                <Filter
                  filter_value={filterValues}
                  filterDisable={filterDisable}
                  handleFilterChange={handleFiltersearch}
                  hideInsights={hideInsights}
                  handleResetFilter={handleResetFilter}
                />
              )} */}
            </div>
            <div className="right-panel">
              <Insights
                insights={insights}
                hideInsights={hideInsights}
                handleHideInsights={handleHideInsights}
                handleShowInsights={handleShowInsights}
                insightsLoading={insightsLoading}
              />
            </div>
          </div>
          {loading ? (
            <Loading height="100vh" />
          ) : (
            <>
              <div>
                {tableLoading ? (
                  <Loading height="50vh" />
                ) : (
                  <TableComponent
                    table_data={tableData}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    orderBy={orderBy}
                    order={order}
                    sqlDescription={sqlDescription}
                    maxRows={maxRows}
                    handleUserFeedback={(feedbackData) => {
                      logUserFeedback(feedbackData);
                    }}
                    enableFeedBack={enableFeedBack}
                    page={page}
                    setPage={handlePage}
                    rowsPerPage={rowsPerPage}
                    filterValues={filterValues}
                    handleFiltersearch={handleFiltersearch}
                    setRowsPerPage={handleRowsPerPage}
                  />
                )}
              </div>
              <ToastContainer />
            </>
          )}
          {tableLoading || loading ? (
            ""
          ) : (
            <div className="footer">
              {/* Use a button and attach the click handler */}
              <button className="version-button" onClick={handleVersionClick}>
                Version {localStorage.getItem("MRO_APP_VERSION")}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
