import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Paper, Divider, Box } from "@mui/material";
import { ReactComponent as CloseIcon } from '../../assets/images/CloseIcon.svg';
import backgroundImg from '../../assets/images/circleBackground.png';
import { ReactComponent as Success } from '../../assets/images/Success.svg';
import { ReactComponent as Creation } from '../../assets/images/Creation.svg';
import { Navigate, useNavigate   } from "react-router-dom";
import {automationHttpRequest} from "../../api/httpRequests";

const PartCreationSuccess = ({ partCreationDialog, setPartCreationDialog, requestID }) => {
    const navigate = useNavigate ();

    const handleClose = () => {
        setPartCreationDialog(false)
    };

    return (
        <>
            <Dialog
                open={partCreationDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        maxWidth: "1400px",
                        borderRadius: "10px"
                    }
                }}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px" }}>
                    <Creation />
                    <Typography sx={{ fontFamily: "Verdana", fontSize: "24px", fontWeight: "700", lineHeight: "21px" }}>
                        Part Creation
                    </Typography>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                </DialogTitle>
                <DialogContent sx={{ width: "1260px" }}>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                            <div style={{
                                backgroundImage: `url(${backgroundImg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '35%',
                                height: '440px',
                                margin: "auto"
                            }}>
                            </div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    '& > :not(style)': {
                                        m: 1,
                                        width: "65%",
                                        borderRadius: "18px",
                                        height: 180,
                                        margin: "auto",
                                        zIndex: 100,
                                        position: "relative",
                                        top: "-300px"
                                    },
                                }}
                            >
                                <Paper elevation={2}>
                                    <div>
                                        <div style={{ display: "flex", alignItems: "center", height: "80px", justifyContent: "center" }}>
                                            <Success style={{ height: "60px" }} />
                                            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}> Successfully Submitted Part Creation Request </Typography>
                                        </div>
                                        <Divider />
                                        <div>
                                            <Typography sx={{ fontSize: "24px", margin: "20px 0px 0px 100px", borderRadius: "8px", background: "rgba(50, 65, 255, 0.11)", width: "35%", padding: "10px" }}> Request id is #{requestID} </Typography>
                                        </div>
                                    </div>
                                </Paper>
                            </Box>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Link to="dashboard">   */}
                     <button
                        type="submit"
                        className="submitButton"
                        style={{
                            fontSize: 16,
                            cursor: "pointer",

                            position: "relative",
                            fontFamily: "Verdana",
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClose()
                            navigate('/dashboard')
                        }}
                    >
                        Close
                    </button>
                    {/* </Link> */}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PartCreationSuccess;




