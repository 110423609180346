import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../styles/changePart.css";
import backgroundImage from "../../assets/images/backgroundImage.png";
import { ReactComponent as ActionSelect } from "../../assets/images/ActionSelect.svg";
import DescriptionChange from "../PartModification/DescriptionChange";
import DeletionFlag from "../PartModification/DeletionFlag";
import AttributeChange from "../PartModification/AttributeChange";
import ParameterChanges from "../PartModification/ParameterChanges";
import ReplenishmentLevel from "../PartModification/ReplenishmentLevel";
import PlantSpecificModification from "../PartModification/PlantSpecificModification";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import { ReactComponent as ModificationIcon } from "../../assets/images/ModificationIcon.svg";

const PartModification = ({
  materialNumber,
  handleDialogClose,
  uid,
  handleBackWardClick,
  handleBack,
}) => {
  const [selectAction, setSelectAction] = React.useState("");
  const handleChange = (event) => {
    setSelectAction(event.target.value);
  };

  const handleBackClick = () => {
    setSelectAction("");
  };

  const handleClose = () => {
    handleDialogClose();
  };

  return (
    <>
      <div>
        <button
          style={{
            display: "flex",
            width: "90px",
            justifyContent: "space-around",
            alignItems: "center",
            border: "0px",
            background: "transparent",
            margin: "0px 0px 20px 0px",
            cursor: "pointer",
          }}
          onClick={() => handleBackWardClick()}
        >
          <ArrowBackIcon />
          <Typography> Back </Typography>
        </button>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: selectAction === "attributeChange" ? "110%" : "80%", // Adjust the width conditionally
            borderRadius: "8px",
            height: selectAction === "parameterChanges" ? "500" : "450",
            boxShadow:
              selectAction === "attributeChange"
                ? "none"
                : "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adjust the boxShadow property
          },
        }}
      >
        <Paper elevation={2}>
        <PlantSpecificModification
            selectAction={selectAction}
            handleBackClick={handleBackWardClick}
            uniqueId={uid}
            materialNumber={materialNumber}
            handleClose={handleClose}
          />
          {/* <ReplenishmentLevel
            selectAction={selectAction}
            handleBackClick={handleBackClick}
            uniqueId={uid}
            materialNumber={materialNumber}
            handleClose={handleClose}
          />
          <DeletionFlag
            selectAction={selectAction}
            handleBackClick={handleBackClick}
            uniqueId={uid}
            materialNumber={materialNumber}
            handleClose={handleClose}
          />
          <ParameterChanges
            selectAction={selectAction}
            handleBackClick={handleBackClick}
            uniqueId={uid}
            materialNumber={materialNumber}
            handleClose={handleClose}
          /> */}
        </Paper>
        {/* <img src={backgroundImage} alt="Logo" style={{ width: "100%" }} /> */}
      </Box>
    </>
  );
};

export default PartModification;
