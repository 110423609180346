import React from "react";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import "../../styles/login.css";
import { IconButton, Typography } from "@mui/material";
import { automationHttpRequest } from "../../api/httpRequests";
import { Divider } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as DropdownIcon } from "../../assets/images/DropdownIcon.svg";
import { Select, MenuItem, InputAdornment, TextField } from "@mui/material";
import { ListSubheader } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as Plant } from "../../assets/images/Plant.svg";
import Paper from "@mui/material/Paper";
import { ReactComponent as Success } from "../../assets/images/Success.svg";
import Box from "@mui/material/Box";
import axios from "axios";
import { ReactComponent as ButtonPlus } from "../../assets/images/ButtonPlus.svg";
import backgroundImg from "../../assets/images/circleBackground.png";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";


const PartExtension = ({
  materialNumber,
  handleDialogClose,
  uid,
  handleBackClick,
  handleBack,
}) => {
  const [successPop, setSuccessPop] = useState(false);
  const [inputCount, setInputCount] = useState(1);
  const [inputValues, setInputValues] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [justificationShow, setJustificationShow] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [requestID, setRequestID] = useState(0);
  const [leadTimeValue, setLeadTimeValue] = useState("");
  const [netPrice, setNetPrice] = useState("");
  const [abcIndicator, setAbcIndicator] = useState({
    abcIndicatorFutureValue: "",
    abcIndicatorJustify: "",
  });
  const [currency, setCurrency] = useState("$");
  const [location, setLocation] = useState("");
  const [bin, setBin] = useState("");
  const [minimumValue, setMinimumValue] = useState({
    minimumValueFutureValue: 0,
    minimumValueJustify: "",
  });
  const [maximumValue, setMaximumValue] = useState({
    maximumValueFutureValue: 0,
    maximumValueJustify: "",
  });

  const options = ["$", "€", "£", "¥", "CA$"];

  const handleSelectChange = (event) => {
    setCurrency(event?.target?.value);
  };

  useEffect(() => {
    fetchSearchResult();
  }, []);

  useEffect(() => {
    if (responseData) {
      setLocation(responseData?.storage_location);
      setBin(responseData?.storage_bin);
      setAbcIndicator((prevObject) => ({
        ...prevObject,
        abcIndicatorFutureValue: responseData?.future_abc_indicator,
      }));
      setMinimumValue((prevObject) => ({
        ...prevObject,
        minimumValueFutureValue: responseData?.future_minimum_stock_level,
      }));
      setMaximumValue((prevObject) => ({
        ...prevObject,
        maximumValueFutureValue: responseData?.future_maximum_stock_level,
      }));
    }
    setNetPrice(responseData?.standard_price);
    setLeadTimeValue(responseData?.lead_time_from_pir);
  }, [responseData]);

  const initialValue = {
    plant: "",
    purchasingGroup: "",
    baseUnitOfMeasure: "",
    mrpGroup: "",
    mrpController: "",
    abcIndicator: "",
    criticalityIndicator: "",
    safetyStock: "",
    maximumStock: "",
    sloc: "",
    bin: "",
  };

  const inputStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  };

  const headingStyle = {
    fontWeight: "700",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
        marginTop: "4px",
        borderRadius: "10px",
        fontFamily: "Verdana",
      },
    },
  };

  const fetchSearchResult = async () => {
    setLoading(true);
    try {
      const response = await automationHttpRequest.post(
        "/extension/",
        {
          Unique_ID: uid.toString(),
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setResponseData(response?.data?.data[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const handleClose = () => {
    handleDialogClose();
  };

  const handleSubmit = async () => {
    setSuccessPop(true);
    handleSubmitValues();
  };

  const handleSubmitValues = async () => {
    const finalValue = {
      material_number: materialNumber,
      vendor_account: responseData?.vendor_account || 0,
      supplier_part_number: responseData?.supplier_part_number || "",
      lead_time_from_pir: parseInt(leadTimeValue) || 0,
      order_unit_from_pir: parseInt(responseData?.order_unit_from_pir) || 0,
      ABC_indicator: responseData?.ABC_indicator || "",
      critical_part: responseData?.critical_part || "",
      minimum_stock_level: responseData?.minimum_stock_level || 0,
      maximum_stock_level: responseData?.maximum_stock_level || 0,
      storage_location: location || "-",
      storage_bin: bin || "-",
      currency,
      equipment_number_for_bom: inputValues,
      standard_price: parseInt(netPrice) || 0,
      future_abc_indicator: abcIndicator?.abcIndicatorFutureValue || "",
      future_maximum_stock_level:
        parseInt(maximumValue?.maximumValueFutureValue) || 0,
      future_minimum_stock_level:
        parseInt(minimumValue?.minimumValueFutureValue) || 0,
      justification_for_change_in_abc_indicator:
        abcIndicator?.abcIndicatorJustify || "",
      justification_for_change_in_maximum_stock:
        maximumValue?.maximumValueJustify || "",
      justification_for_change_in_minimum_stock:
        minimumValue?.minimumValueJustify || "",
      plant_name: responseData?.plant_name,
      submitted_by: localStorage.getItem("MRO_user_email")
    };
    try {
      setLoading(true);
      const response = await automationHttpRequest.post(
        "/extension_insert/",
        {
          ...finalValue,
        },
        {
          headers: {
            session_id: localStorage.getItem("session_id"),
            userEmail: localStorage.getItem("MRO_user_email"),
          },
          params: {},
        }
      );
      if (response?.data?.status) {
        setRequestID(response?.data?.data?.request_id);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process the request. Please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const handleAddInput = () => {
    setInputCount(inputCount + 1);
    setInputValues([...inputValues, ""]);
  };

  const handleChange = (event) => {
    if (event?.target?.name === "leadTime") {
      setLeadTimeValue(event?.target?.value);
    } else {
      setNetPrice(event?.target?.value);
    }
  };

  const handleFutureValue = (event) => {
    if (event?.target?.name === "abcIndicator") {
      setAbcIndicator((prevObject) => ({
        ...prevObject,
        abcIndicatorFutureValue: event?.target?.value,
      }));
      setJustificationShow(true);
    }
    if (event?.target?.name === "minimumValue") {
      setMinimumValue((prevObject) => ({
        ...prevObject,
        minimumValueFutureValue: event?.target?.value,
      }));
    }
    if (event?.target?.name === "maximumValue") {
      setMaximumValue((prevObject) => ({
        ...prevObject,
        maximumValueFutureValue: event?.target?.value,
      }));
    }
  };
  const handleJustification = (event) => {
    if (event?.target?.name === "abcIndicator") {
      setAbcIndicator((prevObject) => ({
        ...prevObject,
        abcIndicatorJustify: event?.target?.value,
      }));
    }
    if (event?.target?.name === "minimumValue") {
      setMinimumValue((prevObject) => ({
        ...prevObject,
        minimumValueJustify: event?.target?.value,
      }));
    }
    if (event?.target?.name === "maximumValue") {
      setMaximumValue((prevObject) => ({
        ...prevObject,
        maximumValueJustify: event?.target?.value,
      }));
    }
  };
  const handleChangeCurrent = (event) => {
    if (event?.target?.name === "Sloc") {
      setLocation(event?.target.value);
    } else {
      setBin(event?.target.value);
    }
  };

  const ValueDisplay = ({
    current,
    future,
    justification,
    currentValue,
    disabled,
  }) => {
    return (
      <>
        <div>
          {current && (
            <input
              className="narrowInput"
              // placeholder="Current Value"
              name="currentValue"
              type="text"
              disabled={disabled}
              value={currentValue}
              style={{ background: "#EFEFEF" }}
            />
          )}
          {future && (
            <input
              // placeholder="future Value"
              name="revisedValue"
              type="text"
              className="narrowInput"
            />
          )}
          {justification && (
            <input
              // placeholder="Justification"
              name="justificationValue"
              type="text"
              className="narrowInput"
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        {loading ? (
          <>
            <>
              <Typography
                sx={{
                  height: "70px",
                  fontSize: "18px",
                  padding: "20px",
                  background: "#EFEFEF",
                }}
              >
                Loading...
              </Typography>
            </>
          </>
        ) : (
          <>
            <div>
              <button
                style={{
                  display: "flex",
                  width: "90px",
                  justifyContent: "space-around",
                  alignItems: "center",
                  border: "0px",
                  background: "transparent",
                  margin: "0px 0px 20px 0px",
                  cursor: "pointer",
                }}
                onClick={() => handleBackClick()}
              >
                <ArrowBackIcon />
                <Typography> Back </Typography>
              </button>
            </div>
            <div className="">
              {!successPop ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      alignItems: "center",
                    }}
                  >
                    <Plant />
                    <div style={inputStyle}>
                      <Typography style={headingStyle}>
                        Extension Required
                      </Typography>
                      <FormControl sx={{ m: 1, width: "200px" }}>
                        <div className="select-container">
                          <input
                            // placeholder="Part Extension"
                            name="activePart"
                            style={{ background: "#EFEFEF" }}
                            type="text"
                            value={responseData?.plant_name}
                            className="partInputField"
                            disabled
                          />
                        </div>
                      </FormControl>
                    </div>
                  </div>
                  <Divider style={{ margin: "10px 0px" }} />
                  <div className="formikExtension">
                    <div style={inputStyle}>
                      <Typography style={headingStyle}>
                        Vendor Number
                      </Typography>
                      <input
                        value={responseData?.vendor_account}
                        // placeholder="Vendor Number"
                        type="text"
                        name="vendorNumber"
                        disabled
                        style={{ background: "#EFEFEF" }}
                        className="partInputField"
                      />
                    </div>
                    <div style={inputStyle}>
                      <Typography style={headingStyle}>
                        Vendor Material Number
                      </Typography>
                      <input
                        // placeholder="Vendor Material Number"
                        name="vendorMaterialNumber"
                        type="text"
                        value={responseData?.supplier_part_number}
                        className="partInputField"
                        disabled
                        style={{ background: "#EFEFEF" }}
                      />
                    </div>
                    <div style={inputStyle}>
                      <Typography style={headingStyle}>Lead Time</Typography>
                      <input
                        // placeholder="Lead Time"
                        name="leadTime"
                        onChange={handleChange}
                        type="number"
                        value={leadTimeValue}
                        className="partInputField"
                      />
                    </div>
                    <div style={inputStyle}>
                      <Typography
                        style={{
                          fontWeight: "700",
                          position: "relative",
                          left: "12px",
                        }}
                      >
                        Net Price
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <input
                          name="netPrice"
                          onChange={handleChange}
                          type="number"
                          value={netPrice}
                          className="partInputField"
                          style={{ width: "80px" }}
                        />
                        <FormControl
                          sx={{
                            m: 1,
                            width: "70px",
                            position: "relative",
                            left: "-10px",
                          }}
                        >
                          <Select
                            value={currency}
                            onChange={handleSelectChange}
                            IconComponent={() => (
                              <DropdownIcon
                                style={{
                                  width: "40px",
                                  position: "relative",
                                  left: "-10px",
                                }}
                              />
                            )}
                            sx={{ height: "32px", borderRadius: "8px" }}
                          >
                            {options.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div style={inputStyle}>
                      <Typography style={headingStyle}>Order Unit</Typography>
                      <input
                        // placeholder="OrderUOM"
                        name="orderUOM"
                        disabled
                        type="text"
                        value={responseData?.order_unit_from_pir}
                        className="partInputField"
                        style={{ background: "#EFEFEF" }}
                      />
                    </div>
                    {/* <div style={inputStyle}>
                        <Typography style={headingStyle}>ABC Indicator</Typography>
                        <input
                          placeholder="ABC Indicator"
                          name="abcIndicator"
                          onChange={handleChange}
                          type="text"
                          value={values.abcIndicator}
                          className="partInputField"
                        />
                      </div>
                      <div style={inputStyle}>
                        <Typography style={headingStyle}>Criticality Indicator</Typography>
                        <input
                          placeholder="Criticality Indicator"
                          name="criticalityIndicator"
                          onChange={handleChange}
                          type="text"
                          value={values.criticalityIndicator}
                          className="partInputField"
                        />
                      </div>
                      <div style={inputStyle}>
                        <Typography style={headingStyle}>Safety Stock</Typography>
                        <input
                          placeholder="Safety Stock"
                          name="safetyStock"
                          onChange={handleChange}
                          type="text"
                          value={values.safetyStock}
                          className="partInputField"
                        />
                      </div>
                      <div style={inputStyle}>
                        <Typography style={headingStyle}>Maximum Stock</Typography>
                        <input
                          placeholder="Maximum Stock"
                          name="maximumStock"
                          onChange={handleChange}
                          type="text"
                          value={values.maximumStock}
                          className="partInputField"
                        />
                      </div>
                      <div style={inputStyle}>
                        <Typography style={headingStyle}>Sloc</Typography>
                        <input
                          placeholder="Sloc"
                          name="sloc"
                          onChange={handleChange}
                          type="text"
                          value={values.sloc}
                          className="partInputField"
                        />
                      </div>
                      <div style={inputStyle}>
                        <Typography style={headingStyle}>Bin</Typography>
                        <input
                          placeholder="Bin"
                          name="bin"
                          onChange={handleChange}
                          type="text"
                          value={values.bin}
                          className="partInputField"
                        />
                      </div> */}
                  </div>
                  <Divider style={{ margin: "10px 0px" }} />
                  {!justificationShow ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "50%",
                          margin: "40px auto 0px",
                          
                        }}
                      >
                        <Typography style={{ fontWeight: 600, }}>Current Value</Typography>
                        <Typography
                          style={{ position: "relative", left: "85px",  fontWeight: 600, }}
                        >
                          Future Value
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "60%",
                          justifyContent: "space-evenly",
                          margin: "40px auto 0px",
                        }}
                      >
                        <Typography style={{fontWeight: 600, }}>Current Value</Typography>
                        <Typography style={{ fontWeight: 600, }}>Future Value</Typography>
                        {
                          <Typography
                            sx={{ fontWeight: 600,
                              color: !justificationShow ? "#fff" : "#000", // Change color based on justificationShow
                            }}
                          >
                            Justification for Requested Changes
                          </Typography>
                        }
                      </div>
                    </>
                  )}
                  <div className="valueDisplay">
                    <Typography className="subHeading">
                      ABC Indicator
                    </Typography>
                    <ValueDisplay
                      current={true}
                      suggested={true}
                      future={false}
                      disabled={true}
                      justification={false}
                      currentValue={responseData?.ABC_indicator}
                    />
                    <input
                      // placeholder="future Value"
                      name="abcIndicator"
                      type="text"
                      className="narrowInput"
                      value={abcIndicator?.abcIndicatorFutureValue}
                      onChange={handleFutureValue}
                    />
                    {justificationShow && (
                      <input
                        // placeholder="Justify Value"
                        name="abcIndicator"
                        // disabled={
                        //   abcIndicator?.abcIndicatorFutureValue?.length > 0
                        //     ? false
                        //     : true
                        // }
                        type="text"
                        className="narrowInput"
                        value={abcIndicator?.abcIndicatorJustify}
                        onChange={handleJustification}
                      />
                    )}
                  </div>
                  <Divider />
                  <div className="valueDisplay">
                    <Typography className="subHeading">
                      Criticality Indicator
                    </Typography>
                    <ValueDisplay
                      current={true}
                      suggested={false}
                      disabled={true}
                      future={false}
                      justification={false}
                      currentValue={responseData?.critical_part}
                    />
                  </div>
                  <Divider />
                  <div className="valueDisplay">
                    <Typography className="subHeading">
                      Minimum Stock
                    </Typography>
                    <ValueDisplay
                      current={true}
                      suggested={false}
                      future={false}
                      disabled={true}
                      justification={false}
                      currentValue={responseData?.minimum_stock_level}
                    />
                    {/* <input
                      // placeholder="future Value"
                      name="minimumValue"
                      type="number"
                      className="narrowInput"
                      value={minimumValue?.minimumValueFutureValue}
                      onChange={handleFutureValue}
                    /> */}
                    {/* <input
                      // placeholder="Justify Value"
                      name="minimumValue"
                      type="text"
                      className="narrowInput"
                      style={{
                        background:
                          minimumValue?.minimumValueFutureValue > 0
                            ? "#FFF"
                            : "#EFEFEF",
                      }}
                      disabled={
                        minimumValue?.minimumValueFutureValue > 0 ? false : true
                      }
                      value={minimumValue?.minimumValueJustify}
                      onChange={handleJustification}
                    /> */}
                  </div>
                  <Divider />
                  <div className="valueDisplay">
                    <Typography className="subHeading">
                      Maximum Stock
                    </Typography>
                    <ValueDisplay
                      current={true}
                      disabled={true}
                      suggested={false}
                      future={false}
                      justification={false}
                      currentValue={responseData?.maximum_stock_level}
                    />
                    {/* <input
                      // placeholder="future Value"
                      name="maximumValue"
                      type="number"
                      className="narrowInput"
                      value={maximumValue?.maximumValueFutureValue}
                      onChange={handleFutureValue}
                    />
                    <input
                      // placeholder="Justify Value"
                      name="maximumValue"
                      type="text"
                      className="narrowInput"
                      style={{
                        background:
                          maximumValue?.maximumValueFutureValue > 0
                            ? "#FFF"
                            : "#EFEFEF",
                      }}
                      disabled={
                        maximumValue?.maximumValueFutureValue > 0 ? false : true
                      }
                      value={maximumValue?.maximumValueJustify}
                      onChange={handleJustification}
                    /> */}
                  </div>
                  <Divider />
                  <div className="valueDisplay">
                    <Typography className="subHeading">
                      Storage Location
                    </Typography>
                    <input
                      // placeholder="Sloc"
                      name="Sloc"
                      type="text"
                      className="narrowInput"
                      value={location}
                      onChange={handleChangeCurrent}
                    />
                  </div>
                  <Divider />
                  <div className="valueDisplay">
                    <Typography className="subHeading">Bin</Typography>
                    <input
                      // placeholder="Bin"
                      name="Bin"
                      type="text"
                      className="narrowInput"
                      value={bin}
                      onChange={handleChangeCurrent}
                    />
                  </div>
                  <Divider />

                  <Typography
                    sx={{
                      width: "100%",
                      fontFamily: "Verdana",
                      fontSize: "14px",
                      fontWeight: "700",
                      margin: "20px 0px",
                    }}
                  >
                    Equipment Number(s) For BOM
                  </Typography>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      {Array.from({ length: inputCount }).map((_, index) => (
                        <input
                          key={index}
                          className="partInputField"
                          type="text"
                          value={inputValues[index]}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          // placeholder={`Input ${index + 1}`}
                        />
                      ))}
                    </div>
                    <IconButton
                      onClick={handleAddInput}
                      sx={{ height: "40px" }}
                    >
                      <ButtonPlus />
                    </IconButton>
                  </div>

                  {/* <div style={inputStyle}>
                      <Typography style={headingStyle}>Existing Active Part</Typography>
                      <input
                        placeholder="Enter"
                        name="activePart"
                        onChange={handleChange}
                        type="text"
                        style={{ width: "25%" }}
                        // value={values.password}
                        className="partInputField"
                      />
                    </div>
                    <div style={inputStyle}>
                      <Typography style={headingStyle}>Comments</Typography>
                      <textarea
                        id="yourTextarea"
                        name="yourTextarea"
                        placeholder="Enter Comments"
                        // value={text}
                        style={{ width: "50%" }}
                        onChange={handleChange}
                        className="partTextField"
                        rows={4}
                        cols={50}
                      />
                    </div> */}
                  <div>
                    <button
                      type="submit"
                      className="submitButton"
                      style={{
                        fontSize: 16,
                        cursor: "pointer",

                        position: "relative",
                        fontFamily: "Verdana",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="cancelButton"
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: "Verdana",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {loading ? (
                    <>
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress style={{ color: "#C6003D" }} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            alignItems: "center",
                          }}
                        >
                          <Plant />
                          <div style={inputStyle}>
                            <Typography style={headingStyle}>
                              Extension Required
                            </Typography>
                            <FormControl sx={{ m: 1, width: "200px" }}>
                              <div className="select-container">
                                <input
                                  // placeholder="Part Extension"
                                  name="activePart"
                                  // onChange={handleChange}
                                  style={{ background: "#EFEFEF" }}
                                  type="text"
                                  value={responseData?.plant_name}
                                  className="partInputField"
                                  disabled
                                />
                              </div>
                            </FormControl>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundImage: `url(${backgroundImg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "35%",
                            height: "440px",
                            margin: "auto",
                          }}
                        ></div>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                              m: 1,
                              width: "65%",
                              borderRadius: "18px",
                              height: 180,
                              margin: "auto",
                              zIndex: 100,
                              position: "relative",
                              top: "-300px",
                            },
                          }}
                        >
                          <Paper elevation={2}>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "80px",
                                  justifyContent: "center",
                                }}
                              >
                                <Success style={{ height: "60px" }} />
                                <Typography
                                  sx={{ fontSize: "24px", fontWeight: "500" }}
                                >
                                  {" "}
                                  Successfully Submitted Part Extension Request{" "}
                                </Typography>
                              </div>
                              <Divider />
                              <div>
                                <Typography
                                  sx={{
                                    fontSize: "24px",
                                    margin: "20px 0px 0px 100px",
                                    borderRadius: "8px",
                                    background: "rgba(50, 65, 255, 0.11)",
                                    width: "35%",
                                    padding: "10px",
                                  }}
                                >
                                  {" "}
                                  Request id is #{requestID}{" "}
                                </Typography>
                              </div>
                            </div>
                          </Paper>
                        </Box>
                      </div>

                      <button
                        type="submit"
                        className="submitButton"
                        style={{
                          fontSize: 16,
                          cursor: "pointer",

                          position: "relative",
                          fontFamily: "Verdana",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                        }}
                      >
                        Close
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PartExtension;
