export const dialogStyles = {
  backgroundStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius: "50px",
    border: "0px",
    marginTop: "5px",
    padding: "40px",
    height: "410px", // Set a fixed height for the dialog box if needed
  },
  centeredButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: "20px", // Adjust the top margin as needed
  },
  centeredButton: {
    background: "#D11F3F",
    borderRadius: "30px",
    textTransform: "capitalize",
    border: "0px",
    cursor: "pointer",
    zIndex: 1000,
    height: "60px", // Adjust the height as needed
    width: "120px",
  },
};