import React, { useState, useEffect, useRef } from "react";

import Typography from "@mui/material/Typography";
import "../../styles/VersionHistory.css";
import { ReactComponent as Dropdown } from "../../assets/images/VersionDropdown.svg";
import { ReactComponent as Dropup } from "../../assets/images/VersionDropUp.svg";
import { ReactComponent as RoundFilled } from "../../assets/images/Round_filled_icon.svg";
import { ReactComponent as RoundUnFilled } from "../../assets/images/Round_unfilled_icon.svg";
import Loading from "../Loading";

import Header from "./Header";
import VersionLoading from "../VersionLoading";

import { httpRequest } from "../../api/httpRequests";
// import httpRequest from "../../api/httpRequests";

const VersionHistory = () => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isMounted.current) {
      fetchApplicationVersion();
      isMounted.current = false;
    }
  }, []);

  const [versions, setVersions] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const fetchApplicationVersion = async () => {
    try {
      const versionResponse = await httpRequest.get("/version_info", {
        headers: {
          session_id: localStorage.getItem("session_id"),
        },
      });
      const versionData = versionResponse?.data?.data;
      setVersions(versionData);
      setLoading(true);
    } catch (error) {
      console.error("Error fetching application version:", error);
      setLoading(true);
    }
  };

  return (
    <>
      <Header handleHideInsights={""} />
      {!loading ? (
        <VersionLoading height="100vh" />
      ) : (
        <div className="history-container">
          <div className="version-history-container">
            <div className="version-history-header">
              <Typography
                sx={{
                  fontFamily: "Verdana",
                  fontSize: "20px",
                  marginLeft: "-20px",
                }}
              >
                Version History
              </Typography>
            </div>
            <div className="version-history-header">
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Verdana",
                  fontSize: "15px",
                }}
              >
                Release Date
              </Typography>
            </div>
            {versions.map((version, index) => (
              <div
                className={`version-entry ${
                  index === 0 ? "first-version" : ""
                }`}
                key={index}
              >
                <div className="date-column">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #b3a6a6",
                        borderRadius: "20px",
                        padding: "2px 8px",
                        backgroundColor:
                          index === 0 ? "#BA0C2F" : "transparent",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Verdana",
                          lineHeight: 1.5,
                          fontSize: "13px",
                          color: index === 0 ? "white" : "black",
                        }}
                      >
                        {version.releaseDate}
                      </Typography>
                    </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "20px",
                          cursor: "pointer",
                          marginLeft: "40px"
                        }}
                      >
                        {index === 0 ? (
                          <RoundFilled className="filled-icon" />
                        ) : (
                          <RoundUnFilled className="unfilled-icon" />
                        )}
                      </div>
                  </div>
                </div>
                <div
                  className={`info-column ${
                    expandedIndex === index ? "expanded" : ""
                  }`}
                >
                  <div className="info-header">
                    <Typography
                      sx={{
                        fontFamily: "Verdana",
                        fontWeight: "600",
                      }}
                    >
                      Version {version.version}
                    </Typography>
                    <div
                      className="expand-button"
                      onClick={() => handleToggle(index)}
                    >
                      {expandedIndex === index ? (
                        <Dropup style={{ cursor: "pointer" }} />
                      ) : (
                        <Dropdown style={{ cursor: "pointer" }} />
                      )}
                    </div>
                  </div>
                  {expandedIndex === index && (
                    <div className="additional-info">
                      {Object.entries(version.releaseNotes).map(
                        ([heading, details], headingIndex) =>
                          details && Object.keys(details).length > 0 ? (
                            <div key={headingIndex}>
                              <Typography
                                sx={{
                                  display: "inline-block",
                                  border: "1px solid #b3a6a6",
                                  borderRadius: "20px",
                                  padding: "2px 8px",
                                  fontFamily: "Verdana",
                                  backgroundColor: "#e0e0e0",
                                  marginBottom: "8px",
                                  lineHeight: "1.5",
                                }}
                              >
                                {heading}
                              </Typography>
                              {Object.entries(details).map(
                                ([subHeading, subDetails], subIndex) =>
                                  subDetails && subDetails.length > 0 ? (
                                    <div key={subIndex}>
                                      <Typography
                                        sx={{
                                          display: "inline-block",
                                          padding: "2px 8px",
                                          fontWeight: 650,
                                          fontFamily: "Verdana",
                                          marginLeft: "10px",
                                          marginBottom: "8px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {subHeading}
                                      </Typography>
                                      {Array.isArray(subDetails) ? (
                                        subDetails.map(
                                          (detail, detailIndex) => (
                                            <Typography
                                              key={detailIndex}
                                              sx={{
                                                fontFamily: "Verdana",
                                                marginLeft: "20px",
                                                marginBottom: "8px",
                                                lineHeight: "1.5",
                                              }}
                                            >
                                              {detailIndex + 1}. {detail}
                                            </Typography>
                                          )
                                        )
                                      ) : (
                                        <Typography
                                          sx={{
                                            fontFamily: "Verdana",
                                            marginLeft: "20px",
                                            marginBottom: "8px",
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          {subDetails}
                                        </Typography>
                                      )}
                                    </div>
                                  ) : null
                              )}
                            </div>
                          ) : null
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default VersionHistory;
